import { uploadfile, joinedUser, shareScreenUser, participant_join, leftUser, acceptcandidate, rejectecandidate, share, downloadarrowblow, emildevilerd, windresize, exitedinterview, Video, ringcopy, textquestion, website, mcqQuestion } from "../../helper/Icons/Icon";
import CriclrDiv from "../common/CriclrDiv";
import { Row, Col } from "react-bootstrap";
import { getTimeLineById } from "../../store/actions/interviewActions";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import moment from "moment";

const iconData = {
  'email_delivered': emildevilerd,
  'landing_page': website,
  'screen_share': shareScreenUser,
  'leave_meeting': leftUser,
  'question_4': textquestion,
  'join_meeting': joinedUser,
  'participant_join': participant_join,
  'participant_leave': participant_join,
  'exited_interview': exitedinterview,
};

const TimeLineLive = ({ id }) => {
  const dispatch = useDispatch();
  const { InterviewTimeLine, loading, error } = useSelector((state) => state.interview);

  useEffect(() => {
    if (id) {
      dispatch(getTimeLineById(id));
    }
  }, [dispatch, id]);

  // Safe check for InterviewTimeLine and its data
  const timelineData = InterviewTimeLine?.data || [];
  const checkNotNull = (label) => label !== null;


  const filteredTimeLine = timelineData.filter(checkNotNull);

  return (
    <div className="timeline-body">
      <h5 className='min-sub-heading mt-10'><label>Timeline </label></h5>
      <div className="timeline">
        {loading && <p>Loading...</p>}

        <ul>
          {filteredTimeLine.length > 0 ? (
            filteredTimeLine.map((item, index) => (
              <li key={index}>
                <div className="d-flex">
                  <CriclrDiv icon={iconData[item.key]} clrName="timeline-criclr v-blue-light border-0" />
                  <div className="timeline-body-text">
                    <label>{item.log}</label>
                    <span>{moment(item.date_time).format('MM-DD-YYYY h:mm A')}</span>
                  </div>
                </div>
              </li>
            ))
          ) : (
            <p>No timeline data available</p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default TimeLineLive;

// const iconData = [
//     { icon: emildevilerd, label: "Email delivered", time: "10-13-2023 12:34PM" },
//     { icon: website, label: "Visited interview landing page", time: "10-13-2023 12:34PM" },
//     { icon: ringcopy, label: "Submitted audio response", time: "10-13-2023 12:34PM" },
//     { icon: Video, label: "Submitted video response", time: "10-13-2023 12:34PM" },
//     { icon: windresize, label: "Resized window", time: "10-13-2023 12:34PM" },
//     { icon: mcqQuestion, label: "Submitted MCQ response", time: "10-13-2023 12:34PM" },
//     { icon:  exitedinterview, label: "Exited interview", time: "10-13-2023 12:34PM" },

//     // Add more objects for additional list items
//   ];