import { Button, Col, Form, Row, Modal } from "react-bootstrap";
import { sky, Video, time, timer, interviews, InterviewMediaicon, bell, allowedAnswer } from "../../helper/Icons/Icon"
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import interview from "../../assets/images/interview.jpg";

import { CustomButton } from "../form";
const PracticeQuestion = () => {
  const { token } = useParams();
  const videotag = useRef(null);
  const mediaRecorder = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [error, setError] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [suggestionMsg, setSuggestionMsg] = useState("");
  const [recordedBlob, setRecordedBlob] = useState(null);
  const [prevRecording, setPrevRecording] = useState(false);
  const [preData, setPreData] = useState(null)
  const navigate = useNavigate();
  const location = useLocation();

  const PracticeProceed = () => {
    // navigate(`/system-check`, { state: { token: location.state?.token, candidateId: location.state.candidateId } });
    navigate(`/system-check/${token}`);
  }

  useEffect(() => {

    if (isRecording) {
      const timer = setInterval(() => {
        setCurrentTime(prevTime => prevTime + 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isRecording]);

  const startRecording = async () => {

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      videotag.current.srcObject = stream;
      mediaRecorder.current = new MediaRecorder(stream);

      setIsRecording(true);

      mediaRecorder.current.start();
      const chunks = [];

      mediaRecorder.current.ondataavailable = (event) => {
        chunks.push(event.data);
      };

      mediaRecorder.current.onstop = () => {
        const blob = new Blob(chunks, { type: 'video/webm' });
        setRecordedBlob(blob);
        setCurrentTime(0);


        const videoUrl = window.URL.createObjectURL(blob);


        videotag.current.srcObject = null;
        stream.getTracks().forEach(track => track.stop());

        setIsRecording(false);
      };
    } catch (error) {
      let errorMessage = "Error: Could not start video source";
      if (error.name === "NotReadableError") {
        errorMessage +=
          ". This could be due to permission issues, browser support, or device availability.";
        setSuggestionMsg("Please check your browser settings, ensure that your browser supports media devices, and verify that the camera and microphone are properly connected and accessible.");
      } else if (error.name === "NotAllowedError") {
        errorMessage += ". Please grant permission to access the camera and microphone.";
        setSuggestionMsg("Click on the browser's lock icon in the address bar to grant permission to access the camera and microphone.");
      }
      setError(errorMessage);
      setShowErrorModal(true);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.stop();

    }
  };

  const handleDownload = () => {
    if (recordedBlob) {
      const url = URL.createObjectURL(recordedBlob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'recorded-video.webm';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
    setError(null);
    setSuggestionMsg("");
  };
  return (

    <>
      <Row className="row-pad-0">
        <h5 className="sub-heading mt-10 ">Practice Question</h5>
        <Row className="bg-white pad-20 mt-10 radius-7 row-pad-0">
          <Col md={12} className=" mt-10 d-flex justify-content-between">

            <div className="system-card ">
              <div className="syetem-icon">
                {bell}
              </div>
              <div className="system-msg question-text">
                <small className="system-text">You are currently in a practice mode. Please take a moment to unwind and familiarize yourself with the process, as no data will be retained.</small>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="row-pad-0 bg-white border-radius-5 pad-20 mt-20" >



          <Col md={12} className=" mt-10 d-flex justify-content-between">
            <div className="system-card ">
              <div className="syetem-icon">
                {Video}

              </div>
              <div className="system-msg">
                <small className="system-text">Video Question: How are you doing today?</small>

              </div>

            </div>

            {/*  */}
            <div className="system-card ">
              <div className="syetem-icon">
                {sky}

              </div>
              <div className="system-msg">
                <small className="system-text">Allowed Think Time:</small>
                <small className="system-text-span">2:00 Mins</small>

              </div>

            </div>
            <div className="system-card ">
              <div className="syetem-icon">
                {time}

              </div>
              <div className="system-msg">
                <small className="system-text">Allowed Answer Duration:</small>
                <small className="system-text-span">2:00 Mins</small>

              </div>

            </div>


          </Col>

          <Col md={12} >
            <Row className="row-pad-0 pad-20 border-radius-5 Practice-Question-wrap bg-ligte-gray mt-10">



              <Row className="row-pad-0 pad-10 border-radius-5 interview-media-wrap bg-ligte-gray">
                <Col md={12} className="Practice-Question-RecordVideo-wrap">
                  <h4 className="">
                    <span className="mr-10">{Video}</span>Record Video
                  </h4>

                  <div className="video-section mt-20">

                    {!recordedBlob && <video width="100%" height="100%" autoPlay poster={interview} ref={videotag} controls />}
                    {recordedBlob && (
                      <video width="100%" height="100%" controls src={URL.createObjectURL(recordedBlob)} />
                    )}

                    <div className="">

                      <div className="">
                        {!recordedBlob && (
                          <>
                            <CustomButton text="Record Now" disabled={isRecording} className=" custom-btn-primary mt-10 mr-10 " onClick={startRecording} />
                            <CustomButton text="save and continue" disabled={!isRecording} className="custom-btn-outline  mt-10" onClick={stopRecording} />
                            <span className="mt-1" style={{ display: isRecording ? 'block' : 'none' }}>Response Time: {currentTime}</span>
                          </>
                        )}
                        {recordedBlob && (
                          <CustomButton className="custom-btn-outline mt-10" onClick={handleDownload} text="Download Video" />
                        )}
                      </div>

                    </div>
                  </div>


                  {/* Error Modal */}
                  <Modal show={showErrorModal} onHide={handleCloseErrorModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {error}
                      {suggestionMsg && <p><strong>Suggestion:</strong> {suggestionMsg}</p>}
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleCloseErrorModal}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </Col>
              </Row>






            </Row>
          </Col>



        </Row>
        <Col md={12} className="text-end pad-0 mt-20 mb-20">
          <CustomButton className="custom-btn-primary" onClick={PracticeProceed} text="Proceed" />
        </Col>

      </Row>

    </>)
}


export default PracticeQuestion;