import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Tab, Nav } from "react-bootstrap";
import Paginate from "../common/Paginate.jsx";
import { getAll } from "../../store/actions/candidateInvitationActions.js";
import { getCandidateCountsByJob } from "../../store/actions/jobActions.js";
import { getByName } from "../../store/actions/userPrefrenceActions.js";
import { CandidatesICon } from "../../helper/Icons/Icon.jsx";

import { share, Video, view, pencil, deletclrred } from "../../helper/Icons/Icon.jsx";
import JobDetail from "./JobDetail.jsx";
import CandidatesDetail from "../candidate/CandidatesDetail.jsx";
import ViewerFile from "../common/ViewerFile.jsx";
import Statusbtn from "../common/Statusbtn.jsx";
import Tabelcolumn from "../common/Tabelcolumn.js";
import { Table, Form } from "react-bootstrap";
import * as moment from 'moment';
// import Spinner from 'react-bootstrap/Spinner';
import Spinner from "../common/Spinner.jsx";
import Swal from 'sweetalert2';
import EditCandidate from "../candidate/EditCandidate.jsx";
import { deleteCandidate } from "../../store/actions/candidateActions.js";
import Interview from "../RecordedInterviewDetails/Interview.jsx";
import ShareCandidate from "../common/ShareCandidate.jsx";
const config = require('../../config/config.json');

const defaultCurrentPage = 1;

const CandidatesList = ({ jobId, update }) => {
    const [showDocViewer, setDocViewer] = useState(false);
    const [showDocViewerFile, setDocViewerFile] = useState(false);
    const [showCandidateEdit, setCandidateEdit] = useState(false);
    const [invitationId, setInvitationId] = useState()
    const [showInterview, setshowInterview] = useState(false)
    const [showDetailPage, setShowDetailPage] = useState(false);
    const [showCandidatesDetailPage, setCandidatesDetailPage] = useState(false);
    const [candidateId, setcandidateId] = useState()
    const [ShowShareCandidate, setShareCandidate] = useState(false)
    // const [jobId, setJobId] = useState(jobId)
    const [sortConfig, setSortConfig] = useState({});
    const [deleteFlag, setDeleteFlag] = useState(false);
    const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
    const [perPage, setPerPage] = useState(10);
    const [stageId, setStageId] = useState(0);
    const [searchField, setSearchField] = useState({});
    const dispatch = useDispatch();

    const prefrenceName = "users";
    const { userPrefrenceDetail } = useSelector((state) => state.userPrefrence);

    const { candidateInvitation, loading } = useSelector((state) => state.candidateInvitations);
    const invitations = candidateInvitation?.data;
    let pages = candidateInvitation.pages;
    let totalPages = candidateInvitation.total_page;
    let totalRecords = candidateInvitation.total_record;

    const candidateCountList = useSelector((state) => state.job.cadidateCount);

    useEffect(() => {
        dispatch(getByName(prefrenceName));
    }, [dispatch, update]);

    useEffect(() => {
        if (userPrefrenceDetail?.preference_value?.record_per_page) {
            setPerPage(userPrefrenceDetail.preference_value.record_per_page);
        }
    }, [userPrefrenceDetail]);

    useEffect(() => {

        const params = {
            limit: perPage,
            page: currentPage,
            job_id: jobId,
            candidate_stage_id: stageId,
            search: searchField,
            order_by: sortConfig?.key, sort_by: sortConfig?.order
        };
        dispatch(getAll(params));

    }, [dispatch, stageId, currentPage, perPage, searchField, deleteFlag, update, sortConfig]);

    useEffect(() => {
        dispatch(getCandidateCountsByJob(jobId));
    }, [dispatch, jobId]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerPage = (perPage) => {
        setPerPage(perPage);
    };
    const toggleSortOrder = (key) => {
        setSortConfig((prevConfig) => {
            if (prevConfig.key === key) {

                return { key, order: prevConfig.order === 'asc' ? 'desc' : 'asc' };
            } else {

                return { key, order: 'asc' };
            }
        });
    };
    const handleFieldSearch = (e) => {
        const { name, value } = e.target;
        setSearchField((prev) => ({ ...prev, [name]: value }));
    };


    const handleDelete = (candidateInvitationId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure to delete the selected item(s).',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteCandidate(candidateInvitationId))
                    .then((res) => {



                        Swal.fire({
                            icon: 'success',
                            title: 'Deleted',
                            text: 'Item(s) has been deleted.',
                            showConfirmButton: false,
                            timer: 1000
                        })
                        setDeleteFlag(!deleteFlag)
                    });
            }
        });

    };

    const handelModel = (type, id, candidateId) => {


        if (type == "editCandidate") {
            setInvitationId(id)

            setCandidateEdit(true);

            setDeleteFlag(!deleteFlag)

        }
        if (type == "candidates") {
            setCandidatesDetailPage(true);
            setcandidateId(candidateId)
        }
        if (type == "jobs") {
            setShowDetailPage(true);
            // setJobId(id)
        }
        if (type == "Interview") {
            setInvitationId(id)
            setcandidateId(candidateId)
            setshowInterview(true);
        }


    }
    const handeShareCandidate = (id) => {
        // setJobId(id)
        setShareCandidate(!ShowShareCandidate)


    }
    const handelResumeDocViewer = (type, url) => {
        setDocViewerFile(url)
        setDocViewer(true);


    }
    const handelCloseModel = (type) => {

        setInvitationId(null)
        if (type == "editCandidate") {


            setCandidateEdit(false);

            setDeleteFlag(!deleteFlag)

        }
        if (type == "ResumeDocViewer") {

            setDocViewer(false);
        }
        if (type == "Interview") {
            setshowInterview(false);

        }
        if (type == "jobs") {
            setShowDetailPage(false);

        } if (type == "candidates") {
            setCandidatesDetailPage(false);

        }

    }

    return (
        <>
            <Row className="row-pad-0 mt-10">
                <Col md={1} className="custom-md-1 mt-1">
                    <div className="profile">
                        <span>{CandidatesICon}</span>
                    </div>
                </Col>
                <Col md={11} className="pad-0 mt-1 min-sub-heading custom-md-11">
                    <label>Candidates</label>
                </Col>
            </Row>

            <Row className="row-pad-0 mt-1 detail-tab-wrap">

                <div className="box-wrap">
                    <Nav variant="pills" className="d-flex custom-nav">
                        {candidateCountList?.map((link) => (
                            <Nav.Item key={link.stage_id}>
                                <Nav.Link eventKey={link.stage_id} className={stageId === link.status_id ? 'active' : ''} onClick={() => setStageId(link.stage_id)}>
                                    {link.stage_name} ({link.count})
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                </div>

                {invitations &&
                    <div className="mt-20 x-scroll detail-Tab-Pane">
                        {loading &&
                            <Spinner />
                        }
                        <Table className="table" style={{ width: "1200px" }}>
                            <thead>
                                <tr>
                                    <th style={{ minWidth: "100px", width: "140px" }} >
                                        <span>Quick Action</span>
                                    </th>
                                    <th style={{ minWidth: "100px", width: "200px" }} className="sorting">
                                        <span onClick={() => toggleSortOrder('name')}>
                                            {sortConfig.key === 'name'
                                                ? sortConfig.order === 'asc'
                                                    ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                                                    : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                                                : <i className="fa fa-exchange" aria-hidden="true"></i>}
                                        </span>
                                        <Form.Control
                                            type="text"
                                            placeholder="Candidate"
                                            name="name"
                                            value={searchField.name || ''}
                                            onChange={handleFieldSearch}
                                        />
                                    </th>
                                    <th style={{ minWidth: "100px", width: "200px" }} className="sorting">
                                        <span onClick={() => toggleSortOrder('email_address')}>
                                            {sortConfig.key === 'email_address'
                                                ? sortConfig.order === 'asc'
                                                    ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                                                    : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                                                : <i className="fa fa-exchange" aria-hidden="true"></i>}
                                        </span>
                                        <Form.Control
                                            type="text"
                                            placeholder="Email"
                                            name="email_address"
                                            value={searchField.email_address || ''}
                                            onChange={handleFieldSearch}
                                        />
                                    </th>
                                    <th style={{ minWidth: "100px", width: "200px" }} className="sorting">
                                        <span onClick={() => toggleSortOrder('phone')}>
                                            {sortConfig.key === 'phone'
                                                ? sortConfig.order === 'asc'
                                                    ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                                                    : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                                                : <i className="fa fa-exchange" aria-hidden="true"></i>}
                                        </span>
                                        <Form.Control
                                            type="text"
                                            placeholder="Phone Number"
                                            name="phone"
                                            value={searchField.phone || ''}
                                            onChange={handleFieldSearch}
                                        />
                                    </th>
                                    <th style={{ minWidth: "100px", width: "200px" }} className="sorting">
                                        <span onClick={() => toggleSortOrder('job_title')}>
                                            {sortConfig.key === 'job_title'
                                                ? sortConfig.order === 'asc'
                                                    ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                                                    : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                                                : <i className="fa fa-exchange" aria-hidden="true"></i>}
                                        </span>
                                        <Form.Control
                                            type="text"
                                            placeholder="Job Title"

                                            name="job_title"
                                            value={searchField.job_title || ''}
                                            onChange={handleFieldSearch}
                                        />
                                    </th>
                                    <th style={{ minWidth: "100px", width: "200px" }} className="sorting">
                                        <span onClick={() => toggleSortOrder('candidate_status_name')}>
                                            {sortConfig.key === 'candidate_status_name'
                                                ? sortConfig.order === 'asc'
                                                    ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                                                    : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                                                : <i className="fa fa-exchange" aria-hidden="true"></i>}
                                        </span>
                                        <Form.Control
                                            type="text"
                                            placeholder="Status"
                                            name="candidate_status_name"
                                            value={searchField.candidate_status_name || ''}
                                            onChange={handleFieldSearch}
                                        />
                                    </th>
                                    <th style={{ minWidth: "100px", width: "200px" }} className="sorting">
                                        <span onClick={() => toggleSortOrder('created_date')}>
                                            {sortConfig.key === 'created_date'
                                                ? sortConfig.order === 'asc'
                                                    ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                                                    : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                                                : <i className="fa fa-exchange" aria-hidden="true"></i>}
                                        </span>
                                        <Form.Control
                                            type="text"
                                            placeholder="Invited AT"
                                            name="created_date"
                                            value={searchField.created_date || ''}
                                            onChange={handleFieldSearch}
                                        />
                                    </th>
                                </tr>
                            </thead>

                            <tbody className="tabel-body">


                                {(invitations && !loading) &&
                                    (invitations.length === 0 ? (
                                        <tr>
                                            <td colSpan="7" className="text-center"><div className=""><h6>No record found</h6></div> </td>
                                        </tr>
                                    ) : (
                                        invitations.map((invitation) => {

                                            const hasInterview = invitation.interview_start_time && invitation.interview_end_time;


                                            return (

                                                <tr>
                                                    <td>
                                                        <div className="quick-action">
                                                            {hasInterview &&

                                                                <Tabelcolumn span="span " className="cursor" onClick={() => handelModel("Interview", invitation?.job_id, invitation?.candidate_id)} Tooltip="Interviews" icon={Video} />
                                                            }

                                                            {invitation?.candidate_id && <Tabelcolumn span="span" className="eye cursor" onClick={() => handelModel("editCandidate", invitation?.candidate_id)} icon={pencil} Tooltip="Edit" />}
                                                            {invitation?.candidate_id && <Tabelcolumn span="span " className="cursor" onClick={() => handleDelete(invitation?.candidate_id)} Tooltip="Delete" icon={deletclrred} />}
                                                            {hasInterview && <Tabelcolumn span="span " className="cursor" onClick={() => handeShareCandidate(invitation?.job_id)} Tooltip="Share Candidate for Evaluation" icon={share} />}
                                                            {invitation?.resume && <Tabelcolumn span="span " className="cursor" onClick={() => handelResumeDocViewer("ResumeDocViewer", invitation?.resume)} Tooltip="Resume" icon={view} />}

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <a href="#" onClick={() => handelModel("candidates", '', invitation?.candidate_id)} >{invitation?.name}{invitation?.candidate_id} </a>
                                                    </td>
                                                    <td>{invitation?.email_address}</td>
                                                    <td>{invitation?.phone}</td>
                                                    <td> {invitation?.job_title}</td>
                                                    <td>
                                                        <Statusbtn
                                                            className={`${invitation?.candidate_status_name ? invitation.candidate_status_name.replace(/\s+/g, '-').toLowerCase() : 'default-status'} status`}
                                                            text={invitation?.candidate_status_name || 'Default Status'}
                                                        />
                                                    </td>
                                                    <td>{moment(invitation?.created_date).format('MM-DD-YYYY h:mm A')}</td>

                                                </tr>
                                            )
                                        }
                                        ))
                                    )
                                }

                            </tbody>
                        </Table>

                        <Paginate
                            pages={pages}
                            currentPage={currentPage}
                            totalPages={totalPages}
                            perPage={perPage}
                            totalRecords={totalRecords}
                            handlePageChange={handlePageChange}
                            handlePerPage={handlePerPage}
                        />

                        {showCandidateEdit && <EditCandidate candidateId={invitationId} handleClose={() => handelCloseModel("editCandidate")} />}
                        {showInterview && <Interview handleClose={() => handelCloseModel("Interview")} jobid={invitationId} candidateId={candidateId} />}
                        {ShowShareCandidate && <ShareCandidate jobId={jobId} handleClose={handeShareCandidate} />}
                        {showDocViewer && <ViewerFile handleClose={() => handelCloseModel("ResumeDocViewer")} document={`${showDocViewerFile}`} />}
                        {showDetailPage && <JobDetail handleClose={() => handelCloseModel("jobs")} jobId={jobId} />}
                        {showCandidatesDetailPage && <CandidatesDetail handleClose={() => handelCloseModel("candidates")} candidateId={candidateId} />}
                    </div>
                }
            </Row >

        </>
    );
};

export default CandidatesList;
