import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import { hands } from "../../helper/Icons/Icon";
import InterviewMedia from "./InterviewMedia";
import { CustomButton } from "../form";
import { interviewtemplatetokent, UpdateInterviewByToken } from "../../store/actions/InterviewproceedAction";
import Spinner from "../common/Spinner";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Swal from 'sweetalert2';

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 2000
    });
}

const WelcomeInterview = () => {
    const { token } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showerror, setErrors] = useState(null)
    useEffect(() => {
        if (token) {
            dispatch(interviewtemplatetokent(token)).then((res) => {
                if (res.payload.error) {
                    setErrors(res.payload.error)
                }

            });
        }
    }, [dispatch, token]);

    const { interviewtemplatetDetail, loading, error } = useSelector((state) => state.Interviewproceed);

    const InterviewProceed = () => {
        let payload = {
            "interview_type_id": 1,
            "start_timestamp": new Date(),
            "stage_id": 7, //interview underway
            "end_timestamp": "",
            "description": "",
            "interview_rating": 0,
            "comments": "",
        }
        dispatch(UpdateInterviewByToken({ token, payload })).then((resp) => {
            if (resp.payload.error) {
                sweetAlert(
                    "error",
                    "Error!",

                    `${resp.payload.error}!`
                );
            }
            else {
                navigate(`/interview-question/${token}`);
            }

        }).catch(() => {
            sweetAlert(
                "error",
                "Error!",
                "Something went wrong!"
            );
        });


    };


    return (
        <>
            {loading && <Spinner />}
            {showerror ? <div className="link-error-wrap">
                <div className="link-error"><h1>{showerror}</h1></div>
            </div> :
                <Col md={12} className="pad-0 startInterview-content">
                    <Container fluid className="pad-20">
                        <Row className="row-pad-0 bg-white radius-7 pad-10 min-height-50">
                            <Col md={12} className="mt-10 d-flex justify-content-between">
                                <div className="system-card">
                                    <div className="syetem-icon">
                                        {hands}
                                    </div>
                                    <div className="system-msg">
                                        <small className="sub-heading">{interviewtemplatetDetail?.intro_message}</small>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="row-pad-0 bg-white radius-7 pad-10 mt-10 d-flex align-items-center justify-content-center">
                            <div className="bg-ligte-gray radius-7 mt-20 mb-20 media-box-wrap  ">
                                <div className="media-box  mt-10">
                                    <InterviewMedia media={interviewtemplatetDetail?.intro_media} />
                                </div>
                            </div>
                        </Row>
                        <Col md={12} className="text-end mt-20 mb-10">
                            <CustomButton className="custom-btn-primary" text="Proceed" onClick={InterviewProceed} />
                        </Col>
                    </Container>
                </Col>

            }
        </>
    );
}

export default WelcomeInterview;
