import { useState, useEffect } from "react";

import { Row, Col, Offcanvas, Image, Carousel } from "react-bootstrap";

import Companyoverview from "./Companyoverview";
import { CustomButton } from "../form/index";

import Connectsocialmedia from "./Connectsocialmedia";


import { JobActive, crossicon } from "../../helper/Icons/Icon";

const config = require('../../config/config.json');

const ViewCompany = ({ handleClose, companyDetail, bg, logo, cultur }) => {
  const data = {
    Companylabel: "Company Name",
    Companyvalue: companyDetail ? companyDetail?.company_name : "-",
    Industrylabel: "Industry",


    Citylabel: "City, State",
    Cityvalue: companyDetail ? (companyDetail.city && companyDetail.state_name ? `${companyDetail.city} ${companyDetail.state_name}` : " ") : " ",

    ZipCodelabel: "Zip Code",
    ZipCodevalue: companyDetail ? companyDetail?.zip_code : "-",
    Websitelabel: "Website",
    Websitevalue: companyDetail ? companyDetail?.website_url : "-",
    Countrylabel: "Country",
    Countryvalue: companyDetail ? companyDetail?.country_name : "-",
    CompanySizelabel: "Company Size",
    CompanySizevalue: companyDetail ? companyDetail?.company_size : "-",
  };

  const hasData = companyDetail?.company_name || companyDetail.city || companyDetail.state_name || companyDetail?.zip_code || companyDetail?.website_url || companyDetail?.company_size;
  const hasDataConnect = companyDetail.website_url || companyDetail.linkedin_url || companyDetail.twitter_url || companyDetail.facebook_url

  const shouldRender = bg || logo;





  return (
    <>
      <Offcanvas
        show={true}
        onHide={handleClose}
        placement="end"
        className="bg-ligte-gray custom-offcanvas"
        style={{ width: "80%" }}
      >

        <Offcanvas.Header className="model-header bg-white pad-10 ">
          <Offcanvas.Title>
            <span></span>Preview Landing Page
          </Offcanvas.Title>
          <div className="text-end">



            <CustomButton
              onClick={handleClose}
              className="text-end close-btn "
              text={crossicon}
            />
          </div>
        </Offcanvas.Header>

        <Offcanvas.Body className="mt-10">




          <Col className="pad-20 bg-ligte-gray y-scroll">
            {shouldRender &&
              <Row className="row-pad-0 virtelligenceTeam-card pb-10 ">

                <Col md={12} className="p-relative pad-0 ">
                  {bg && <div className="virtelligenceTeam-img">
                    <img
                      src={`${config.API_BASE_URL_PUBLIC}/${bg}`}
                      alt="Banner"

                    />
                  </div>}
                  {logo && <div className="profile-image-view-company p-absolute ">
                    <img src={`${config.API_BASE_URL_PUBLIC}/${logo}`} alt="Logo" />
                  </div>
                  }
                  {companyDetail?.company_name && <div className="company-name">
                    <b>{companyDetail?.company_name}</b>
                  </div>
                  }
                </Col>
              </Row>
            }
            <Row className="row-pad-0 mt-20">
              <Col md={7} className="">
                {hasData &&
                  <Row className="pad-0 mt-20 bg-white">
                    <div>
                      <Row className='row-pad-0 mt-10 mb-10'>
                        <Col md={12} className='pad-0'>
                          <h4 className="heading-h">{data?.Companyvalue} Company Overview</h4>
                        </Col>
                        <span className='single-border d-block'></span>
                      </Row>

                      <Row className='row-pad-0 pad-0 mt-1 CompanyOverview-body'>
                        <Col md={6} className=''>
                          {data?.Companyvalue && (
                            <div className="content">
                              <label>{data?.Companylabel}:</label>
                              <small>{data?.Companyvalue}</small>
                            </div>
                          )}
                          {data?.Industryvalue && (
                            <div className="content">
                              <label>{data?.Industrylabel}:</label>
                              <small>{data?.Industryvalue}</small>
                            </div>
                          )}
                          {data?.Cityvalue || data?.Cityvalue && (
                            <div className="content">
                              <label>{data?.Citylabel}:</label>
                              <small>{data?.Cityvalue}</small>
                            </div>
                          )}
                          {data?.ZipCodevalue && (
                            <div className="content">
                              <label>{data?.ZipCodelabel}:</label>
                              <small>{data?.ZipCodevalue}</small>
                            </div>
                          )}
                        </Col>

                        <Col md={6} className=''>
                          {data?.Websitevalue && (
                            <div className="content">
                              <label>{data?.Websitelabel}:</label>
                              <small>{data?.Websitevalue}</small>
                            </div>
                          )}
                          {data?.Countryvalue && (
                            <div className="content">
                              <label>{data?.Countrylabel}:</label>
                              <small>{data?.Countryvalue}</small>
                            </div>
                          )}
                          {data?.CompanySizevalue && (
                            <div className="content">
                              <label>{data?.CompanySizelabel}:</label>
                              <small>{data?.CompanySizevalue}</small>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Row>
                }
                {companyDetail?.description &&
                  <Row className="pad-0 mt-20 bg-white">
                    <Row className='row-pad-0 mt-10 mb-1 '><Col md={12} className='pad-0'><h4 className="heading-h">About {companyDetail?.company_name}</h4> </Col>
                      <span className='single-border d-block'></span>
                    </Row>
                    <Col md={12} className="mt-1 mb-10">
                      <p dangerouslySetInnerHTML={{ __html: companyDetail?.description }} />
                    </Col>
                  </Row>
                }
                <Row className="pad-0 mt-20 bg-white">
                  {cultur?.length > 0 && (
                    <>
                      <Row className="row-pad-0 mt-10 mb-10">
                        <Col md={12} className="pad-0">
                          <h4 className="heading-h">{companyDetail?.company_name || " "} Photos</h4>
                        </Col>
                        <span className="single-border d-block"></span>
                      </Row>

                      <Row className="row-pad-0 mt-10 mb-10 d-flex justify-content-center">
                        <Col md={12} className="photos-carousel">
                          <Carousel
                            style={{
                              width: '400px',
                              height: '150px',
                            }}
                          >
                            {cultur.map((photo, index) => (
                              <Carousel.Item key={index}>
                                <Image
                                  src={`${config.API_BASE_URL_PUBLIC}/${photo.image_file}`}
                                  fluid
                                  style={{
                                    width: '100%', // Set to full width of the container
                                    height: '150px', // Set fixed height
                                    objectFit: 'cover' // Ensure the image covers the area
                                  }}
                                />
                              </Carousel.Item>
                            ))}
                          </Carousel>
                        </Col>
                      </Row>
                    </>
                  )}
                </Row>
              </Col>
              <Col md={5} className="">
                <Row className="pad-0 mt-10 bg-white ml-10">
                  {hasDataConnect && <Connectsocialmedia company={companyDetail} />}
                </Row>
              </Col>
            </Row>
          </Col>



        </Offcanvas.Body>
      </Offcanvas>
    </>





  );
};

export default ViewCompany;
