import { useState, useRef, useEffect } from "react";
import { CustomButton, InputField, Label } from "../form/index";
import { crossicon, faplusicon, deletclrred, attachment } from "../../helper/Icons/Icon";
import { Container, Row, Col, Button, Offcanvas } from "react-bootstrap";
import InterviewApps from "./InterviewApps";
import { useDispatch } from 'react-redux';
import Spinner from "./Spinner";
import Swal from 'sweetalert2';
import { create } from "../../store/actions/interviewActions";
import moment from 'moment';
import TextEditor from "./TextEditor";
import InterviewNatureTypes from "./InterviewNatureTypes";

const ScheduleInterviews = ({ handleClose, meetingData }) => {
    const dispatch = useDispatch();
    const today = moment().format('YYYY-MM-DDTHH:mm');
    const [errors, setErrors] = useState({});
    const [errorsLink, setErrorsLink] = useState({});

    const [contentEditor, setContentEditor] = useState('');
    const [data, setData] = useState({
        job_id: meetingData.job_id,
        candidate_id: meetingData.candidate_id,
        interview_type_id: 2,
        attachments: '',
        interview_rating: 0,
        comments: "",
        description: "",
        end_timestamp: meetingData.end,
        interview_app_id: "",
        interview_title: '',
        start_timestamp: meetingData.start,
        interview_nature: '',
        interviewees: [{ email: meetingData.follow_up_user_email }],
        candidates: [{ email: meetingData.email_address, name: meetingData.first_name + ' ' + meetingData.last_name, phone: +19525486607 }]
    });
    const [interviewee, setInterviewee] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [hideLinkField, setHideLinkField] = useState(false);
    const [showLoading, setLoading] = useState(false);
    const fileInputRef = useRef(null);

    // Handle file input change
    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (!file) {
            Swal.fire({
                icon: "error",
                title: "Error!",
                text: 'No file selected',
                showConfirmButton: false,
                timer: 2500
            });
            setSelectedFile(null);
            return;
        }

        if (file.size > 1 * 1024 * 1024) {
            Swal.fire({
                icon: "error",
                title: "Error!",
                text: 'File size exceeds 1MB',
                showConfirmButton: false,
                timer: 2500
            });
            setSelectedFile(null);
            return;
        }

        const allowedFormats = ['doc', 'docx', 'pdf', 'rtf', 'txt', 'odt'];
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (!allowedFormats.includes(fileExtension)) {
            Swal.fire({
                icon: "error",
                title: "Error!",
                text: 'Invalid file format',
                showConfirmButton: false,
                timer: 2500
            });
            setSelectedFile(null);
            return;
        }

        setErrors(prevErrors => ({
            ...prevErrors,
            file: ''
        }));
        setSelectedFile(file);
        setData(prevData => ({
            ...prevData,
            attachments: file.name
        }));
    };

    const triggerFileInputClick = () => {
        fileInputRef.current.click();
    };

    const sweetAlert = (type, title, text) => {
        Swal.fire({
            icon: type,
            title: title,
            text: text,
            showConfirmButton: false,
            timer: 2000
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;


        setData(prevData => ({
            ...prevData,
            [name]: value
        }));
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: value.trim() ? '' : ' is required'
        }));
        if (name === 'interview_app_id') {
            setHideLinkField(value === '1' ? false : true);

            setErrorsLink(true)


        }
        if (name == "interview_link") {
            setErrorsLink(value.trim() ? true : false)
        }

    };
    const handleSearchChange = (name, value) => {
        setData(prevData => ({
            ...prevData,
            [name]: value
        }));
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: value.toString().trim() ? '' : ' is required'
        }));
    }


    useEffect(() => {
        if (contentEditor) {
            setData(prevData => ({
                ...prevData,
                description: contentEditor
            }));
        }
    }, [contentEditor]);

    const handleChangeInterviewee = (e, index) => {
        const { value } = e.target;
        const newInterviewees = [...data.interviewees];
        newInterviewees[index].email = value;

        setData(prevData => ({
            ...prevData,
            interviewees: newInterviewees
        }));

        const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
        const error = emailPattern.test(value) ? '' : 'Invalid email format';

        setErrors(prevErrors => ({
            ...prevErrors,
            [`interviewee-${index}`]: error
        }));
    };

    const handleAddInterviewee = () => {
        const newInterviewees = [...data.interviewees];
        const emptyFields = newInterviewees.some(field => !field.email.trim());

        if (emptyFields) {
            const updatedErrors = {};
            newInterviewees.forEach((field, index) => {
                if (!field.email.trim()) {
                    updatedErrors[`interviewee-${index}`] = 'Email is required';
                }
            });

            setErrors(prevErrors => ({
                ...prevErrors,
                ...updatedErrors,
                interviewee: 'At least one email field must be filled'
            }));
        } else {
            setData(prevData => ({
                ...prevData,
                interviewees: [...prevData.interviewees, { email: interviewee }]
            }));
            setInterviewee('');
            setErrors(prevErrors => ({
                ...prevErrors,
                interviewee: ''
            }));
        }
    };

    const handleRemoveInterviewee = (index) => {
        setData(prevData => ({
            ...prevData,
            interviewees: prevData.interviewees.filter((_, i) => i !== index)
        }));
        setErrors(prevErrors => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[`interviewee-${index}`];
            return updatedErrors;
        });
    };





    const handleSubmit = (e) => {
        e.preventDefault();

        const newErrors = {};
        if (!data.interviewees.length) {
            newErrors['interviewee'] = 'Email is required';
        }
        if (errorsLink) {
            setErrorsLink(false)
        }
        const requiredFields = ['interview_title', 'interview_nature', 'start_timestamp', 'end_timestamp'];

        requiredFields.forEach(field => {
            if (!data[field]) {
                newErrors[field] = `${field.replace('_', ' ')} is required`;
            }
        });

        setErrors(prevErrors => ({
            ...prevErrors,
            ...newErrors
        }));

        if (Object.keys(newErrors).length) return;

        setLoading(true);
        dispatch(create(data))
            .then((res) => {
                setLoading(false);
                if (res.payload.error) {
                    sweetAlert("error", "Error!", res.payload.error);
                } else {
                    handleClose();
                    sweetAlert("success", "Success!", res.payload.message);
                }
            })
            .catch(() => {
                setLoading(false);
                sweetAlert("error", "Error!", "Something went wrong!");
            });
    };

    return (
        <Offcanvas
            show={true}
            onHide={handleClose}
            placement="end"
            className="bg-ligte-gray custom-offcanvas"
            style={{ width: "700px" }}
        >
            <Container fluid className="row-pad-0 pad-0 bg-ligte-gray pad-20">
                <Offcanvas.Header className="model-header bg-white">
                    <Offcanvas.Title>
                        <span className="model-header-svg">Schedule Interview</span>
                    </Offcanvas.Title>
                    <div className="text-end">
                        <CustomButton onClick={handleClose} className="text-end close-btn" text={crossicon} />
                    </div>
                </Offcanvas.Header>
                {showLoading && <Spinner />}
                <Offcanvas.Body className="wrap pad-10 InviteCandidate-wrap">
                    <Row className="row-pad-0 bg-white pad-10 radius-7 row">
                        <Col className="mb-10" md={6}>
                            <InterviewNatureTypes Changehandle={handleSearchChange} />
                            {errors.interview_nature && <span className="text-danger">{errors.interview_nature}</span>}
                        </Col>
                        <Col className="mb-10" md={6}>
                            <Label text="Interview Title" htmlFor="interview_title" className="label-input" required="required" />
                            <InputField
                                type="text"
                                placeholder="Write an interview title"
                                name="interview_title"
                                id="interview_title"
                                value={data.interview_title}
                                onChange={handleChange}
                            />
                            {errors.interview_title && <span className="text-danger">{errors.interview_title}</span>}
                        </Col>

                        <Col className="mb-10" md={6}>
                            <Label text="Interview Start Time" htmlFor="start_timestamp" className="label-input" />
                            <InputField
                                type="datetime-local"
                                name="start_timestamp"
                                id="start_timestamp"
                                className="cursor"
                                value={moment(meetingData.start).format('YYYY-MM-DDTHH:mm')}
                                onChange={handleChange}
                                disabled={true}
                            />

                        </Col>
                        <Col className="mb-10" md={6}>
                            <Label text="Interview End Time" htmlFor="end_timestamp" className="label-input" />
                            <InputField
                                type="datetime-local"
                                name="end_timestamp"
                                id="end_timestamp"
                                className="cursor"
                                disabled={true}
                                value={moment(meetingData.end).format('YYYY-MM-DDTHH:mm')}

                            />

                        </Col>
                        <Col md={12} className="mb-10">
                            <InterviewApps onChange={handleChange} />
                            {errors.interview_app_id && <span className="text-danger">{errors.interview_app_id}</span>}
                        </Col>
                        {hideLinkField && (
                            <>

                                <Col className="mb-10" md={12}>
                                    <Label text="Add Link" htmlFor="interview_link" className="label-input" required="required" />
                                    <InputField
                                        type="text"
                                        placeholder="http://"
                                        id="interview_link"
                                        name="interview_link"
                                        value={data.interview_link}
                                        onChange={handleChange}
                                    />
                                    {!errorsLink && <span className="text-danger">App link is required </span>}
                                </Col>
                            </>
                        )}
                        <Col className="mb-10" md={12}>
                            <Label text="Interview Description" htmlFor="description" className="label-input" />
                            <TextEditor
                                textValue={contentEditor}
                                setTextValue={setContentEditor}
                                placeholderValue={"Share interview details for the candidate"}
                            />
                        </Col>

                        <Col className="mb-10" md={12}>
                            <Label text="Attachments" htmlFor="attachments" className="label-input" />
                            <input
                                type="file"
                                className="hide"
                                onChange={handleFileChange}
                                ref={fileInputRef}
                            />
                            <Button variant="primary" className="d-block attachment-btn" onClick={triggerFileInputClick}>
                                {selectedFile ? selectedFile.name : <>{attachment}<span>Choose File</span></>}
                            </Button>
                            {errors.file && <span className="text-danger">{errors.file}</span>}
                            <span className="attachment-span">Supported file formats: doc, docx, pdf, rtf, text, and odt. Max file size: 1MB.</span>
                        </Col>

                        {data.interviewees.map((field, index) => (
                            <Col key={index} md={12} className="d-flex InviteCandidate-addinput mb-10">
                                <Col md={10} sm={8}>
                                    <InputField
                                        type="text"
                                        name={`email-${index}`}
                                        placeholder="Enter interviewee email"
                                        value={field.email}
                                        disabled={index === 0}
                                        onChange={(e) => handleChangeInterviewee(e, index)}
                                    />
                                    {errors[`interviewee-${index}`] && <span className="text-danger">{errors[`interviewee-${index}`]}</span>}
                                </Col>

                                <Col md={2} sm={4} style={{ display: index === 0 ? "none" : "block" }}>
                                    <span className="ml-20" onClick={() => handleRemoveInterviewee(index)}>{crossicon}</span>
                                </Col>
                            </Col>
                        ))}


                        <Col md={12} className="mb-10">
                            <Col md={12} className="d-flex InviteCandidate-addinput mb-10">
                                <Col md={2} sm={4}>
                                    <CustomButton text="Add More" className="custom-btn-primary mr-20 h-33" onClick={handleAddInterviewee} />
                                </Col>
                            </Col>
                        </Col>

                        <Col md={12} className="mb-10">
                            <Label text="Add Candidate" htmlFor="AddCandidates" className="label-input" required="required" />
                            <div className="d-flex justify-content-between">
                                <div className="mr-10">
                                    <InputField
                                        type="email"
                                        placeholder="Add email address"
                                        name="email"
                                        value={meetingData.email_address}
                                        disabled={true}
                                    />

                                </div>
                                <div className="mr-10">
                                    <InputField
                                        type="text"
                                        placeholder="Enter name"
                                        name="name"
                                        value={meetingData.first_name + ' ' + meetingData.last_name}
                                        disabled={true}

                                    />

                                </div>
                                <div className="mr-10">
                                    <InputField
                                        type="text"
                                        placeholder="Enter phone number"
                                        name="phone"
                                        value={meetingData.phone}
                                        disabled={true}
                                    />

                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Col md={12} className="text-end mt-20 mb-20">
                        <CustomButton onClick={handleSubmit} className="custom-btn-primary" text="Submit" />
                    </Col>
                </Offcanvas.Body>
            </Container>
        </Offcanvas>
    );
};

export default ScheduleInterviews;


















