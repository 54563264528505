import React from 'react';
import { Link } from 'react-router-dom';


function Analytics({ className, icon, link, activeNumber, text, cardModel = null }) {



  if (link) {
    return (
      <Link to={link} target="_blank" className="icon">
        <div className='y-center'>
          <div className={`Circlediv-wrap ${className}`}>


            <Link className="icon">
              {icon}
            </Link>


          </div>
          <div className="active-status-company">
            <span className='number-circle'>{activeNumber}</span><span className='text-circle'>{text}</span>
          </div>
        </div>
      </Link>
    )
  }
  else {


    return (
      <>
        <div className={`y-center ${cardModel ? 'cursor' : ''}`} onClick={cardModel ? cardModel : null} >
          <div className={`Circlediv-wrap ${className}`}>

            <span className="icon">{icon}</span>



          </div>
          <div className="active-status-company">
            <span className='number-circle'>{activeNumber}</span><span className='text-circle'>{text}</span>
          </div>
        </div >
      </>
    );

  }
}

export default Analytics;