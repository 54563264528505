import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { Row, Col, Offcanvas, Container, Form } from 'react-bootstrap';
import { crossicon, email } from '../../helper/Icons/Icon';
import { CustomButton, InputField, Label } from '../form';
import { update, getById } from '../../store/actions/emailTemplateActions';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import EmailTemplateTypes from '../common/EmailTemplateTypes';
import Spinner from "../common/Spinner";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const EditEmailTemplate = ({ emailTemplateId, handleClose }) => {
    const dispatch = useDispatch();
    const { emailTemplateDetail, loading, error } = useSelector((state) => state.emailTemplate);
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const editorRef = useRef(null);

    useEffect(() => {
        dispatch(getById(emailTemplateId));
    }, [dispatch, emailTemplateId]);

    useLayoutEffect(() => {
        if (emailTemplateDetail) {
            setFormData({
                email_template_type_id: emailTemplateDetail.email_template_type_id,
                emailSubject: emailTemplateDetail.subject,
                templateName: emailTemplateDetail.email_template_name,
                message: emailTemplateDetail.message
            });
        }
    }, [emailTemplateDetail]);

    const handleInsertVariable = (e) => {
        const selectedVariable = e.target.value;
        if (editorRef.current) {
            const currentContent = editorRef.current.getData();
            editorRef.current.setData(currentContent + selectedVariable);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const sweetAlert = (type, title, text) => {
        Swal.fire({
            icon: type,
            title: title,
            text: text,
            showConfirmButton: false,
            timer: 3000
        });
    };

    const handleEditorChange = (event, editor) => {
        const content = editor.getData();
        setErrors(prevErrors => ({
            ...prevErrors,
            message: content.trim() ? '' : 'Message is required',
        }));
        setFormData(prevData => ({ ...prevData, message: content }));
    };

    const handleSubmit = () => {
        const newErrors = {};
        if (!formData.templateName) newErrors.templateName = 'Template name is required';
        if (!formData.email_template_type_id) newErrors.email_template_type_id = 'Template type is required';
        if (!formData.emailSubject) newErrors.emailSubject = 'Email subject is required';
        if (!formData.message) newErrors.message = 'Message is required';

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            const formDataFields = {
                email_template_type_id: formData.email_template_type_id,
                subject: formData.emailSubject,
                email_template_name: formData.templateName,
                message: formData.message,
                is_active: 1,
            };

            dispatch(update({ emailTemplateId, formDataFields }))
                .then((res) => {
                    if (res.payload.error) {
                        sweetAlert("error", "Error!", `${res.payload.error}!`);
                    }
                    else {
                        handleClose();
                        sweetAlert("success", "Success!", `${res.payload.message}!`);
                    }
                })
                .catch(() => {
                    sweetAlert("error", "Error!", "Something went wrong!");
                });
        }
    };

    return (
        <Offcanvas show={true} onHide={handleClose} placement="end" className="bg-light-gray custom-offcanvas" style={{ width: "60%" }}>
            <Offcanvas.Header className="model-header bg-white actionlist-header ">
                <Offcanvas.Title>
                    <span className="model-header-svg">{email}Edit Email Template</span>
                </Offcanvas.Title>
                <div className="text-end">
                    <CustomButton onClick={handleClose} className="text-end close-btn" text={crossicon} />
                </div>
            </Offcanvas.Header>
            {loading && <Spinner />}
            <Container fluid className="pad-10">
                <Form>
                    <Row className="mt-20 row-pad-0 bg-white border-radius-5 mt-10 pad-10">
                        <Col md={6}>
                            <Label className="label-input" text="Template Name" required="required" />
                            <InputField type="text" className="input-field" name="templateName" placeholder="Add template name" onChange={handleChange} value={formData.templateName || ''} />
                            {errors.templateName && <span className="text-danger">{errors.templateName}</span>}
                        </Col>
                        <Col md={6}>
                            <EmailTemplateTypes templateTypeId={formData.email_template_type_id} Changehandle={handleChange} />
                            {errors.email_template_type_id && <span className="text-danger">{errors.email_template_type_id}</span>}
                        </Col>
                        <Col md={12} className='mt-10'>
                            <Label className="label-input" text="Email Subject" required="required" />
                            <InputField type="text" className="input-field" name="emailSubject" placeholder="Add email subject" onChange={handleChange} value={formData.emailSubject || ''} />
                            {errors.emailSubject && <span className="text-danger">{errors.emailSubject}</span>}
                        </Col>
                        <Col md={12} className='mt-10 sms-template-texterea'>
                            <Label className="label-text" text="Message" required="required" />
                            <CKEditor
                                editor={ClassicEditor}
                                data={formData.message || ""}
                                onReady={(editor) => {
                                    editorRef.current = editor;
                                }}
                                onChange={handleEditorChange}
                                config={{
                                    placeholder: "Enter your text here",
                                    toolbar: [
                                        'undo', 'redo', '|', 'bold', 'italic',
                                    ],
                                    height: 250
                                }}
                            />
                            <div className='selectoptionEmail' style={{ zIndex: '2' }}>
                                <Form.Select name='Insert-variabl' id='Insert-variabl' onChange={handleInsertVariable}>
                                    <option value="[Insert_Variable]">Insert Variable</option>
                                    <option value="[Company_Name]">Company Name</option>
                                    <option value="[Candidate_Name]">Candidate Name</option>
                                    <option value="[Interview_Link]">Recorded Interview Link</option>
                                    <option value="[No_of_days]">No. of days</option>
                                    <option value="[Unique_Link]">Unique Link</option>
                                    <option value="[Interview_Type]">Interview Type</option>
                                    <option value="[Interview_Title]">Interview Title</option>
                                    <option value="[Interview_Start_Time]">Interview Start Time</option>
                                    <option value="[Interview_End_Time]">Interview End Time</option>
                                    <option value="[Live_Interview_Link]">Live Interview Link</option>
                                    <option value="[Interview_Description]">Interview Description</option>
                                    <option value="[Job_Name]">Job Name</option>
                                    <option value="[Evaluator_Name]">Evaluator Name</option>
                                    <option value="[Recorded_Interview_Evaluation_Link]">Recorded Interview Evaluation Link</option>
                                </Form.Select>
                            </div>
                            {errors.message && <span className="text-danger">{errors.message}</span>}
                        </Col>
                        <Col md={12} className='text-end mt-10 pad-0'>
                            <CustomButton className="custom-btn-outline mr-10" text="Cancel" onClick={handleClose} />
                            <CustomButton className="custom-btn-primary" text="Save" onClick={handleSubmit} />
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Offcanvas>
    );
};

export default EditEmailTemplate;
