import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Row, Col, Offcanvas } from "react-bootstrap";
import virtelligenceTeam from "../../assets/images/virtelligenceteam.jpg";
import Companyoverview from "./Companyoverview";
import { CustomButton } from "../form/index";
import AboutCompany from "./AboutCompany";
import Photos from "./Photos";
import Highlights from "./Highlights";
import Spinner from "../common/Spinner";
import Connectsocialmedia from "./Connectsocialmedia";
import JobsLike from "./JobsLike";
import Paginate from "../common/Paginate";
import { JobActive, crossicon } from "../../helper/Icons/Icon";
import { getById, getCompanyJobs, companyhighlights } from "../../store/actions/companyActions";
import HeaderCompanName from "./HeaderCompanName";
import { getAll } from "../../store/actions/documentActions";
const config = require('../../config/config.json');

const defaultPerPage = 25
const defaultCurrentPage = 1
const ViewCompanyModel = () => {
  const companyId = sessionStorage.getItem('companyID');

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerPage = (perPage) => {
    setPerPage(perPage);
  };
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
  const [perPage, setPerPage] = useState(defaultPerPage);

  useEffect(() => {


    if (companyId) {
      let payload = {
        'limit': perPage, 'current_page': currentPage,
      }
      dispatch(getById(companyId));
      dispatch(getCompanyJobs({ companyId, payload }));
      dispatch(companyhighlights(companyId));
      dispatch(getAll({ module_name: "companies", module_pk_id: companyId }));
    }
  }, [dispatch, companyId]);

  const { companyDetail, loading, error } = useSelector((state) => state.company);
  const { CompanyJobs } = useSelector((state) => state.company);
  const { highlightData } = useSelector((state) => state.company);

  const jobs = CompanyJobs?.data;

  const { documents } = useSelector((state) => state.document);


  let pages = CompanyJobs.pagination?.current_page;
  let totalPages = CompanyJobs?.pagination?.total_pages;
  let totalRecords = CompanyJobs?.pagination?.total_jobs;


  const data = {
    Companylabel: "Company Name",
    Companyvalue: companyDetail ? companyDetail?.company_name : "-",
    Industrylabel: "Industry",
    Industryvalue: companyDetail ? companyDetail?.industry?.industry_name : "-",
    Citylabel: "City, State",
    Cityvalue: companyDetail ? companyDetail?.city + ", " + companyDetail?.state_name : "-",
    ZipCodelabel: "Zip Code",
    ZipCodevalue: companyDetail ? companyDetail?.zip_code : "-",
    Websitelabel: "Website",
    Websitevalue: companyDetail ? companyDetail?.website_url : "-",
    Countrylabel: "Country",
    Countryvalue: companyDetail ? companyDetail?.country_name : "-",
    CompanySizelabel: "Company Size",
    CompanySizevalue: companyDetail ? companyDetail?.company_size : "-",
  };



  return (
    <>

      {loading && <Spinner />}
      {companyDetail &&
        <>
          <HeaderCompanName logo={<img src={`${companyDetail.company_logo}`} style={{ width: "100%", height: "100%", }} alt="company logo" />} bgclr={companyDetail?.header_footer_colour_code} name={companyDetail.company_name} />

          <Col className="pad-20 bg-ligte-gray y-scroll">
            <Row className="row-pad-0 virtelligenceTeam-card pb-10 ">
              <Col md={12} className="p-relative pad-0 ">
                <div className="virtelligenceTeam-img">
                  <img
                    src={`${companyDetail?.company_banner}`}
                    alt="Banner"

                  />
                </div>
                <div className="profile-image-view-company p-absolute ">
                  <img src={`${companyDetail?.company_logo}`} alt="Logo" />
                </div>
                <div className="company-name">
                  <b>{companyDetail?.company_name}</b>
                </div>
              </Col>
            </Row>
            <Row className="row-pad-0 mt-20">
              <Col md={7} className="">
                <Row className="pad-0 mt-20 bg-white">
                  <Companyoverview data={data} />
                </Row>
                {companyDetail.company_description &&
                  < Row className="pad-0 mt-20 bg-white">
                    <AboutCompany companyDetail={companyDetail} />
                  </Row>
                }
                <Row className="pad-0 mt-20 bg-white">
                  <Photos company={companyDetail} documents={documents} />
                </Row>
              </Col>

              <Col md={5} className="">
                <Row className="pad-0 mt-20 bg-white ml-10">
                  <Highlights company={highlightData} />
                </Row>
                <Row className="pad-0 mt-10 bg-white ml-10">
                  <Connectsocialmedia company={companyDetail} />
                </Row>
                {jobs && jobs.length > 0 && <Row className="pad-0 mt-20 bg-white ml-10 pad-10">
                  <Col md={12} className="pad-0 mb-10 ">
                    {" "}
                    <JobsLike JobActive={JobActive} jobs={jobs} />
                    <Col md={12} className="d-flex justify-content-center mt-15">
                      {jobs && jobs.length > 0 &&
                        <Paginate

                          pages={pages}
                          currentPage={currentPage}
                          totalPages={totalPages}
                          perPage={perPage}
                          totalRecords={totalRecords}
                          handlePageChange={handlePageChange}
                          handlePerPage={handlePerPage}

                          pagenation="only"

                        />}
                    </Col>   </Col>


                </Row>
                }
                {/* <Col md={12} className="pad-0 mt-20 d-flex justify-content-center">
                  <CustomButton
                    size="lg"
                    // onClick={ProceedInterview}
                    className="btn-full"
                    text="Proceed with the Interview"
                  />
                </Col> */}
              </Col>
            </Row>
          </Col >
        </>
      }

    </>





  );
};

export default ViewCompanyModel;
