import React, { useState, useCallback } from 'react';
import { Row, Col, Offcanvas, Container, Form } from 'react-bootstrap';
import { CustomButton, InputField, Label } from '../form';
import { create } from '../../store/actions/emailTemplateActions';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import EmailTemplateTypes from '../common/EmailTemplateTypes';
import { crossicon, email } from '../../helper/Icons/Icon';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'; // Use custom build if created
import Spinner from "../common/Spinner";

const AddEmailTemplate = ({ handleClose }) => {
    const [formData, setFormData] = useState({
        templateName: '',
        emailSubject: '',
        email_template_type_id: '',
    });
    const [errors, setErrors] = useState({});
    const [editor, setEditor] = useState(null);
    const dispatch = useDispatch();
    const { emailTemplateDetail, loading, error } = useSelector((state) => state.emailTemplate);

    const handleInsertVariable = useCallback((e) => {
        const selectedVariable = e.target.value;
        if (editor) {
            const currentContent = editor.getData();
            editor.setData(currentContent + selectedVariable);
        }
    }, [editor]);

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: value.trim() ? '' : ' required',
        }));
    }, []);

    const handleEditorChange = useCallback((event, editorInstance) => {
        setEditor(editorInstance);
        const data = editorInstance.getData();
        setErrors(prevErrors => ({
            ...prevErrors,
            message: data.trim() ? '' : ' required',
        }));
    }, []);

    const sweetAlert = (type, title, text) => {
        Swal.fire({
            icon: type,
            title: title,
            text: text,
            showConfirmButton: false,
            timer: 3000,
        });
    };

    const handleSubmit = () => {
        const newErrors = {};
        if (!formData.templateName) newErrors.templateName = 'Template name is required';
        if (!formData.emailSubject) newErrors.emailSubject = 'Email subject is required';
        if (!editor?.getData()) newErrors.message = 'Message is required';
        if (!formData.email_template_type_id) newErrors.email_template_type_id = 'Template type is required';

        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            const payload = new FormData();
            payload.append('email_template_type_id', formData.email_template_type_id);
            payload.append('email_template_name', formData.templateName);
            payload.append('subject', formData.emailSubject);
            payload.append('message', editor?.getData());
            payload.append('is_active', 1);

            dispatch(create(payload))
                .then((res) => {
                    if (res.payload.error) {
                        sweetAlert("error", "Error!", `${res.payload.error}!`);
                    } else {
                        sweetAlert("success", "Success!", "Email template added successfully!");
                        handleClose();
                    }
                })
                .catch(() => {
                    sweetAlert("error", "Error!", "Something went wrong!");
                });
        }
    };

    return (
        <Offcanvas show={true} onHide={handleClose} placement="end" className="bg-light-gray custom-offcanvas" style={{ width: "60%" }}>
            <Offcanvas.Header className="model-header bg-white actionlist-header">
                <Offcanvas.Title>
                    <span className="model-header-svg">{email} Add Email Template</span>
                </Offcanvas.Title>
                <div className="text-end">
                    <CustomButton onClick={handleClose} className="text-end close-btn" text={crossicon} />
                </div>
            </Offcanvas.Header>
            {loading && <Spinner />}
            <Container fluid className="pad-10" style={{ overflowY: 'auto' }}>
                <Row className="mt-20 row-pad-0 bg-white border-radius-5 mt-10 pad-10">
                    <Col md={6}>
                        <Label className="label-input" text="Template Name" required="required" />
                        <InputField
                            type="text"
                            name="templateName"
                            className="input-field"
                            placeholder="Add template name"
                            onChange={handleChange}
                            value={formData.templateName}
                        />
                        {errors.templateName && <span className="text-danger">{errors.templateName}</span>}
                    </Col>
                    <Col md={6}>
                        <EmailTemplateTypes Changehandle={handleChange} />
                        {errors.email_template_type_id && <span className="text-danger">{errors.email_template_type_id}</span>}
                    </Col>
                    <Col md={12} className='mt-10'>
                        <Label className="label-input" text="Email Subject" />
                        <InputField
                            type="text"
                            name="emailSubject"
                            className="input-field"
                            placeholder="Add email subject"
                            onChange={handleChange}
                            value={formData.emailSubject}
                        />
                        {errors.emailSubject && <span className="text-danger">{errors.emailSubject}</span>}
                    </Col>
                    <Col md={12} className='mt-10 sms-template-texterea'>
                        <Label className="label-input" text="Message" required="required" />
                        <CKEditor
                            editor={ClassicEditor}

                            onReady={editorInstance => setEditor(editorInstance)}
                            onChange={(event, editorInstance) => handleEditorChange(event, editorInstance)}
                            config={{
                                placeholder: "Enter your text here",
                                height: '250px', // Set the height here
                                toolbar: [
                                    'undo', 'redo', '|', 'bold', 'italic',
                                ],

                            }}
                        />
                        <div className='selectoptionEmail' style={{ zIndex: '2' }}>
                            <Form.Select name='Insert-variabl' id='Insert-variabl' onChange={handleInsertVariable}>
                                <option value="[Insert_Variable]">Insert Variable</option>
                                <option value="[Company_Name]">Company Name</option>
                                <option value="[Company_Logo]">Company Logo</option>
                                <option value="[Position_Name]">Position Name</option>

                                <option value="[Candidate_Name]">Candidate Name</option>
                                <option value="[Interview_Link]">Recorded Interview Link</option>
                                <option value="[No_of_days]">No.of days</option>
                                <option value="[Unique_Link]">Unique Link</option>
                                <option value="[Interview_Type]">Interview Type</option>
                                <option value="[Interview_Title]">Interview Title</option>
                                <option value="[Interview_Start_Time]">Interview Start Time</option>
                                <option value="[Interview_End_Time]">Interview End Time</option>
                                <option value="[Live_Interview_Link]">Live Interview Link</option>
                                <option value="[Interview_Description]">Interview Description</option>
                                <option value="[Job_Name]">Job Name</option>
                                <option value="[sender_Name]">Sender Name</option>
                                <option value="[Evaluator_Name]">Evaluator Name</option>
                                <option value="[Recorded_interview_Evaluation_Link]">Recorded interview Evaluation Link</option>
                            </Form.Select>
                        </div>
                        {errors.message && <span className="text-danger">{errors.message}</span>}
                    </Col>
                </Row>
                <Col md={12} className='text-end mt-10 pad-0'>
                    <CustomButton className="custom-btn-outline mr-10" text="Cancel" onClick={handleClose} />
                    <CustomButton className="custom-btn-primary" text="Save" onClick={handleSubmit} />
                </Col>
            </Container>
        </Offcanvas>
    );
};

export default AddEmailTemplate;
