import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { jobAnalytics } from "../store/actions/DashboardAction";
import { createPreference } from "../store/actions/userActions";
import { getByName } from "../store/actions/userPrefrenceActions";
import Spinner from "../components/common/Spinner";
import Filter from "./Filter";
import { Modal, Button, Row, Col } from "react-bootstrap";
import JobAnalytics from "../components/dashboard/JobAnalytics"; // Assuming this component exists

const JobAnalyticsCard = ({ cardModel, setJobAnalyticsDate }) => {
    const dispatch = useDispatch();


    const [showModal, setShowModal] = useState(false);
    const [customProps, setCustomProps] = useState(null);




    const [formData, setFormData] = useState({
        startDate: new Date().setHours(0, 0, 0, 0),
        endDate: new Date(),
    });
    const [dateFilters, setDateFilters] = useState({
        jobAnalytics: { startDate: new Date(), endDate: new Date() },
    });


    const getDateRange = () => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        return { today, yesterday };
    };

    // Get today's and yesterday's date and store them in the state
    const { today, yesterday } = getDateRange();

    const { dashboardAnalytics, loading } = useSelector((state) => state.dashboard);
    const { userPrefrenceDetail } = useSelector((state) => state.userPrefrence);

    useEffect(() => {
        setFormData({
            startDate: yesterday,
            endDate: today,
        });

        setDateFilters({
            jobAnalytics: { startDate: yesterday, endDate: today },
        });

        dispatch(getByName("jobAnalytics"));
    }, [dispatch]);

    useEffect(() => {
        if (userPrefrenceDetail?.preference_value?.startDate && userPrefrenceDetail?.preference_value?.endDate) {
            const { startDate, endDate } = userPrefrenceDetail.preference_value;

            setFormData({
                startDate: new Date(startDate),
                endDate: new Date(endDate),
            });
            setJobAnalyticsDate({
                startDate: new Date(startDate),
                endDate: new Date(endDate),
            }
            )
            setDateFilters({
                jobAnalytics: { startDate: new Date(startDate), endDate: new Date(endDate) },
            });

            dispatch(jobAnalytics({ startDate, endDate }));
        }
        else {

            dispatch(jobAnalytics({ today, yesterday }));
        }
    }, [userPrefrenceDetail, dispatch]);

    // Handle date change
    const handleDateChange = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const handleSubmit = async () => {
        const { startDate, endDate } = formData;

        console.log("Submitting with startDate:", startDate, "endDate:", endDate);

        if (!startDate || !endDate) {
            console.error("Start date and end date are required.");
            return;
        }

        setDateFilters((prev) => ({
            ...prev,
            [customProps]: { startDate, endDate },
        }));

        const payload = {
            preference_value: {
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
            },
            preference_name: customProps,
            module_code: "dashboardCard",
        };

        try {

            await dispatch(createPreference(payload));
            console.log("Preference created successfully.");

            await dispatch(jobAnalytics({ startDate, endDate }));
            console.log("Job Analytics API called with:", { startDate, endDate });

            setJobAnalyticsDate({
                startDate: startDate,
                endDate: endDate,
            });

            setShowModal(false);
        } catch (error) {
            console.error("Error during form submission:", error);
        }
    };



    const handleReset = () => {
        setFormData({
            startDate: yesterday,
            endDate: today,
        });

        setDateFilters({
            jobAnalytics: { startDate: yesterday, endDate: today },
        });

        const payload = {
            preference_value: {
                startDate: yesterday.toISOString(),
                endDate: today.toISOString(),
            },
            preference_name: customProps,
            module_code: "dashboardCard",
        };

        dispatch(createPreference(payload))
            .then(() => {
                if (customProps === "jobAnalytics") {
                    dispatch(jobAnalytics({ startDate: yesterday, endDate: today }));
                }
                setJobAnalyticsDate({
                    startDate: yesterday,
                    endDate: today,
                })
                setShowModal(false); // Close modal after resetting filters
            })
            .catch((error) => console.error("Reset preference update error:", error));
    };

    // Handle opening the modal
    const handleOpenModal = (type) => {
        setCustomProps(type);
        setShowModal(true); // Open the modal
    };

    // Updated isYesterdayFilter to use today and yesterday from state
    const isYesterdayFilter = () => {
        const { startDate, endDate } = formData;
        return (
            startDate instanceof Date &&
            endDate instanceof Date &&
            startDate.getDate() === yesterday.getDate() &&
            startDate.getMonth() === yesterday.getMonth() &&
            startDate.getFullYear() === yesterday.getFullYear() &&
            endDate.getDate() === today.getDate() &&
            endDate.getMonth() === today.getMonth() &&
            endDate.getFullYear() === today.getFullYear()
        );
    };

    return (
        <>
            {loading && <Spinner />}
            <Row className="row-pad-0 mt-20 border-radius-5 bg-white dashboard-analytics">
                {dashboardAnalytics && (
                    <JobAnalytics
                        cardModel={cardModel}
                        formData={dateFilters.jobAnalytics}
                        dashboardAnalytics={dashboardAnalytics}
                        handleOpenModal={() => handleOpenModal("jobAnalytics")}
                        Yesterday={isYesterdayFilter()}
                    />
                )}
            </Row>

            {showModal && (
                <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Filter</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Filter
                            startDate={formData.startDate}
                            endDate={formData.endDate}
                            onchange={handleDateChange}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleReset}>
                            Reset
                        </Button>
                        <Button variant="primary" onClick={handleSubmit}>
                            Apply
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export default JobAnalyticsCard;
