import { Row,Col } from "react-bootstrap";

function Companyoverview({data}){  
    
    return(
        <>
        <Row  className='row-pad-0  mt-10 mb-10 '>
            <Col md={12} className='pad-0'><h4 className="sub-heading">{data?.Companyvalue} Company Overview</h4> </Col>
            <span className='single-border d-block'></span>
        </Row>
       
        <Row className='row-pad-0 pad-0 mt-1 CompanyOverview-body'>          
            <Col md={6} className='' >
                <div className="content">
                    <label>{data?.Companylabel}:</label><small>{data?.Companyvalue}</small>
                </div>
                <div className="content">
                    <label >{data?.Industrylabel}:</label> <small >{data?.Industryvalue}</small>
                </div>
                <div className="content">
                    <label >{data?.Citylabel}:</label> <small >{data?.Cityvalue}</small>

                </div>
                <div className="content">
                    <label>{data?.ZipCodelabel}:</label> <small >{data?.ZipCodevalue}</small>

                </div>
            </Col>

            <Col md={6} className=''>
                <div className="content">
                    <label>{data?.Websitelabel}:</label> <small >{data?.Websitevalue}</small>
                </div>
                <div className="content">
                    <label>{data?.Countrylabel}:</label><small >{data?.Countryvalue}</small>
                </div>
                <div className="content">
                    <label>{data?.CompanySizelabel}:</label> <small >{data?.CompanySizevalue==0 ? "":data?.CompanySizevalue}</small>
                </div>
            </Col>
        </Row>
    
        </>
    )
}

export default Companyoverview;