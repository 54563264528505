import { Card, Row, Col } from "react-bootstrap";
import CriclrDiv from "../common/CriclrDiv";
import Analytics from "../common/Analytics";
import Pagination from "react-bootstrap/Pagination";

function JobsLike({jobs, JobActive }) {
    let companyJobsList = jobs;

    return (<>
    <Row  className='row-pad-0 mt-10 mb-10 '><Col md={12} className='pad-0'><h4 className="sub-heading">Jobs You May Like</h4> </Col>
        <span className='single-border d-block'></span>
    </Row>
    { companyJobsList && 
    companyJobsList.length > 0   ? 
        companyJobsList.map(job => {
            return (
                <Card className="joblikes-card mt-2" key={job.id}>
                    <Row>
                    {job.jobTitle ?    
                    
                    <Col md={12}>
              
                            <div className=" d-flex justify-content-between">
                                <div className=" d-flex">
                                    <CriclrDiv icon={JobActive}
                                        clrName="bg-white Circlediv-wrap" />

                                    <div className="active-status-company">
                                        <span className='number-circle'>{job.jobTitle}</span><span className='text-circle'>${job.paySalary+"/"+job.payType?.pay_type_name}</span>
                                    </div></div>
                                <p>{job.stateName+"/"+job.countryName}</p>

                            </div>

                        </Col>
                    : 
                    <Col md={12}>
              
                            <div className=" d-flex justify-content-between">
                                <div className=" d-flex">
                                    <CriclrDiv icon={JobActive}
                                        clrName="bg-white Circlediv-wrap" />

                                    <div className="active-status-company">
                                        <span className='number-circle'>{job.job_title}</span><span className='text-circle'>${job.pay_salary+"/"+job.pay_type_name}</span>
                                    </div></div>
                                    <p>${job.state_name+"/"+job.country_name}</p>

                            </div>

                        </Col>
                        
                        }
                    </Row>
                </Card>
            )
        })
       : <p>No record found.</p> 
    }

  

 

    </>)
}

export default JobsLike;
//   <Col md={12} className="d-flex justify-content-center mt-15">
//   <Pagination>
//     <Pagination.Prev>{"Previous"}</Pagination.Prev>
//     <Pagination.Item>{1}</Pagination.Item>
  
//     <Pagination.Item>{2}</Pagination.Item>
//     <Pagination.Item  active>{3}</Pagination.Item>

//     <Pagination.Ellipsis />
//     <Pagination.Item>{20}</Pagination.Item>
//     <Pagination.Next>{"Next"}</Pagination.Next>
//   </Pagination>
// </Col>