import { Table, Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Tabelcolumn from "./Tabelcolumn";
import { Video } from "../../helper/Icons/Icon";
import Liveterview from "../RecordedInterviewDetails/Liveterview";
import RecordedInterview from "../interview/RecordedInterview";
import Paginate from "./Paginate";
import { dashboardInterviews, getHiredCandidates } from "../../store/actions/DashboardAction";
import Spinner from "./Spinner";
import { headertimeLine } from "../interview/fields";
import Statusbtn from "./Statusbtn";

const defaultCurrentPage = 1;

const DashboardTable = ({ update, interviewTypeId }) => {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
    const [perPage, setPerPage] = useState(25);
    const [searchField, setSearchField] = useState({});
    const [sortConfig, setSortConfig] = useState({});
    const [interviewId, setInterviewId] = useState(null);
    const [showLiveterview, setShowLiveterview] = useState(false);
    const [showRecordedInterview, setShowRecordedInterview] = useState(false);

    const { dashboardInterviewsData, loading } = useSelector((state) => state.dashboard);
    const pages = dashboardInterviewsData?.pages || [];
    const totalPages = dashboardInterviewsData?.total_page || 0;
    const totalRecords = dashboardInterviewsData?.total_record || 0;
    const headers = dashboardInterviewsData?.headers || [];

    const handleFieldSearch = (e) => {
        const { name, value } = e.target;
        setSearchField(prev => ({ ...prev, [name]: value }));
    };

    const toggleSortOrder = (key) => {
        setSortConfig(prevConfig => ({
            key,
            order: prevConfig.key === key ? (prevConfig.order === 'asc' ? 'desc' : 'asc') : 'asc'
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const isHired = interviewTypeId.type === "Hired";


                const data = {
                    limit: perPage,
                    page: currentPage,
                    search: searchField,
                    ...(isHired ? {} : {
                        interview_type_id: interviewTypeId.type,

                        ...(interviewTypeId.name ? { status_name: interviewTypeId.name.toLowerCase() } : {}),
                    }),
                    ...(interviewTypeId.name ? { status_name: interviewTypeId.name.toLowerCase() } : {}),
                    startDate: interviewTypeId.startDate,
                    endDate: interviewTypeId.endDate,
                    order_by: sortConfig.key,
                    sort_by: sortConfig.order,
                };


                if (perPage) {
                    if (isHired) {
                        await dispatch(getHiredCandidates(data));
                    } else {
                        await dispatch(dashboardInterviews(data));
                    }
                }
            } catch (err) {
                console.error(err);
            }
        };

        fetchData();
    }, [dispatch, currentPage, perPage, searchField, sortConfig, update, interviewTypeId]);

    const handlePageChange = (page) => setCurrentPage(page);
    const handlePerPage = (perPage) => setPerPage(perPage);

    const handleModel = (type, id) => {

        if (type === "Video Interview") {
            setShowRecordedInterview(true);
        } else {
            setShowLiveterview(true);
        }
        setInterviewId(id);
    };

    const closeModel = (modelType) => {
        if (modelType === "RecordedInterview") {
            setShowRecordedInterview(false);
        } else {
            setShowLiveterview(false);
        }
    };

    return (
        <>
            {loading && <Spinner />}
            <div className="listing-section" style={{ overflowX: "auto" }}>
                <Table className="tabel">
                    <thead>
                        <tr>
                            <th style={{ minWidth: "150px" }} className="th-text">Quick Action</th>
                            {headers.map((header, index) => (
                                <th key={index} className="sorting" style={{ minWidth: "200px" }}>
                                    <span onClick={() => toggleSortOrder(header.key)}>
                                        {sortConfig.key === header.key ? (
                                            sortConfig.order === 'asc' ? <i className="fa fa-sort-amount-asc" /> : <i className="fa fa-sort-amount-desc" />
                                        ) : <i className="fa fa-exchange" />}
                                    </span>
                                    <Form.Control
                                        name={header.key}
                                        value={searchField[header.key] || ''}
                                        type="text"
                                        placeholder={header.title}
                                        onChange={handleFieldSearch}
                                    />
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {dashboardInterviewsData?.data?.map((item, index) => (
                            <tr key={index}>
                                <td className="cursor td-icon">
                                    <div className="quick-action">
                                        <Tabelcolumn
                                            className="cursor td-icon"
                                            span="span"
                                            icon={Video}
                                            onClick={() => handleModel(item.recorded_live, item.interview_type_id)}
                                            Tooltip={item.recorded_live}
                                        />
                                    </div>
                                </td>
                                {headers.map((header, headerIndex) => {
                                    const statusClass = item[header.key]?.replace(/\s+/g, '-').toLowerCase();
                                    return (
                                        <td key={headerIndex}>
                                            {header.key === "stage_name" || header.key === "candidate_status" || header.key === "job_status" ? (
                                                <Statusbtn className={`status ${statusClass}`} text={item[header.key]} />
                                            ) : (
                                                item[header.key] || '-'
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </Table>

                <Paginate
                    pages={pages}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    perPage={perPage}
                    totalRecords={totalRecords}
                    handlePageChange={handlePageChange}
                    handlePerPage={handlePerPage}
                />
            </div>
            {showLiveterview && <Liveterview id={interviewId} timeLineheader={headertimeLine} handleClose={() => closeModel("Liveterview")} />}
            {showRecordedInterview && <RecordedInterview id={interviewId} timeLineheader={headertimeLine} handleClose={() => closeModel("RecordedInterview")} />}
        </>
    );
};

export default DashboardTable;
