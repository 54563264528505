import React, { useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { Label, InputField, CustomButton } from '../components/form/index';
import { changePassword } from '../store/actions/authActions';
import { changepasswordfield } from "../components/accountSetting/FieldConfig";
import Swal from 'sweetalert2';
import { crossicon } from '../helper/Icons/Icon';

const sweetAlert = (type, title, text) => {
  Swal.fire({
    icon: type,
    title: title,
    text: text,
    showConfirmButton: false,
    timer: 2000
  });
}
const ChangePassword = ({ handleClose, userid }) => {
  const id = userid ? userid : "";
  const [formData, setFormData] = useState({ oldPassword: '', newPassword: '', confirmPassword: '' });
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false
  });

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: value.trim() ? '' : ' is required'
    }));

    if (name === 'newPassword' || name === 'confirmPassword') {
      if (name === 'newPassword') {
        setPasswordsMatch(formData.confirmPassword === value);
      } else if (name === 'confirmPassword') {
        setPasswordsMatch(formData.newPassword === value);
      }
    }
  };

  const handleSave = () => {
    const newErrors = {};
    if (!formData.newPassword) {
      newErrors.newPassword = 'New password is required';
    }
    if (!formData.oldPassword) {
      newErrors.oldPassword = 'Current password is required';
    }
    if (formData.newPassword !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const payload = {
        "new_password": formData.newPassword,
        "current_password": formData.oldPassword,
      };
      dispatch(changePassword({ id, payload }))
        .then((res) => {
          if (res.payload.error) {
            sweetAlert("error", "Error!", `${res.payload.error}!`);
          } else {
            sweetAlert("success", "Success!", `${res.payload.message}!`);
            handleClose();
          }
        })
        .catch(error => {
          sweetAlert("error", "Error!", "Disconnect Your Internet!");
        });
    }
  };

  const togglePasswordVisibility = (field) => {
    setShowPassword(prevState => ({
      ...prevState,
      [field]: !prevState[field]
    }));
  };

  return (
    <Offcanvas show={true} onHide={handleClose} placement='end' className="bg-ligte-gray pad-20 custom-offcanvas" style={{ width: "40%" }}>
      <Offcanvas.Header className="model-header-changePsd  bg-white ">
        <Offcanvas.Title>Change Password</Offcanvas.Title>
        <div className="text-end">
          <CustomButton onClick={handleClose} className="text-end close-btn " text={crossicon} />
        </div>
      </Offcanvas.Header>

      <Offcanvas.Body className='mt-10 bg-white pad-20 radius-7'>
        <Row className='row-pad-0'>
          <h1>{changePassword}</h1>
          <Col md={12} className='d-flex justify-content-center'>
            <div className='changepassword-key'>
              <FontAwesomeIcon icon={faKey} />
            </div>
          </Col>
        </Row>
        <Form>
          <Row className="row-pad-0 mt-20 d-flex justify-content-center">
            {changepasswordfield.map((field, index) => (
              <Col sm={12} xs={12} className='mb-10 p-relative' key={index}>
                <Label text={field.text} htmlFor={field.id} className={field.className} required="required" />

                <InputField
                  type={field.type === "password" && showPassword[field.id] ? "text" : field.type}
                  placeholder={field.placeholder}
                  id={field.id}
                  name={field.id}
                  onChange={handleChange}
                  value={formData[field.id]}
                  className={field.id === 'confirmPassword' && !passwordsMatch ? 'border-danger' : ''}
                />
                <span
                  onClick={() => togglePasswordVisibility(field.id)}
                  className={`password-faEye fa fa-fw field-icon ${showPassword[field.id] ? "fa-eye-slash" : "fa-eye"}`}
                >



                </span>

                {errors[field.id] && <span className="text-danger">{errors[field.id]}</span>}
                {field.id === 'confirmPassword' && !passwordsMatch && <div className="text-danger">Passwords do not match</div>}
              </Col>
            ))}

          </Row>
        </Form>
      </Offcanvas.Body>
      <Col md={12} className='text-end mt-10'>
        <CustomButton className="custom-btn-primary" text="Save" onClick={handleSave} />
      </Col>
    </Offcanvas>
  );
}

export default ChangePassword;
