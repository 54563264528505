import { createSlice } from '@reduxjs/toolkit'
import {  } from '../actions/authActions'
import { create,get,getPlan,stripePayment,getAmountBreakdown, updatePackagepurchased ,update,accountUsageInfo,getPackages ,packagePurchased,packageFinal,getPackagesAll,downloadInvoice, accountStatus} from '../actions/accountBillingAction'

const initialState = {
    loading: false,
    accountBilling:null,
    accountInfo:null,
    packagesGet:null,
    purchasedPackage:null,
    allPackage:null,
    monthlyPlan:null,
    updatePackge:null,
    accountStatusverify:null,
    amoutBreakDown:null,
    error: null,
    
}

const accountBillingSlice = createSlice({
    name: 'accountBilling',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(create.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(create.fulfilled, (state, action) => {
                state.loading = false
                state.accountBilling = action.payload
           
            })
            .addCase(create.rejected, (state, action) => {
                state.loading = false
               
            }) .addCase(get.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(get.fulfilled, (state, action) => {
                state.loading = false
                state.accountBilling = action.payload
           
            })
            .addCase(get.rejected, (state, action) => {
                state.loading = false
               
            }).addCase(update.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(update.fulfilled, (state, action) => {
                state.loading = false
                state.accountBilling = action.payload
           
            })
            .addCase(update.rejected, (state, action) => {
                state.loading = false
            }).addCase(accountUsageInfo.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(accountUsageInfo.fulfilled, (state, action) => {
                state.loading = false
                state.accountInfo = action.payload
           
            })
            .addCase(accountUsageInfo.rejected, (state, action) => {
                state.loading = false
            }).addCase(getPackages.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(getPackages.fulfilled, (state, action) => {
                state.loading = false
                state.packagesGet = action.payload
           
            })
            .addCase(getPackages.rejected, (state, action) => {
                state.loading = false
            }).addCase(packagePurchased.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(packagePurchased.fulfilled, (state, action) => {
                state.loading = false
                state.purchasedPackage = action.payload
           
            })
            .addCase(packagePurchased.rejected, (state, action) => {
                state.loading = false
            }).addCase(packageFinal.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(packageFinal.fulfilled, (state, action) => {
                state.loading = false
                state.purchasedPackage = action.payload
           
            })
            .addCase(packageFinal.rejected, (state, action) => {
                state.loading = false
            }).addCase(getPackagesAll.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(getPackagesAll.fulfilled, (state, action) => {
                state.loading = false
                state.allPackage = action.payload
           
            })
            .addCase(getPackagesAll.rejected, (state, action) => {
                state.loading = false
            }).addCase(downloadInvoice.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(downloadInvoice.fulfilled, (state, action) => {
                state.loading = false
                
           
            })
            .addCase(downloadInvoice.rejected, (state, action) => {
                state.loading = false
            }).addCase(getPlan.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(getPlan.fulfilled, (state, action) => {
                state.loading = false
                state.monthlyPlan=action.payload
                
           
            })
            .addCase(getPlan.rejected, (state, action) => {
                state.loading = false
            }).addCase(stripePayment.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(stripePayment.fulfilled, (state, action) => {
                state.loading = false
                // state.monthlyPlan=action.payload
                
           
            })
            .addCase(stripePayment.rejected, (state, action) => {
                state.loading = false
            }).addCase(updatePackagepurchased.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(updatePackagepurchased.fulfilled, (state, action) => {
                state.loading = false
                state.updatePackge=action.payload
                
           
            })
            .addCase(updatePackagepurchased.rejected, (state, action) => {
                state.loading = false
            }).addCase(accountStatus.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(accountStatus.fulfilled, (state, action) => {
                state.loading = false
                state.accountStatusverify=action.payload
                
           
            })
            .addCase(accountStatus.rejected, (state, action) => {
                state.loading = false
            }).addCase(getAmountBreakdown.pending, (state, action) => {
                state.loading = true
                state.error = null
            })
            .addCase(getAmountBreakdown.fulfilled, (state, action) => {
                state.loading = false
                state.amoutBreakDown=action.payload
                
           
            })
            .addCase(getAmountBreakdown.rejected, (state, action) => {
                state.loading = false
            })
      
      
    },
})

export default accountBillingSlice.reducer;

