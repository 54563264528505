import { useState, useEffect } from 'react';
import { CustomButton, InputField, Label, Checkbox, Switch } from '../form';
import { attachment, sms, email, users, interviewbilling, JobActive } from '../../helper/Icons/Icon';
import { Row, Col } from 'react-bootstrap';
import UpgradePlan from './UpgradePlan';
import PlanOverview from "./PlanOverview";
import { accountUsageInfo } from '../../store/actions/accountBillingAction';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Spinner from '../common/Spinner';
import { Link } from 'react-router-dom';
import * as moment from 'moment'

const Plan = ({ Upgrade, accountInfo, loading, accountStatusverify }) => {
    const formatCustomDate = (date) => {
        const months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "agu", "sep", "oct", "nov", "dec"];
        const momentDate = moment(date);
        const month = months[momentDate.month()];
        const day = momentDate.date();
        const year = momentDate.year();
        return `${month},${day},${year}`;
    };
    return (
        <>





            <Col md={12} className='bg-white radius-7 pad-20'>
                {loading && <Spinner />}
                <Col md={12} className="d-flex justify-content-between pad-0 mb-10" >
                    {!accountStatusverify.is_trial ? <h4>{accountStatusverify?.account?.accountName} - {accountStatusverify?.package?.packageName}</h4> : <h4>{accountStatusverify?.account?.accountName} - Free Trial</h4>}
                    <CustomButton text="Upgrade Plan " onClick={Upgrade} className="custom-btn-primary" />
                </Col>
                <div className="billing-wrap-p billing-plan-card ">
                    <p >
                        Your Free trial of Growth plan ends in {accountStatusverify?.trial_days_remaining} days. You currently have 1 <Link to="/users">user</Link> on your account who have received a total of 2 responses.

                    </p>


                    <p style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center"

                    }} >

                        <label style={{ fontWeight: "600", fontSize: "13px", marginRight: "5px" }}>Period : </label> <span style={{ fontSize: "16px", letterSpacing: "1px", fontWeight: "500" }}>{accountStatusverify.is_trial ? formatCustomDate(accountStatusverify?.trial_expiry) : formatCustomDate(accountStatusverify?.package_expiry)}</span>
                    </p>
                    <div className='d-flex billing-Content'>
                        <div className='title'>
                            <h5>{interviewbilling}<span className='title-name'>Interviews:</span> <span>{accountInfo?.interviews}</span></h5>
                        </div>
                        <div className='billing-box'>
                            <span>Allowed Interviews: {accountInfo?.limit_of_interviews == 0 ? "Unlimited interviews" : accountInfo?.limit_of_interviews} </span>
                        </div>
                    </div>
                    <div className='d-flex billing-Content'>
                        <div className='title'>
                            <h5>{JobActive}<span className='title-name'>Jobs:</span> <span>{accountInfo?.jobs}</span></h5>
                        </div>
                        <div className='billing-box'>
                            <span>Allowed Jobs: {accountInfo?.limit_of_jobs} </span>
                        </div>
                    </div>

                    <div className='d-flex billing-Content'>
                        <div className='title'>
                            <h5>{users}<span className='title-name'>Users:</span><span> {accountInfo?.users}</span></h5>
                        </div>
                        <div className='billing-box'>
                            <span >Allowed Users: {accountInfo?.limit_of_users} </span>
                        </div>
                    </div>
                    <div className='d-flex billing-Content'>
                        <div className='title'>
                            <h5>{email}<span className='title-name'>Email Credits:</span><span>{accountInfo?.email_messages}</span></h5>
                        </div>
                        <div className='billing-box'>
                            <span>Allowed Credits:{accountInfo?.limit_of_emails}</span>
                        </div>
                    </div>
                    <div className='d-flex billing-Content'>
                        <div className='title'>
                            <h5>{sms}<span className='title-name'>SMS Credits:</span><span>{accountInfo?.sms_messages}</span></h5>
                        </div>
                        <div className='billing-box'>
                            <span>Allowed Credits: {accountInfo?.limit_of_sms} </span>
                        </div>
                    </div>
                </div>
            </Col>
        </>
    )
}
export default Plan;