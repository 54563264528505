
import { Col, Row } from "react-bootstrap";
import { CustomButton } from "../form/index";
import JobApplied from "./JobApplied";
import users from '../../assets/images/users.png';
import { CandidatesICon, Jobs } from "../../helper/Icons/Icon";

const Detail = ({ detaildata }) => {

  return (<>

    <Row className="row-pad-0 custom-row-pad-0">
      <Col md={1} className="custom-md-1  mt-15">
        <div className="profile">
          <span>{CandidatesICon}</span>
        </div>
      </Col>
      <Col md={11} className="content pad-0 custom-md-11 mt-10">


        <h5 className="min-sub-heading mt-15">
          <label>{detaildata.fname + "  " + detaildata.lname}  </label>
        </h5>


        <div className="flex-Xcenter">
          {detaildata?.email && (
            <div className="list-title">
              <label className="title-label">Email:</label>
              <span className="view-text">{detaildata.email}</span>
            </div>
          )}

          <div className="list-title">
            <label className="title-label">jobs Applied:</label>
            <span className="view-text">{detaildata.applied}</span>
          </div>

          {detaildata?.city && (
            <div className="list-title">
              <label className="title-label">City:</label>
              <span className="view-text">{detaildata.city}</span>
            </div>
          )}

          {detaildata?.address && (
            <div className="list-title">
              <label>Address:</label>
              <span className="view-text">{detaildata.address}</span>
            </div>
          )}

          {detaildata?.phone && (
            <div className="list-title">
              <label className="title-label">Phone Number:</label>
              <span className="view-text">{detaildata.phone}</span>
            </div>
          )}

          {detaildata?.country_name && (
            <div className="list-title">
              <label className="title-label">Country:</label>
              <span className="view-text">{detaildata.country_name}</span>
            </div>
          )}

          {detaildata?.resume && (
            <div className="list-title">
              <label className="title-label">Resume:</label>
              <span className="view-text">
                <a href={detaildata.resume}> Preview | </a>
              </span>
              <span className="view-text">
                <a href={detaildata.resume} target="_blank"> Download </a>
              </span>
            </div>
          )}
        </div>
        {detaildata?.skills && (
          <div className="list-location mt-10 mb-10">
            <label className="title-label">Skills:</label>
            <CustomButton className="btn-status skill-tags" text={detaildata?.skills} />

          </div>
        )}

        {detaildata?.profile_summary && (
          <div className="list-description">
            <label className="title-label">Profile Summary:</label>

            <p class="view-text bg-white pad-10 radius-5">{detaildata.profile_summary}</p>

          </div>
        )}

      </Col>

    </Row>
    <Row className="row-pad-0  y-center mt-10 border-x">
      <Col md={1} className="custom-md-1 mt-1 mt-10">
        <div className="profile">
          <span>{Jobs}</span>
        </div>
      </Col>
      <Col md={11} className="pad-0 mt-1 custom-md-11 mt-10">
        <h5 className="min-sub-heading">
          <label>Jobs Applied</label></h5>
      </Col>
    </Row>


    <JobApplied candidateId={detaildata?.id} />
  </>)
}

export default Detail;