import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import { hands } from "../../helper/Icons/Icon";
import InterviewMedia from "./InterviewMedia";
import { CustomButton } from "../form";
import { interviewtemplatetokent } from "../../store/actions/InterviewproceedAction";
import Spinner from "../common/Spinner";
import { useNavigate, useLocation, useParams } from "react-router-dom";

const ExitMessageInterview = () => {
    const { token } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showerror, setErrors] = useState(null)

    const { interviewtemplatetDetail, loading, error } = useSelector((state) => state.Interviewproceed);
    const data = interviewtemplatetDetail;
    console.log(data)
    useEffect(() => {
        if (token) {
            dispatch(interviewtemplatetokent(token)).then((res) => {
                if (res.payload.error) {
                    setErrors(res.payload.error)
                }

            });
        }
    }, [dispatch, token]);

    const InterviewProceed = () => {
        navigate(`/meeting-request/${token}`);
        // navigate(`/meeting-request`, {
        //     state: { token: location.state?.token }
        // });
    };


    return (
        <>
            {!data && <Spinner />}
            <Col md={12} className="pad-0 startInterview-content">
                <Container fluid className="pad-20">
                    <Row className="row-pad-0 bg-white radius-7 pad-10">
                        <Col md={12} className="mt-10 d-flex justify-content-between">
                            <div className="system-card">
                                <div className="syetem-icon">
                                    {hands}
                                </div>
                                <div className="system-msg">
                                    <small className="sub-heading">{data?.exit_message}</small>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="row-pad-0 bg-white radius-7 pad-10 mt-10 d-flex align-items-center justify-content-center">
                        <div className="bg-ligte-gray radius-7 mt-20 mb-20 media-box-wrap  ">
                            <div className="media-box  mt-10">
                                <InterviewMedia media={data?.exit_media} />
                            </div>
                        </div>
                    </Row>
                    <Col md={12} className="text-end mt-20 mb-10">
                        <CustomButton className="custom-btn-primary" text="Proceed" onClick={InterviewProceed} />
                    </Col>
                </Container>
            </Col>
        </>
    );
}

export default ExitMessageInterview;
