import React, { useState, useEffect } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { Document, Page, pdfjs } from 'react-pdf';
import mammoth from 'mammoth';
import { CustomButton } from '../form';
import { crossicon } from '../../helper/Icons/Icon';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ViewerFile = ({ document, handleClose }) => {
  const getFileExtension = (url) => {
    const splitUrl = url.split('?')[0].split('.');
    return splitUrl.length > 1 ? splitUrl.pop().toLowerCase() : '';
  };

  const getFileType = (fileExtension) => {
    switch (fileExtension) {
      case 'pdf': return 'pdf';
      case 'docx': return 'docx';
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif': return 'img';
      case 'txt': return 'txt';
      default: return 'unknown';
    }
  };

  const fileExtension = getFileExtension(document);
  const fileType = getFileType(fileExtension);

  const [docxContent, setDocxContent] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [fileData, setFileData] = useState(null);

  useEffect(() => {
    const fetchFile = async () => {
      try {
        const response = await fetch(document);
        if (!response.ok) {
          throw new Error(`Failed to fetch document: ${response.statusText}`);
        }

        if (fileType === 'pdf') {
          const blob = await response.blob();
          setFileData(URL.createObjectURL(blob));
        } else if (fileType === 'docx') {
          const arrayBuffer = await response.arrayBuffer();
          const result = await mammoth.convertToHtml({ arrayBuffer });
          setDocxContent(result.value);
        }
      } catch (error) {
        console.error('Error loading file:', error);
      }
    };

    fetchFile();
  }, [document, fileType]);

  const renderFileViewer = () => {
    switch (fileType) {
      case 'pdf':
        return fileData ? (
          <Document
            file={fileData}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        ) : (
          <p>Loading PDF...</p>
        );
      case 'docx':
        return docxContent ? (
          <div dangerouslySetInnerHTML={{ __html: docxContent }} />
        ) : (
          <p>Loading DOCX content...</p>
        );
      case 'img':
        return <img src={document} alt="Preview" style={{ width: '100%' }} />;
      case 'txt':
        return (
          <iframe
            src={document}
            title="Text file preview"
            style={{ width: '100%', height: '100%' }}
          />
        );
      default:
        return <div>Unsupported file type: {fileExtension}</div>;
    }
  };

  return (
    <Offcanvas
      show={true}
      onHide={handleClose}
      placement="end"
      className="bg-light-gray custom-offcanvas"
      style={{ width: '60%' }}
    >
      <Offcanvas.Header className="model-header bg-white">
        <Offcanvas.Title>Document Preview</Offcanvas.Title>
        <div className="text-end">
          <CustomButton
            onClick={handleClose}
            className="text-end close-btn"
            text={crossicon}
          />
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body className="mt-10">
        {renderFileViewer()}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ViewerFile;
