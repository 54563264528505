import { Col, Row, Tab, Nav } from "react-bootstrap";
import Spinner from "../components/common/Spinner";
import React, { useEffect, useState } from 'react';
import FileUploadViewInternal from "../components/common/FileUploadViewInternal";
import StarRating from 'react-rating-stars-component';

import { useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getInterviewEvaluation, EvaluationFinalRemarks } from "../store/actions/interviewEvaluatorAction";
import { Participants, ParticipantsTime, ParticipantsDate } from '../helper/Icons/Icon';
import Swal from 'sweetalert2';
import { Checkbox, CustomButton, Label, Textarea } from "../components/form";
import Message from "../components/common/Message";
import EvaluateComment from "./EvaluateComment";
import HeaderCompanName from "../components/company/HeaderCompanName";

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 2000
    });
}

const EvaluateCandidateLiveInterview = () => {
    const [answerId, setAnswerId] = useState(0);


    return (
        <>
            <Col className="pad-0">


                <Col className="pad-20   y-scroll" md={12} sm={12} style={{ backgroundColor: "#F5F5F5 " }} >
                    <Row className="row-pad-0    pad-10">  <h6 className='mt-1 mb-10 mt-10 pad-0 min-sub-heading'><label>Evaluate Candidate(s)</label></h6></Row>


                    <Row className="row-pad-0    recordedinterview-wrap evaluateCandidate-wrap pad-10">


                        <Col md={12} sm={12} className="pad-10 mb-20    bg-white border-radius-5 ">
                            <Row className='row-pad-0 evaluate-live-wrap '>
                                <h6 className="min-sub-heading  mt-10 mb-20"><span>Interview Recording</span></h6>
                                <Col md={12} >
                                    <div className='Response-div  '>
                                        <span>{Participants}</span>
                                        <b>Participants: </b>
                                        <span>Sam R. , Anthony Russell, Sam W. , Marie C.</span>
                                    </div>
                                </Col>


                                <Col md={12} className="mt-10">
                                    <div className='Response-div  '>
                                        <div>
                                            <span>{ParticipantsDate}</span>

                                            <b>Date & Time: </b>
                                            <span> 23-12-2023 22:00</span>

                                        </div>
                                        <div>
                                            <span>{ParticipantsTime}</span>
                                            <b>  Duration: </b>
                                            <span>    1 Hour 5 mins</span>

                                        </div> </div>

                                </Col>
                                <Col md={6} sm={12} className="    ">
                                    <div className=' mt-20 Response-wrap  pad-10' style={{ height: "284px" }}>
                                        <video width="100%" height="100%" controls>
                                            <source src="" type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>

                                </Col>
                                <Col md={6} sm={12} className="  ">

                                    <Row className='feedback-wrap mt-10 mb-10 row-pad-0 pad-0'>
                                        <Col md={6} sm={12}>
                                            <Label text="Internal Comments:" />
                                            <Textarea
                                                style={{ maxHeight: '100px', height: "100px" }}
                                                as="textarea"
                                                readOnly
                                                className="feedback"

                                            />
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <Label text="Internal Rating:" />
                                            <div className='feedback pad-10 d-flex justify-content-center align-items-center' style={{ maxHeight: '100px', height: "100px" }}>
                                                <StarRating

                                                    count={5}
                                                    size={20}
                                                    name="evaluator_rating"

                                                    activeColor="#FFD700"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='feedback-wrap mb-20 row-pad-0 pad-0 mt-30'>
                                        <Col md={6} sm={12} >
                                            <Label text="Enter Comments:" />
                                            <Textarea

                                                as="textarea"
                                                rows={3}
                                                style={{ maxHeight: '100px', height: "100px" }}
                                                name='evaluator_remarks'
                                                className="feedback"
                                                placeholder=""

                                            />

                                        </Col>
                                        <Col md={6} sm={12} >
                                            <Label text="Rate Response:" />
                                            <div className='feedback pad-10 d-flex justify-content-center align-items-center' style={{ maxHeight: '100px', height: "100px" }}>
                                                <StarRating

                                                    count={5}
                                                    size={20}
                                                    name="evaluator_rating"

                                                    activeColor="#FFD700"
                                                />
                                            </div>

                                        </Col>
                                    </Row>


                                </Col>
                            </Row>
                        </Col>


                    </Row >
                    {/* {Evaluation?.show_feedback && <Row className="row-pad-0 bg-white border-radius-5 custom-width-note pad-10">
                        <Col md={12} sm={6}>


                            <h5 className="min-sub-heading"><label>{Evaluation?.evaluator_name} Note</label></h5>
                            {Evaluation?.message && <p className="ml-10">{Evaluation?.message}</p>}
                        </Col>
                    </Row>
                    } */}
                    <Col md={12} className="text-end mt-20 mb-20">
                        <CustomButton text="Cancel" className="custom-btn-outline mr-10" />
                        <CustomButton text="Submit Evaluation" className="custom-btn-primary" />
                    </Col>


                </Col >

            </Col >
        </>
    );
}

export default EvaluateCandidateLiveInterview;