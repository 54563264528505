import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import Calendar from 'react-calendar';
import Swal from 'sweetalert2';
import { useLocation, useParams } from 'react-router-dom';
import { callsSchedule, scheduleMetting, UpdateInterviewByToken, updateAvailibilityFlag } from "../../store/actions/InterviewproceedAction";
import { Calendarlogoimg, skiptext } from "../../helper/Icons/Icon";
import { CustomButton } from "../form";
import { useDispatch, useSelector } from "react-redux";
import Spinner from '../common/Spinner';

const dayMap = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

const MeetingRequestInterview = () => {
    const [selectedSlots, setSelectedSlots] = useState([]);
    const [date, setDate] = useState();
    const [showText, setShowText] = useState(false);
    const [availableSlots, setAvailableSlots] = useState([]);
    const [mLoading, setMLoading] = useState(false);
    const dispatch = useDispatch();
    const { meetingData, loading, error } = useSelector((state) => state.Interviewproceed);

    const { token } = useParams();

    const today = new Date();
    const days = meetingData?.future_window_booking * 7
    const maxDate = new Date();
    maxDate.setDate(today.getDate() + days);

    const disableUnavailableDays = ({ date, view }) => {
        const availability = meetingData?.availability;
        if (view === "month") {
            const dayOfWeek = dayMap[date.getDay()];

            let dayAvailability = null;
            if (availability && typeof availability[dayOfWeek] !== 'undefined') {
                dayAvailability = availability[dayOfWeek];
            }

            if (!dayAvailability || dayAvailability.enabled !== 1) {
                return true; // Disable day if not found or not enabled
            }
        }
        return false;
    };

    useEffect(() => {
        if (token) {
            dispatch(scheduleMetting(token));
        }
    }, [dispatch, token]);


    const parseTime = (timeStr) => {
        if (!timeStr) {
            throw new Error("Invalid time string");
        }

        const [time, meridiem] = timeStr.includes('PM') || timeStr.includes('AM') ? timeStr.split(' ') : [timeStr, ''];
        let [hours, minutes = '00'] = time.split(':').map(Number);

        if (meridiem) {
            if (meridiem === 'PM' && hours !== 12) hours += 12;
            if (meridiem === 'AM' && hours === 12) hours = 0;
        } else if (hours === 24) {
            hours = 0;
        }

        const date = new Date();
        date.setHours(hours, minutes, 0, 0);
        return date;
    };


    const formatTime = (date) => {
        if (!(date instanceof Date)) {
            throw new Error("Invalid date object");
        }

        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const meridiem = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'

        return `${hours}:${minutes} ${meridiem}`;
    };

    // Generate time slots between 'from' and 'to' times
    const generateTimeSlots = (from, to) => {
        const slots = [];
        const fromTime = parseTime(from);
        const toTime = parseTime(to);

        let currentTime = new Date(fromTime);

        while (currentTime < toTime) {
            const slotEndTime = new Date(currentTime);
            slotEndTime.setHours(slotEndTime.getHours() + 1);

            if (slotEndTime > toTime) {
                slotEndTime.setTime(toTime.getTime());
            }

            slots.push({
                start: formatTime(currentTime),
                end: formatTime(slotEndTime)
            });

            currentTime = new Date(slotEndTime);
        }

        // Ensure we have a maximum of 8 slots
        const maxSlots = 8;
        return slots.slice(0, maxSlots);
    };

    const handleSlotSelect = (slot) => {
        const updatedSlots = [...selectedSlots];
        const existingIndex = updatedSlots.findIndex(selected => selected.start === slot.start);

        if (existingIndex !== -1) {
            updatedSlots.splice(existingIndex, 1);
        } else if (selectedSlots.length < 3) {
            updatedSlots.push(slot);
        }

        setSelectedSlots(updatedSlots);
    };

    const isSlotSelected = (slot) => {
        return selectedSlots.some(selected => selected.start === slot.start);
    };

    const sweetAlert = (type, title, text) => {
        Swal.fire({
            icon: type,
            title: title,
            text: text,
            showConfirmButton: false,
            timer: 1000
        });
    };

    const handleSchedule = () => {
        if (selectedSlots.length === 0) {
            sweetAlert("warning", "No Slots Selected", "Please select at least one meeting slot before saving.");
            return;
        }
        if (token) {
            const payload = {
                token: token,
                timezone: meetingData?.timezone,
                timeSlots: selectedSlots,
                meeting_schedular_id: meetingData?.meeting_schedular_id
            };
            dispatch(callsSchedule(payload)).then((resp) => {


                if (resp.payload.error) {

                    sweetAlert(
                        "error",
                        "Error!",

                        `${resp.payload.error}!`
                    );
                }
                else {
                    sweetAlert("success", "Success!", "Meeting Saved Successfully!!");
                    setShowText(true);
                }
            }).catch(() => {
                sweetAlert("error", "Error!", "Something went wrong!");
            });
        } else {
            sweetAlert("error", "Error!", "Token not found!");
        }
    };

    const handleDateChange = (selectedDate) => {
        setMLoading(true)
        setTimeout(() => { setMLoading(false) }, 1);
        if (meetingData && meetingData.availability) {
            const dayOfWeek = new Date(selectedDate).toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase();
            const availability = meetingData.availability[dayOfWeek];

            if (availability && availability.enabled) {
                const from = availability.from || '00:00'; // Default to '00:00' if missing
                const to = availability.to || '23:59'; // Default to '23:59' if missing
                const slots = generateTimeSlots(from, to);
                setAvailableSlots(slots);
            } else {
                setAvailableSlots([]);
            }
        }
    };

    const handleSkipMeeting = () => {
        setShowText(true);
        let payload = {
            "availibility_flag": 1,
        }
        dispatch(updateAvailibilityFlag({ token, payload }))
    }

    return (
        meetingData &&
        <Row className="row-pad-0 pad-10">
            {(loading || mLoading) && <Spinner />}
            <h4>Schedule a Follow-up Call</h4>
            <Col md={12} className="bg-white border-radius-5 pad-20">
                <h4 className="heading-4">We’d love to touch-base with you in the coming days!</h4>
                <p>It would be helpful to know your availability for a follow-up conversation in the next few days. Please select at least three available time slots. This section is not mandatory.</p>
                <div className="calender-main">
                    <Col md={6} className="pad-0">
                        <div className="calender-wrap">
                            <div className="calender-heading">
                                <img src={Calendarlogoimg} alt="Calendar Logo" />
                                <span className="span-meeting">Schedule Meeting with</span>
                                <span className="span-name">
                                    {meetingData ? `${meetingData.first_name} ${meetingData.last_name}` : 'Loading...'}
                                </span>
                            </div>
                            <div className='calendar-container'>

                                <Calendar
                                    onChange={handleDateChange}
                                    // value={date}
                                    showNavigation={true}
                                    tileDisabled={disableUnavailableDays}
                                    minDate={today}
                                    maxDate={maxDate}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className="pad-0">
                        <div className="calender-wrap-left-side">
                            <h6>Timezone: {meetingData ? meetingData.timezone : 'Loading...'}</h6>
                            <Row>
                                {availableSlots.map((slot, index) => (
                                    <Col md={12} className="card-time-wrap" key={index}>
                                        <div
                                            className={`card-time ${isSlotSelected(slot) ? 'selected' : ''}`}
                                            onClick={() => handleSlotSelect(slot)}
                                            style={{ backgroundColor: isSlotSelected(slot) ? '#97979f' : '', color: isSlotSelected(slot) ? 'white' : '' }}
                                        >
                                            {slot.start}
                                        </div>
                                        <div className="card-time end">{slot.end}</div>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </Col>
                </div>
                {showText && <div className="skip-text-wrap">
                    <div className="skip-text">
                        <div className="mr-10">{skiptext}</div>
                        You’re all done. Thank you for submitting your video today. Have a great day ahead!
                    </div>
                </div>}
            </Col>
            {!showText && !meetingData?.availibility_flag && <Col md={12} className="pad-0 text-end mt-20 mb-10">
                <CustomButton text="Skip This Step" onClick={handleSkipMeeting} className="mr-10 custom-btn-primary" />
                <CustomButton text="Confirm Availability" onClick={handleSchedule} className="custom-btn-primary" />
            </Col>}
        </Row>
    );
};

export default MeetingRequestInterview;
