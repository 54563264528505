import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Video from "twilio-video";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faMicrophoneSlash, faVideo, faVideoSlash } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Modal } from "react-bootstrap";
import { adduser, leaveMeeting } from "../../helper/Icons/Icon";
import { InputField, Label, CustomButton } from "../form";
import { useDispatch } from "react-redux";
import Swal from 'sweetalert2';
import { addParticipant, CallLliveInterview, closeMeetingRoom } from "../../store/actions/InterviewproceedAction";
import { updateInterveiewTimeine, UpdateInterviewByToken } from "../../store/actions/InterviewproceedAction";
import Spinner from "../common/Spinner";

import HeaderCompanName from "../../components/company/HeaderCompanName"
const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 3000
    });
}

const LiveInterview = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [roomName, setRoomName] = useState(localStorage.getItem('roomNamel') ?? null);
    const [roomId, setRoomID] = useState(null);
    const [formData, setFormData] = useState({
        invite_message: `Hi,\nPlease visit the link to join live video call\n[link]\nRegards,`,
        email: '',
    });
    const [errors, setErrors] = useState({
        invite_message: '',
        email: '',
    });
    const [meetingLeft, setMeetingLeft] = useState(false);
    const [error, setError] = useState('');
    const [audioTrack, setAudioTrack] = useState(null);
    const [isAudioMuted, setIsAudioMuted] = useState(false);
    const [videoTrack, setVideoTrack] = useState(null);
    const [isVideoMuted, setIsVideoMuted] = useState(false);
    const [room, setRoom] = useState(null);
    const [screenTrack, setScreenTrack] = useState(null);
    const [isScreenSharing, setIsScreenSharing] = useState(false);
    const [participants, setParticipants] = useState([]);
    let activeScreenSharingParticipant = null;
    const logEvent = (key, logMessage) => {
        const payload = {
            token: params.token,
            log: {
                key: key,
                log: logMessage,
                date_time: new Date(),
            }
        };
        dispatch(updateInterveiewTimeine(payload));
    };
    const disconnetRomme = (key) => {


        dispatch(closeMeetingRoom(key));
    };
    useEffect(() => {

        const token = localStorage.getItem('roomToken');
        const roomNamel = localStorage.getItem('roomNamel');
        if (token && roomNamel) {
            connectToRoom(token, roomNamel);
        }

        return () => {
            disconnectFromRoom();
        };
    }, [roomName]);

    useEffect(() => {
    }, [participants]);


    // Connect to room
    const connectToRoom = (token, roomNamel) => {

        const { connect, createLocalVideoTrack, createLocalAudioTrack } = Video;
        const connectOptions = { name: roomNamel };
        (token && roomNamel) &&
            connect(token, connectOptions).then(room => {
                setRoom(room);

                const videoChatWindow = document.getElementById('video-chat-window');

                if (videoChatWindow) {
                    createLocalVideoTrack().then(track => {
                        videoChatWindow.appendChild(track.attach());
                        setVideoTrack(track);
                    }).catch(error => console.error('Error attaching local video track:', error));


                    createLocalAudioTrack().then(track => {
                        videoChatWindow.appendChild(track.attach());
                        setAudioTrack(track);
                    }).catch(error => console.error('Error attaching local audio track:', error));

                    // Add already connected participants' tracks when joining the room
                    room.participants.forEach(participant => {
                        setParticipants(prevParticipants => [...prevParticipants, participant]);

                        participant.tracks.forEach(publication => {
                            if (publication.isSubscribed) {
                                const mediaElement = publication.track.attach();
                                videoChatWindow.appendChild(mediaElement);
                            }

                            publication.on("subscribed", track => {
                                const mediaElement = track.attach();
                                videoChatWindow.appendChild(mediaElement);
                            })
                        })
                    });

                    // Handle participant connection
                    room.on('participantConnected', participant => {
                        setParticipants(prevParticipants => [...prevParticipants, participant]);
                        logEvent('participant_join', `${participant.identity} joined the meeting`);

                        participant.tracks.forEach(publication => {
                            if (publication.isSubscribed) {
                                const track = publication.track;
                                videoChatWindow.appendChild(track.attach());
                            }
                        });

                        participant.on('trackSubscribed', track => {
                            videoChatWindow.appendChild(track.attach());
                        });

                        participant.on('trackUnsubscribed', track => {
                            track.detach().forEach(element => element.remove());
                        });


                    });

                    // Handle participant disconnection
                    room.on('participantDisconnected', participant => {
                        setParticipants(prevParticipants =>
                            prevParticipants.filter(p => p !== participant)
                        );
                        logEvent('participant_leave', `${participant.identity} left the meeting`);

                        // Detach all tracks of the disconnected participant
                        participant.tracks.forEach(publication => {
                            if (publication.track) {
                                publication.track.detach().forEach(element => element.remove());
                            }
                        });

                        localStorage.removeItem("roomToken");
                        localStorage.removeItem("roomNamel");
                        // Clean up or disconnect the room if needed
                        disconnetRomme(room);
                    });

                    // Handle room disconnection
                    room.on('disconnected', () => {
                        // logEvent('exited_interview', `Exited interview`);
                        logEvent('participant_leave', `${room.localParticipant.identity} left the meeting`);
                        localStorage.removeItem("roomToken");
                        localStorage.removeItem("roomNamel");

                        room.localParticipant.tracks.forEach(publication => {
                            const attachedElements = publication.track.detach();
                            attachedElements.forEach(element => element.remove());
                        });
                        setRoom(null);
                    });

                } else {
                    setError('Video chat window or participant list window not found.');
                }
            }).catch(error => {
                alert(error.message);
                console.error(`Unable to connect to Room: ${error.message}`);
            });
    };


    // Disconnect from room
    const disconnectFromRoom = () => {
        if (room) {
            room.disconnect();

        }
    };


    const toggleAudioTrack = () => {
        room.localParticipant.audioTracks.forEach((publication) => {
            if (publication.track.isEnabled) {
                setIsAudioMuted(true)
                publication.track.disable();
            } else {
                setIsAudioMuted(false)
                publication.track.enable();
            }
        });
    };

    const toggleVideoTrack = () => {
        room.localParticipant.videoTracks.forEach((publication) => {
            if (publication.track.isEnabled) {
                setIsVideoMuted(true)
                publication.track.disable();
            } else {
                setIsVideoMuted(false)
                publication.track.enable();
            }
        });
    };

    const AVControl = ({ type }) => {
        const icon = type === 'audio'
            ? (isAudioMuted ? faMicrophoneSlash : faMicrophone)  // Update icon based on audio mute status
            : (isVideoMuted ? faVideoSlash : faVideo);  // Update icon based on video mute status

        const toggleTrack = type === 'audio' ? toggleAudioTrack : toggleVideoTrack;  // Toggle audio or video track based on type

        return (
            <div className="avControl">
                <FontAwesomeIcon icon={icon} onClick={toggleTrack} />
            </div>
        );
    };

    const leaveMeetingHandle = () => {
        if (room) {
            room.disconnect();
            setRoom(null);

            if (videoTrack) {
                videoTrack.stop();
                setVideoTrack(null);
            }

            if (audioTrack) {
                audioTrack.stop();
                setAudioTrack(null);
            }

            if (screenTrack) {
                screenTrack.stop();
                setScreenTrack(null);

                const screenVideo = document.getElementById('screen-video');
                if (screenVideo) {
                    screenVideo.remove();
                }
            }

            logEvent('exited_interview', 'Exited interview');

            let payload = {

                "stage_id": 10,
                "end_timestamp": new Date(),
                "interview_type": 2,

            }
            dispatch(UpdateInterviewByToken({ token: params.token, payload })).then((resp) => {
                if (resp.payload.error) {
                    sweetAlert(
                        "error",
                        "Error!",

                        `${resp.payload.error}!`
                    );
                }


            }).catch(() => {
                sweetAlert(
                    "error",
                    "Error!",
                    "Something went wrong!"
                );
            });
            setMeetingLeft(true);
            disconnetRomme(roomName);
        }
    };

    const toggleScreenShare = async () => {
        try {
            const stream = await navigator.mediaDevices.getDisplayMedia({
                video: true
            });

            const screenTrack = stream.getTracks()[0];
            room.localParticipant.publishTrack(screenTrack);

            logEvent('screen_share', "User started sharing screen");

            setScreenTrack(screenTrack);
            setIsScreenSharing(true);

            screenTrack.onended = () => {
                stopScreenShare();
            };

        } catch (error) {
            console.error('Error starting screen share: ', error);
        }
    };

    const stopScreenShare = () => {
        if (screenTrack) {
            room.localParticipant.unpublishTrack(screenTrack);
            screenTrack.stop()

            logEvent('screen_share', "User stopped sharing screen");
            setScreenTrack(null);
            setIsScreenSharing(false);
        }
    };


    const handleModalClose = () => setShowModal(false);

    const handleModalShow = () => setShowModal(true);

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Update the form data state
        setFormData({
            ...formData,
            [name]: value
        });


        const newErrors = { ...errors };


        if (name === 'email') {
            if (value && !/\S+@\S+\.\S+/.test(value)) {
                newErrors[name] = 'Email is invalid';
            } else {
                newErrors[name] = '';
            }
        }


        if (value.trim() === '') {
            newErrors[name] = 'Field is required';
        } else if (name !== 'email') {
            newErrors[name] = '';
        }


        setErrors(newErrors);
    };

    const AddParticipant = () => {
        const { email } = formData;

        // Initialize a new errors object
        const newErrors = {};

        // Check for email presence
        if (!email) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = 'Email is invalid';
        }

        // If there are errors, set them and return early
        if (Object.keys(newErrors).length > 0) {
            setErrors(prevErrors => ({
                ...prevErrors,
                ...newErrors,
            }));
            return; // Stop further execution if validation fails
        }

        // Prepare payload for dispatch
        const payload = {
            "token": params.token,
            "email": email,
            "content": formData.invite_message
        };

        // Dispatch action to add participant
        dispatch(addParticipant(payload))
            .then((res) => {
                if (res.payload.error) {
                    sweetAlert("error", "Error!", `${res.payload.error}!`);
                } else {
                    // Clear errors on successful submission
                    setErrors({});
                    sweetAlert("success", "Success!", res.payload.message);
                    setShowModal(false);
                }
            })
            .catch(() => {
                sweetAlert("error", "Error!", "Something went wrong!");
            });
    };




    return (
        <>
            {!roomName &&
                <Spinner />
            }

            <div
                className="container-liveintervew mt-10"
                style={{ overflowY: meetingLeft ? "none" : "auto" }}
            >

                {meetingLeft ? (
                    <div className="meeting-left">
                        <center style={{ color: "white", }}>You have left Video Call</center>
                    </div>
                ) : (
                    <>
                        {error && (
                            <div className="mb-5 mt-5">
                                <div className="alert alert-danger mt-3">{error}</div>
                            </div>
                        )}
                        <Row className="row-pad-0">
                            <div className="main-video-container">

                                <div id="video-chat-window" className="video-chat-window"></div>
                                {/* {isScreenSharing && screenTrack ? (

                                    <div id="screen-video" className="screen-video"></div>

                                ) : (
                                    <div id="video-chat-window" className="video-chat-window"></div>
                                )} */}

                            </div>
                            {/* <div className="manage-participants" id="window-participants">
                                <h4>Participants{roomName}</h4>
                                <div id="video-chat-window-participants" className="video-chat-window">
                                    {participants.length === 0 ? (
                                        <p>No participants yet</p>
                                    ) : (
                                        <ul>
                                            {participants.map(participant => (
                                                <li key={participant.sid}>{participant.identity}</li>
                                            ))}
                                        </ul>
                                    )}

                                </div>


                            </div> */}
                            <Col md={12} className="handel-setting">
                                <div className="live-mic">
                                    <AVControl type="audio" />
                                </div>
                                <div className="live-video">
                                    <AVControl type="video" />
                                </div>
                                <div className="screenShare-video">
                                    <span className="span-svg" onClick={isScreenSharing ? stopScreenShare : toggleScreenShare}>
                                        {isScreenSharing ? <i className="fa fa-desktop-disabled" aria-hidden="true"></i> : <i className="fa fa-desktop" aria-hidden="true"></i>}
                                    </span>
                                </div>
                                <div className="screenShare-video">
                                    <span className="span-svg" onClick={handleModalShow}>
                                        {adduser}
                                    </span>
                                </div>
                                <div className="leaveMeeting">
                                    <span className="span-svg" onClick={leaveMeetingHandle}>
                                        {leaveMeeting}
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </>
                )}
            </div >
            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Invite User</Modal.Title>
                </Modal.Header>
                <form id="inviteVideoUserFrm">
                    <Modal.Body>
                        <div className="participants-wrap">
                            <Col md={12} className="mt-20 mb-20">
                                <Label text="Email Address" className="labelinput mb-1 d-flex" required="required" />
                                <InputField type="text" id="email" name="email" onChange={handleChange} placeholder="Enter email address" />
                                {errors.email && <span className="text-danger d-flex">{errors.email}</span>}
                            </Col>
                            <Col md={12} className="mt-20 mb-20">
                                <textarea
                                    className="form-control mb-15"
                                    name="invite_message"
                                    id="invite_message"
                                    placeholder="Message goes here"
                                    rows="5"
                                    value={formData.invite_message}
                                    onChange={handleChange}
                                ></textarea>
                            </Col>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <CustomButton text="Send Invite" onClick={AddParticipant} className="custom-btn-primary" />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
};

export default LiveInterview;
