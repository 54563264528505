import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { CustomButton, InputField, Switch } from "../form/index";
import { Interviews, ringcopy, videobox, mcqQuestion, textquestion, rejectecandidate, uploadfile, adduser, crossicon, faplusicon, faminusicon, fileupploed, btnplus, expirtime } from "../../helper/Icons/Icon";
import { Container, Row, Col, Offcanvas } from "react-bootstrap";
import Textarea from "../form/Textarea";
import { interviewForEvaluator } from "../../store/actions/interviewEvaluatorAction";
import Swal from 'sweetalert2';
import Spinner from "../common/Spinner";
import { UplodedFiles } from "../../store/actions/companyActions";

const icons = { '1': videobox, '2': ringcopy, '3': mcqQuestion, '4': textquestion, '5': uploadfile }

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 3000
    });
}

const ShareCandidateLiveInterview = ({ handleClose, jobId }) => {
    const dispatch = useDispatch();
    const [textValue, setTextValue] = useState("");
    const [image, setImage] = useState(false);
    const [showLoading, setLoading] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null);
    const [isChecked1, setIsChecked1] = useState(true);
    const [isChecked2, setIsChecked2] = useState(true);
    const [expireDays, setExpireDays] = useState(5);
    const [inputFields, setInputFields] = useState([{ email: '', name: '', phone: '' }]);
    const [acceptQuestion, setAcceptQuestion] = useState([]);
    const [errors, setErrors] = useState({});

    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
    const phonePattern = /^\+?[0-9]+$/;
    const [showfile, setFile] = useState({
        fileName: "",
        fileData: null,
        extension: ""
    });
    const fileuploed = useRef(null);


    const handleIncrement = () => {
        setExpireDays(prevDays => prevDays + 1);
    };

    const handleDecrement = () => {
        setExpireDays(prevDays => (prevDays > 1 ? prevDays - 1 : 1));
    };

    const handleChange1 = () => {
        setIsChecked1(prev => !prev);
    };

    const handleChange2 = () => {
        setIsChecked2(prev => !prev);
    };

    const handleAddFields = () => {
        setInputFields([...inputFields, { email: '', name: '', phone: '' }]);
    };

    const handleRemoveFields = (index) => {
        setInputFields(prevFields => prevFields.filter((_, i) => i !== index));
    };

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const updatedFields = [...inputFields];
        updatedFields[index][name] = value;

        setInputFields(updatedFields);

        setErrors(prevErrors => ({
            ...prevErrors,
            [index]: {
                ...prevErrors[index],
                [name]: value.trim() ? '' : 'Field is required'
            }
        }));

        if (name === 'email') {
            if (!emailPattern.test(value)) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    [index]: {
                        ...prevErrors[index],
                        [name]: 'Invalid email format'
                    }
                }));
            } else {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    [index]: {
                        ...prevErrors[index],
                        [name]: ''
                    }
                }));
            }
        }

        if (name === 'phone') {
            if (value.trim() && !phonePattern.test(value)) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    [index]: {
                        ...prevErrors[index],
                        [name]: 'Phone number must contain only numbers'
                    }
                }));
            } else {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    [index]: {
                        ...prevErrors[index],
                        [name]: ''
                    }
                }));
            }
        }
    };

    const fileChange = () => {
        if (fileuploed.current) {
            fileuploed.current.click();
        }
    };

    const onFileUpload = (event) => {
        const valueFile = event.target.files[0];

        if (!valueFile) {
            console.error('No file selected');
            return;
        }

        const nameFile = valueFile.name.split('.');
        const extension = nameFile[nameFile.length - 1].toLowerCase();
        const allowedExtensions = ['csv', 'xls', 'xlsx'];

        if (!allowedExtensions.includes(extension)) {
            alert('Invalid file format. Please upload a .csv, .xls, or .xlsx file.');
            return;
        }

        setSelectedFile(valueFile);
        setImage(true);

        setFile({
            fileData: valueFile,
            fileName: nameFile[0],
            extension: extension
        });
    };

    const ClearFile = (e) => {
        e.preventDefault();
        setFile({
            fileName: "",
            fileData: null,
            extension: ""
        });
        setSelectedFile(null);
    };

    useEffect(() => {
        if (image) {
            dispatch(UplodedFiles(selectedFile))
                .then((res) => {
                    if (res.payload.error) {
                        sweetAlert("error", "Error!", `${res.payload.error}!`);
                    } else {
                        setSelectedFile(res.payload.data[0].path);
                        setImage(false);
                    }
                })
                .catch(error => {
                    sweetAlert("error", "Error!", "Something went wrong!");
                });
        }
    }, [image, selectedFile, dispatch]);

    const handleAccepQuestion = (id, checked) => {

        setAcceptQuestion(prevQuestions =>
            prevQuestions.map(item =>
                item.id === id ? { ...item, accept: checked } : item
            )
        );


    };

    const handleTextareaChange = (e) => {
        setTextValue(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();



        const emptyEmailFields = inputFields.some(field => !field.email.trim());
        if (emptyEmailFields) {
            setErrors(prevErrors =>
                inputFields.map((field, index) => ({
                    ...prevErrors[index],
                    email: !field.email.trim() ? 'Email is required' : ''
                }))
            );
            return;
        }

        const invalidEmailOrPhone = inputFields.some(field =>
            !emailPattern.test(field.email.trim()) ||
            (field.phone.trim() && !phonePattern.test(field.phone.trim()))
        );

        if (invalidEmailOrPhone) {
            setErrors(prevErrors =>
                inputFields.map((field, index) => ({
                    ...prevErrors[index],
                    email: !emailPattern.test(field.email.trim()) ? 'Invalid email format' : '',
                    phone: (field.phone.trim() && !phonePattern.test(field.phone.trim())) ? 'Phone number must contain only numbers' : ''
                }))
            );
            return;
        }

        setErrors({});
        setLoading(true);
        const formdata = new FormData();

        formdata.append("job_id", parseInt(jobId));

        formdata.append("expire_after_days", expireDays);
        formdata.append("show_feedback", isChecked2 ? 1 : 0);
        formdata.append("message", textValue);
        formdata.append("sample", selectedFile);


        if (jobId) {
            dispatch(interviewForEvaluator(formdata))
                .then((res) => {
                    if (res.payload.error) {
                        setLoading(false);
                        sweetAlert("error", "Error!", res.payload.error);
                    } else {
                        setLoading(false);
                        handleClose();
                        sweetAlert("success", "Success!", res.payload.message);
                    }
                })
                .catch(error => {
                    setLoading(false);
                    sweetAlert("error", "Error!", "Something went wrong!");
                });
        } else {
            sweetAlert("error", "Error!", "Please Try again!");
        }
    };


    return (
        <Offcanvas
            show={true}
            onHide={handleClose}
            placement="end"
            className="bg-ligte-gray custom-offcanvas"
            style={{ width: "650px" }}
        >

            {showLoading && <Spinner />}
            <Container fluid className="row-pad-0 pad-0 bg-ligte-gray pad-20">
                <Offcanvas.Header className="model-header bg-white ">
                    <Offcanvas.Title>
                        <span className="model-header-svg">{rejectecandidate}Share Candidate’s Live Interview for Evaluation</span></Offcanvas.Title>
                    <div className="text-end"><CustomButton onClick={handleClose} className="text-end close-btn" text={crossicon} /></div>
                </Offcanvas.Header>

                <Offcanvas.Body className="mt-10 wrap InviteCandidate-wrap">
                    <Row className="row-pad-0 InviteCandidatebody bg-white pad-20">
                        {inputFields.map((field, index) => (
                            <Col key={index} md={12} className="d-flex InviteCandidate-addinput mb-10 pad-0">
                                <div>
                                    <InputField
                                        type="email"
                                        className="input"
                                        placeholder="Enter email address"
                                        name="email"
                                        id="email"
                                        value={field.email}
                                        onChange={(e) => handleInputChange(index, e)}
                                    />
                                    {errors[index] && errors[index].email && <span className="text-danger">{errors[index].email}</span>}
                                </div>
                                <div>
                                    <InputField
                                        type="text"
                                        placeholder="Enter name"
                                        name="name"
                                        id="name"
                                        value={field.name}
                                        onChange={(e) => handleInputChange(index, e)}
                                    />
                                </div>
                                <div>
                                    <InputField
                                        type="tel"
                                        placeholder="Enter phone number"
                                        name="phone"
                                        id="phone"
                                        value={field.phone}
                                        onChange={(e) => handleInputChange(index, e)}
                                    />
                                    {errors[index] && errors[index].phone && <span className="text-danger">{errors[index].phone}</span>}
                                </div>

                                <span onClick={() => handleRemoveFields(index)}>{crossicon}</span>
                            </Col>
                        ))}
                        <Col md={12} className="text-end mt-15 mr-15">
                            <CustomButton text="Add More" className="custom-btn-primary" onClick={handleAddFields} />
                        </Col>
                        <Col md={12} className="InviteCandidate-file FileUploadView-wrap mt-10">
                            <input type="file" className="hide" name="fileuploade" accept=".csv, .xls, .xlsx" ref={fileuploed} onChange={onFileUpload} />
                            <div onClick={fileChange}>{fileupploed}</div>
                            <div className="file-wrap mt-10" onClick={fileChange}>
                                <span className="file-name"><b>Upload Emails (Allowed format: .csv, .xls, .xlsx)</b></span>
                                <span>Upload a file containing names, emails and numbers.</span>
                            </div>
                            <span className="action-file">
                                <small className="file-download">
                                    <a href='/template.csv' download >Download Template</a>
                                </small>
                            </span>
                        </Col>
                        {showfile.fileName && (
                            <div className='upload-media-wrap mt-20 mb-20' style={{ width: "fit-content" }}>
                                <div className='upload-media-icon'>
                                    <span>{showfile.extension}</span>
                                </div>
                                <Col md={12}>
                                    <div className="d-flex">
                                        <span>{showfile.fileName}</span>
                                        <span className='clreafile' onClick={ClearFile}>{crossicon}</span>
                                    </div>
                                </Col>
                            </div>
                        )}
                    </Row>

                    <Row className="row-pad-0 bg-white mt-20 share-setting-wrap">
                        <Col md={12} className="pad-0">
                            <h4 className="mb-10">Share Settings</h4>
                            <h5>Main Settings</h5>
                            <Row className="row-pad-0">
                                <Col md={12} className="main-setting-card">
                                    <div className="link mt-10">
                                        <div className="puls-minus">
                                            <span className="mr-10">{expirtime}</span>
                                            <span>Expire Link After</span>
                                            <CustomButton icon={faminusicon} className="btn-default btn-mius" onClick={handleDecrement} />
                                            <InputField type="text" value={expireDays} readOnly />
                                            <CustomButton className="btn-default btn-puls" icon={faplusicon} onClick={handleIncrement} />
                                            <span className="mr-10">Days</span>
                                        </div>
                                        <Switch checked={isChecked1} onChange={handleChange1} />
                                    </div>
                                    <div className="link mt-20 mb-10">
                                        <div>
                                            <span className="mr-10">{btnplus}</span>
                                            <span>Include internal feedback of the candidate</span>
                                        </div>
                                        <Switch checked={isChecked2} onChange={handleChange2} />
                                    </div>
                                </Col>
                                <Col md={12} className="mt-20 pad-0">
                                    <h4>Add a Message</h4>
                                    <Row className="row-pad-0 add-message mt-10">
                                        <div className="pad-20">
                                            <Textarea
                                                as="textarea"
                                                placeholder="Add a personal note for the ones reviewing this candidate"
                                                value={textValue}
                                                onChange={handleTextareaChange}
                                            />
                                        </div>
                                    </Row>
                                </Col>
                            </Row>


                        </Col>
                    </Row>

                    <Col md={12} className="text-end mt-20 mb-20 pad-0">
                        <CustomButton className="mr-10 custom-btn-outline" text="Cancel" onClick={handleClose} />
                        <CustomButton text="Share" className="custom-btn-primary" onClick={handleSubmit} />
                    </Col>
                </Offcanvas.Body>
            </Container>
        </Offcanvas>
    );
};

export default ShareCandidateLiveInterview;
