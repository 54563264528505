import React, { useState } from "react";
import { Container, Row, Col, Form } from 'react-bootstrap';
import { Vidpmlogo } from '../../helper/Icons/Icon';
import { Label, InputField, CustomButton, FeedBack } from "../form/index";
import { useNavigate } from 'react-router-dom';
import { fieldConfigRequestdemo } from './FieldConfig';
import { requestomDemo } from '../../store/actions/InterviewproceedAction';
import { useDispatch } from "react-redux";
import Swal from 'sweetalert2';

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 3000
    });
}

const Requestdemo = () => {
    const dispatch = useDispatch();
    const [message, setMessage] = useState('');
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        company_name: "",
        phone: "",
    });

    const [errors, setErrors] = useState({});

    const phonePattern = /^[0-9\s\-+()]+$/;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const handleChange = (e) => {
        const { name, value } = e.target;
        let newValue = value;


        setFormData(prevState => ({
            ...prevState,
            [name]: newValue
        }));

        // Validate the field
        validateField(name, newValue);
    };

    const validateField = (name, value) => {
        let error = '';

        if (!value.trim()) {
            error = 'Required';
        } else {
            if (name === 'email' && !emailPattern.test(value)) {
                error = 'Invalid email address';
            } else if (name === 'phone' && !phonePattern.test(value)) {
                error = 'Invalid phone number';
            }
        }

        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: error
        }));
    };

    const validateForm = (formData) => {
        const errors = {};

        if (!formData.name.trim()) {
            errors.name = 'Name is required';
        }

        if (!formData.email.trim()) {
            errors.email = 'Work Email is required';
        } else if (!emailPattern.test(formData.email)) {
            errors.email = 'Invalid email address';
        }

        if (!formData.company_name.trim()) {
            errors.company_name = 'Company Name is required';
        }

        if (!formData.phone.trim()) {
            errors.phone = 'Phone Number is required';
        } else if (!phonePattern.test(formData.phone)) {
            errors.phone = 'Invalid phone number';
        }

        return errors;
    };

    const FormRequestdemo = (e) => {
        e.preventDefault();
        const validationErrors = validateForm(formData);
        if (Object.keys(validationErrors).length === 0) {
            dispatch(requestomDemo(formData))
                .then((res) => {
                    if (res.payload.error) {
                        sweetAlert("error", "Error!", `${res.payload.error}!`);
                    } else {
                        setMessage(res.payload.message);
                        sweetAlert("success", "Success!", res.payload.message);
                    }
                })
                .catch(error => {
                    sweetAlert("error", "Error!", "Something went wrong!");
                });
        } else {
            setErrors(validationErrors);
        }
    };

    return (
        <Container fluid className='pad-0'>
            <Row className='row-pad-0'>
                <Col md={6} className='pad-0'>
                    <div className='logo'>{Vidpmlogo}</div>
                    <div className='info-graphics'></div>
                </Col>

                <Col md={6} className='justify-content-center requestdemo-left d-flex'>
                    <Form onSubmit={FormRequestdemo}>
                        <Row className='row-pad-0'>
                            <Col md={12}>
                                <p className='accountSetting-p'>
                                    Recruit Smarter, Not Harder: Start Interviewing in <br />Style!
                                </p>
                                <span className='accountSetting-span'>
                                    Recruit Smarter, Not Harder: Start Interviewing in Style to elevate your hiring game with finesse
                                </span>
                            </Col>
                            {message ? (
                                <div className="message-card">
                                    <div>{message}</div>
                                </div>
                            ) : (<>
                                {fieldConfigRequestdemo.map((field, index) => (
                                    <Col md={6} xs={12} className='mb-10 mt-10' key={index}>
                                        <Label
                                            htmlFor={field.id}
                                            className={field.className}
                                            text={field.text}
                                            required="required"
                                        />
                                        <InputField
                                            type={field.type}
                                            id={field.id}
                                            name={field.id}
                                            placeholder={field.placeholder}
                                            value={formData[field.id]}
                                            onChange={handleChange}
                                        />
                                        {errors[field.id] && (
                                            <FeedBack className="error" error={errors[field.id]} />
                                        )}
                                    </Col>
                                ))}

                                <Col className="mb-10 mt-20">
                                    <CustomButton text="Request a Demo" className="custom-btn-primary" type="submit" />
                                </Col>
                            </>)}
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default Requestdemo;
