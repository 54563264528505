import { Row, Col } from "react-bootstrap";

const HeaderCompanyName = ({ logo, name, bgclr }) => {
  console.log(bgclr, "backcolor header")
  return (
    <Row className={`row-pad-0 pad-5  `} style={{ background: `${bgclr}` }}>
      <Col md={12} className="d-flex  " style={{ boxShadow: `${bgclr} 0 0.2px 5px ` }}>
        <h5 className="d-flex align-items-center ">

          <span style={{ width: "50px", height: "50px", border: "1px solid gainsboro", borderRadius: "50%", display: "inline-block", overflow: "hidden", marginRight: '10px', marginTop: '10px' }}>{logo}</span>
          {name}
        </h5>
      </Col>
    </Row >
  );
}

export default HeaderCompanyName;
