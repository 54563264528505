import { createSlice } from '@reduxjs/toolkit'
import { create, update, getAll, getById, deleteCandidate, getInterviewCountsByCandidateId } from '../actions/candidateActions'

const initialState = {
    loading: false,
    candidates: [],
    candidateDetail: null,
    interviewCount: null,

    error: null,
}

const candidateSlice = createSlice({
    name: 'candidate',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(create.pending, (state, action) => {
                state.loading = true
            })
            .addCase(create.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(create.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(update.pending, (state, action) => {
                state.loading = true
            })
            .addCase(update.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(update.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getAll.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getAll.fulfilled, (state, action) => {
                state.loading = false
                state.candidates = action.payload
            })
            .addCase(getAll.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getById.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getById.fulfilled, (state, action) => {
                state.loading = false
                state.candidateDetail = action.payload
            })
            .addCase(getById.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(deleteCandidate.pending, (state, action) => {
                state.loading = true
            })
            .addCase(deleteCandidate.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(deleteCandidate.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getInterviewCountsByCandidateId.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getInterviewCountsByCandidateId.fulfilled, (state, action) => {
                state.loading = false
                state.interviewCount = action.payload
            })
            .addCase(getInterviewCountsByCandidateId.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
    },
})

export default candidateSlice.reducer