import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Offcanvas, Container, Row, Col } from 'react-bootstrap';
import CriclrDiv from '../../components/common/CriclrDiv';
import ViewerFile from '../common/ViewerFile';
import Spinner from '../common/Spinner';
import { CustomRating } from '../form/index';
import CandidateRespones from '../common/CandidateRespones';
import TimeLine from '../RecordedInterviewDetails/TimeLine';
import { crossicon, acceptcandidate, rejectecandidate, share, downloadarrowblow, Video, HiredICon } from '../../helper/Icons/Icon';
import { CustomButton } from '../form/index';
import aryaimg from '../../assets/images/users.png';
import RatingsTabel from '../RecordedInterviewDetails/RatingsTabel';

import CommentModel from '../common/CommentModel';
import CustomAlert from '../common/CustomAlert';
import ShareCandidate from '../common/ShareCandidate';
import { downloadinterview } from '../../store/actions/interviewActions';
import { getRecordedInterview } from '../../store/actions/interviewActions';
import { commentCreateAccept, commentCreatecandRejected, commentCreateHired } from '../../store/actions/recorderInterviewAction';
import Swal from 'sweetalert2';
const config = require('../../config/config.json');

const sweetAlert = (type, title, text) => {
  Swal.fire({
    icon: type,
    title: title,
    text: text,
    showConfirmButton: false,
    timer: 2500
  });
}

const RecordedInterview = ({ handleClose, id, }) => {
  const [updateList, setUpdateList] = useState(false)
  const [showShareCandidate, setShowShareCandidate] = useState(false);
  const [showApcceptCandidate, setApcceptCandidate] = useState(false);
  const [showRejectCandidate, setRejectCandidate] = useState(false);
  const [showDocViewer, setDocViewer] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [commentData, setCommentData] = useState();

  const [showHiredCandidate, setHiredCandidate] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(getRecordedInterview(id));
    }
  }, [dispatch, updateList]);

  const { recordedInterview, loading, error } = useSelector((state) => state.interview);
  const candidate = recordedInterview?.interview_candidate;
  const jobId = recordedInterview?.job_id;
  const interviewId = recordedInterview?.interview_id;
  const candidateId = candidate?.candidate_id;
  const candidateRespones = recordedInterview?.interview_template_questions;

  const handelComment = (e) => {
    const { name, value } = e.target;
    setCommentData({
      ...commentData,
      [name]: value
    });
  }

  const handelAccept = () => {
    const payload = commentData;

    if (!candidateId || !interviewId) {
      console.error('Candidate ID or Interview ID is undefined.');
      return;
    }

    dispatch(commentCreateAccept({ candidateId, interviewId, payload }))
      .then(() => {
        handelCloseModel('apcceptCandidate');
        dispatch(getRecordedInterview(id));
        return sweetAlert('success', 'Success!', 'Candidate Passed successfully!');

      })
      .catch((error) => {
        console.error('Error:', error);
        return sweetAlert('error', 'Error!', 'Something went wrong!');
      });
  };

  const handelRejected = () => {
    const payload = commentData;

    if (!candidateId || !interviewId) {
      console.error('Candidate ID or Interview ID is undefined.');
      return;
    }

    dispatch(commentCreatecandRejected({ candidateId, interviewId, payload }))
      .then(() => {
        handelCloseModel('rejectCandidate');
        dispatch(getRecordedInterview(id));
        return sweetAlert('success', 'Success!', 'Candidate Rejected successfully!');

      })
      .catch((error) => {
        console.error('Error:', error);
        return sweetAlert('error', 'Error!', 'Something went wrong!');
      });
  };
  const handelHired = () => {
    const payload = commentData;

    if (!candidateId || !interviewId) {
      console.error('Candidate ID or Interview ID is undefined.');
      return;
    }

    dispatch(commentCreateHired({ candidateId, interviewId, payload }))
      .then(() => {
        handelCloseModel('Hired');
        dispatch(getRecordedInterview(id));
        return sweetAlert('success', 'Success!', 'Candidate Hired successfully!');

      })
      .catch((error) => {
        console.error('Error:', error);
        return sweetAlert('error', 'Error!', 'Something went wrong!');
      });

  }
  // const parseStyle = (styleString) => {
  //   const styleObj = {};
  //   styleString.split(';').forEach(style => {
  //     const [key, value] = style.split(':');
  //     if (key && value) {
  //       styleObj[key.trim()] = value.trim();
  //     }
  //   });
  //   return styleObj;
  // }

  const handelModel = (props) => {
    if (props === "apcceptCandidate") {
      setApcceptCandidate(true);
    }
    if (props === "rejectCandidate") {
      setRejectCandidate(true);
    }
    if (props === "Hired") {
      setHiredCandidate(true)
    }
    if (props === "DocViewer") {
      setDocViewer(true);
    }
    if (props === "Evaluation") {
      setShowShareCandidate(true);
    }
    if (props === "download") {
      InterviewsDownload(interviewId)
    }
  }

  const handelCloseModel = (props) => {
    if (props === "apcceptCandidate") {
      setApcceptCandidate(false);
    }
    if (props === "rejectCandidate") {
      setRejectCandidate(false);
    }
    if (props === "Hired") {
      setHiredCandidate(false)
    }
    if (props === "Evaluation") {
      setShowShareCandidate(false);
    }
    if (props === "alert") {
      setShowAlert(false);
    }
    if (props === "DocViewer") {
      setDocViewer(false);
    }
  }
  const InterviewsDownload = (interviewId) => {

    if (interviewId) {
      dispatch(downloadinterview(interviewId))
        .then((res) => {
          console.log(res, "res")

          if (res.error)
            Swal.fire({
              icon: 'error',
              title: 'Download Failed',
              text: `${res.error.message}`,
              showConfirmButton: false,
              timer: 2500
            });
          else {
            Swal.fire({
              icon: 'success',
              title: 'Video interview',
              text: `Video interview successfully downloaded !`,
              showConfirmButton: false,
              timer: 2500
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: 'error',
            title: 'Download Failed',
            text: `Failed to download invoice: ${error.message}`,
          });
        });

    }
    else {

      Swal.fire({
        icon: 'error',
        title: 'errro',

        showConfirmButton: false,
        timer: 1000
      });
    }

  }

  return (
    <Offcanvas show={true} onHide={handleClose} placement="end" className="bg-ligte-gray recordedinterview-wrap custom-offcanvas" style={{ width: "90%" }}>
      <Container fluid className="detailpage-wrap pad-0">
        <Offcanvas.Header className="model-header bg-white ">
          <Offcanvas.Title>
            <span className="model-header-svg">{Video}Recorded Interview Details g</span></Offcanvas.Title>
          <div className="text-end"><CustomButton onClick={handleClose} className="text-end close-btn " text={crossicon} /></div>
        </Offcanvas.Header>
        {loading && <Spinner />}

        <Col md={12} className="pad-0 recordedinterview-body">
          <Row className="row-pad-0">
            <Col md={8} className="">
              <Row className="row-pad-0 detail-interviewer bg-white">
                <div className="flex-space-between">
                  <div className="d-flex">
                    <h5 className="name">
                      <label className="min-sub-heading">Candidate Details</label> <small>/  {recordedInterview?.job_title}</small>
                    </h5>
                    {recordedInterview?.interview_stage && (
                      <CustomButton
                        className={`${recordedInterview.interview_stage.stage_name && recordedInterview.interview_stage.stage_name.replace(/\s+/g, '-').toLowerCase()} status`}

                        text={recordedInterview.interview_stage.stage_name}
                      />
                    )}
                  </div>
                  <div>
                    <CustomRating />
                  </div>
                </div>
                <Row className="row-pad-0 custom-row-pad-0">
                  <Col md={1} className="custom-md-1 pad-0 mt-10">
                    <div className="profile mr-15">
                      <img src={aryaimg} alt="user profile" />
                    </div>
                  </Col>
                  <Col md={11} className="content pad-0 custom-md-11 mt-10">
                    <div className="flex-space-between">
                      <div className="d-flex">
                        <h5 className="name">
                          <span className="title-label">{candidate?.first_name} {candidate?.last_name}</span>
                        </h5>
                      </div>
                    </div>
                    <div className="flex-Xcenter">
                      {candidate?.email_address && (
                        <div className="list-title">
                          <label className="title-label">Email:</label>
                          <span className="span-data">{candidate.email_address}</span>
                        </div>
                      )}

                      {candidate?.city && (
                        <div className="list-title">
                          <label className="title-label">City:</label>
                          <span className="span-data">{candidate.city}</span>
                        </div>
                      )}

                      {candidate?.address && (
                        <div className="list-title">
                          <label>Address:</label>
                          <span className="span-data">{candidate.address}</span>
                        </div>
                      )}

                      {candidate?.phone && (
                        <div className="list-title">
                          <label className="title-label">Phone Number:</label>
                          <span className="span-data">{candidate.phone}</span>
                        </div>
                      )}

                      {candidate?.country_name && (
                        <div className="list-title">
                          <label className="title-label">Country:</label>
                          <span className="span-data">{candidate.country_name}</span>
                        </div>
                      )}

                      {candidate?.resume && (
                        <div className="list-title">
                          <label className="title-label">Resume:</label>
                          <span className="span-data">
                            <a href="#" onClick={() => handelModel("DocViewer")}> Preview | </a>
                          </span>
                          <span className="span-data">
                            <a href={`${candidate.resume}`} download target="_blank"> Download </a>
                          </span>
                        </div>
                      )}
                    </div>
                    {candidate?.skills && (
                      <div className="list-location mt-10 mb-10">
                        <label className="title-label">Skills:</label>
                        <CustomButton className="btn-status skill-tags" text={candidate?.skills} />
                      </div>
                    )}

                    {candidate?.profile_summary && (
                      <div className="list-description">
                        <label className="title-label">Profile Summary:</label>
                        <div className="mt-1">
                          <p className="span-data">{candidate.profile_summary}</p>
                        </div>
                      </div>
                    )}

                  </Col>
                </Row>
              </Row>
              <Row className="row-pad-0 pad-0  interviewer-responses bg-white mt-20 ">
                {candidateRespones && <CandidateRespones responses={candidateRespones} updateList={updateList} setUpdateList={setUpdateList} />}
              </Row>

            </Col>
            <Col md={4}>
              <Row className="row-pad-0 timeline-wrap">
                <div className="d-flex">
                  <CriclrDiv icon={share} onclick={() => handelModel("Evaluation")} tooltiptext="Share Candidate for Evaluation" clrName="timeline-criclr border-header cursor" />
                  <CriclrDiv icon={acceptcandidate} onclick={() => handelModel("apcceptCandidate")} tooltiptext="Accept Candidate" clrName="timeline-criclr accept-candidate-svg border-header cursor" />
                  <CriclrDiv icon={downloadarrowblow} clrName="timeline-criclr border-header cursor" onclick={() => handelModel("download")} tooltiptext="Download" />
                  <CriclrDiv icon={rejectecandidate} onclick={() => handelModel("rejectCandidate")} tooltiptext="Reject Candidate" clrName="timeline-criclr border-header cursor" />
                  <CriclrDiv icon={HiredICon} onclick={() => handelModel("Hired")} tooltiptext="Hired" clrName="timeline-criclr border-header cursor" />


                </div>
                {interviewId && <TimeLine id={interviewId} />
                }
              </Row>
            </Col>
            <Row className="row-pad-0 rating-tabel-wrap mt-20 ">
              {interviewId &&
                <>

                  <RatingsTabel id={interviewId} />


                </>}
            </Row>
          </Row>



        </Col>

      </Container>


      {showRejectCandidate && <CommentModel icon={rejectecandidate} handelComment={handelComment} headertext="Reject Candidate" handelSubmit={handelRejected} handleClose={() => handelCloseModel("rejectCandidate")} />}
      {showApcceptCandidate && <CommentModel icon={acceptcandidate} headertext="Accept Candidate" handelComment={handelComment} handelSubmit={handelAccept} handleClose={() => handelCloseModel("apcceptCandidate")} />}
      {showAlert && <CustomAlert handleClose={() => handelCloseModel("alert")} />}
      {showShareCandidate && <ShareCandidate jobId={jobId} handleClose={() => handelCloseModel("Evaluation")} />}
      {showDocViewer && <ViewerFile handleClose={() => handelCloseModel("DocViewer")} document={`${config.API_BASE_URL_PUBLIC}/${candidate.resume}`} />}
      {showHiredCandidate && <CommentModel icon={HiredICon} headertext="Hired" handelComment={handelComment} handelSubmit={handelHired} handleClose={() => handelCloseModel("Hired")} />}

    </Offcanvas>
  );
}

export default RecordedInterview;
