import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { attachment, sms, email, users, interviewbilling, JobActive } from '../../helper/Icons/Icon';
import { CustomButton, InputField, Label, Checkbox, Switch } from '../form';
import { Row, Col, Table, ProgressBar, Nav, Tab } from 'react-bootstrap';
import UpdateCardInformation from './UpdateCardInformation';
import CardInfromation from './CardInfromation';
import AddPackage from './AddPackage';
import Plan from './Plan';
import BillingList from './BillingList';
import { accountStatus, accountUsageInfo, updatePackagepurchased } from '../../store/actions/accountBillingAction';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import Spinner from '../common/Spinner';
import AmountBreakdown from './AmountBreakdown';
import * as moment from 'moment';

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 5000
    });
};

const PlanOverview = ({ Upgrade }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [confirmPayment, setConfirmPayment] = useState(false);

    const [showAddPackage, setShowAddPackage] = useState(false);

    const { accountInfo } = useSelector((state) => state.accountBilling);
    const { accountStatusverify, loading, error } = useSelector((state) => state.accountBilling) || {};
    const formatCustomDate = (date) => {
        const months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "agu", "sep", "oct", "nov", "dec"];
        const momentDate = moment(date);
        const month = months[momentDate.month()];
        const day = momentDate.date();
        const year = momentDate.year();
        return `${month},${day},${year}`;
    };
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const sessionId = searchParams.get('checkout_session_id');
        if (sessionId) {
            const payload = {
                sessionId: sessionId
            };

            dispatch(updatePackagepurchased(payload)).then((res) => {
                setConfirmPayment(true);
                if (res.payload.error) {
                    sweetAlert(
                        "error",
                        "Error!",
                        `${res.payload.error}!`
                    );
                } else {
                    setConfirmPayment(false);
                    navigate('/billing/plan-overview');
                    sweetAlert(
                        "success",
                        "Success!",
                        res.payload.message
                    );
                }
            });
        }
    }, [dispatch, location.search, navigate]);

    useEffect(() => {
        dispatch(accountStatus());
    }, [dispatch, confirmPayment]);

    useEffect(() => {
        dispatch(accountUsageInfo());
    }, [dispatch]);



    const addPackage = () => {
        setShowAddPackage(!showAddPackage);
    };

    const percentageEmail = (accountInfo?.email_messages / accountInfo?.limit_of_emails) * 100;
    const percentageSMS = (accountInfo?.sms_messages / accountInfo?.limit_of_sms) * 100;

    return (
        <>
            {confirmPayment && <Spinner />}
            {loading && <Spinner />}
            <Col className="row-pad-0 wrap-billing-plan y-scroll">
                {accountStatusverify &&
                    <Plan Upgrade={Upgrade} accountInfo={accountInfo} loading={loading} accountStatusverify={accountStatusverify} />}
                {accountStatusverify && !accountStatusverify?.is_trial && (
                    <Col md={12} className="bg-white radius-7 mt-20 pad-20 amount-breakdown-wrap">
                        <h5 className='heading '>Amount Breakdown</h5>
                        {accountStatusverify?.account_id &&
                            <AmountBreakdown accountID={accountStatusverify?.account_id} addPackage={addPackage} />}
                    </Col>
                )}
                <Col md={12} className="bg-white radius-7 mt-20 pad-20 credit-usage-analytic-wrap">
                    <Row className='row-pad-0 mt-10 mb-10'>
                        <Col md={6} className=' mb-10 pad-0'><h5 className='heading'>Credit Usage Analytics</h5></Col>
                        <Col md={6} className='text-end  mb-10 '>
                            <p className=' mr-10' style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                <label style={{ fontWeight: "600", fontSize: "13px", marginRight: "5px" }}>Credits Expiry Date:</label>
                                <span style={{ fontSize: "16px", letterSpacing: "1px", fontWeight: "500" }}>
                                    {accountStatusverify?.is_trial
                                        ? formatCustomDate(accountStatusverify?.trial_expiry)
                                        : formatCustomDate(accountStatusverify?.package_expiry)}
                                </span>
                            </p>
                        </Col>
                        <Col md={12} className='mt-10 mb-10'>
                            <div className='box-emil-analytics pad-10'>
                                <div className="d-flex justify-content-between">
                                    <div><h6 className=''>Email Usage Analytics</h6></div>
                                    <div>{accountInfo?.email_messages}/{accountInfo?.limit_of_emails} Used</div>
                                </div>
                                <div>
                                    <ProgressBar animated now={percentageEmail} />
                                </div>
                            </div>
                            <div className='box-emil-analytics pad-10 mt-20'>
                                <div className="d-flex justify-content-between">
                                    <div><h6>SMS Usage Analytics</h6></div>
                                    <div>{accountInfo?.sms_messages}/{accountInfo?.limit_of_sms} Used</div>
                                </div>
                                <div>
                                    <ProgressBar animated now={percentageSMS} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                {accountStatusverify && !accountStatusverify?.is_trial && (
                    <CardInfromation />
                )}
            </Col>


            {showAddPackage && <AddPackage handleClose={addPackage} />}
        </>
    );
};

export default PlanOverview;
