import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Spinner as BootstrapSpinner } from "react-bootstrap";

import { CustomButton } from "../form/index";
import PhotosToken from "./PhotosToken";
import { JobActive, adduser, website, linkden, facebook, Xtiwtter, eye } from "../../helper/Icons/Icon";
import Analytics from "../common/Analytics";
import Spinner from "../common/Spinner";
import JobsLike from "./JobsLike";
import HeaderCompanName from "./HeaderCompanName";
import { companytoken, updateInterveiewTimeine } from "../../store/actions/InterviewproceedAction";
const config = require('../../config/config.json');

const CompanyToken = () => {
    const { token } = useParams();
    const [showerror, setErrors] = useState(null)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const clrName = "bg-ligte-gray";
    const { companyDetail, loading, error } = useSelector((state) => state.Interviewproceed);

    useEffect(() => {
        if (token) {
            dispatch(companytoken(token)).then((res) => {
                if (res.payload.error) {
                    setErrors(res.payload.error)
                }

            });

        } else {
            // Handle the case where token is empty or undefined
            console.error("Token is missing or undefined");
        }
    }, [dispatch]);

    const buttonColor = companyDetail?.button_colour_code;
    const buttonTextColor = companyDetail?.button_text_color_code;

    const ProceedInterview2 = () => {
        if (companyDetail) {
            const log = {
                key: `landing_page`,
                log: `Visited interview landing page`,
                date_time: new Date()
            };
            dispatch(updateInterveiewTimeine({ token, log }));
            navigate(`/job-detail/${token}`);
        } else {
            console.error("Company detail is missing");
        }
    };





    return (
        <>
            {loading && <Spinner />}
            {showerror ? <div className="link-error-wrap">
                <div className="link-error"><h1>{showerror}</h1></div>
            </div> :
                <>

                    <HeaderCompanName
                        logo={<img src={`${companyDetail.company_logo}`} style={{ width: "100%", height: "100%" }} alt="company logo" />}
                        bgclr={companyDetail?.header_footer_colour_code}
                        name={companyDetail.company_name}
                    />
                    <Col className="pad-20 bg-ligte-gray y-scroll">
                        <Row className="row-pad-0 virtelligenceTeam-card border-radius-5 pb-10">
                            <Col md={12} className="p-relative pad-0">
                                <div className="virtelligenceTeam-img">
                                    <img alt="" src={`${companyDetail.company_banner}`} />
                                </div>
                                <div className="profile-image-view-company p-absolute">
                                    <img src={`${companyDetail.company_logo}`} alt="" />
                                </div>
                                <div className="company-name">
                                    <b>{companyDetail.company_name}</b>
                                </div>
                            </Col>
                        </Row>
                        <Row className="row-pad-0 mt-20">
                            <Col md={7} sm={12}>
                                <Row className="pad-0 mt-20 bg-white border-radius-5">
                                    <Row className='row-pad-0 mt-10 mb-10'>
                                        <Col md={12} className='pad-0'>
                                            <h4 className="sub-heading">{companyDetail.company_name} Company Overview</h4>
                                        </Col>
                                        <span className='single-border d-block'></span>
                                    </Row>
                                    <Row className='row-pad-0 pad-0 mt-1 CompanyOverview-body'>
                                        <Col md={6}>
                                            <div className="content">
                                                <label>Company Name:</label><small>{companyDetail.company_name}</small>
                                            </div>
                                            <div className="content">
                                                <label>Industry:</label> <small>{companyDetail.industry}</small>
                                            </div>
                                            <div className="content">
                                                <label>City, State:</label> <small>{companyDetail.city} {companyDetail.state_name}</small>
                                            </div>
                                            <div className="content">
                                                <label>Zip Code:</label> <small>{companyDetail.zip_code}</small>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="content">
                                                <label>Website:</label> <small>{companyDetail.website_url}</small>
                                            </div>
                                            <div className="content">
                                                <label>Country:</label><small>{companyDetail.country_name}</small>
                                            </div>
                                            <div className="content">
                                                <label>Company Size:</label> <small>{companyDetail.company_size == 0 ? "" : companyDetail.company_size}</small>
                                            </div>
                                        </Col>
                                    </Row>
                                </Row>
                                <Row className="pad-0 mt-20 border-radius-5 bg-white">
                                    <Row className='row-pad-0 mt-10 mb-10'>
                                        <Col md={12} className='pad-0'>
                                            <h4 className="sub-heading">About {companyDetail.company_name}</h4>
                                        </Col>
                                        <span className='single-border d-block'></span>
                                    </Row>
                                    <Col md={12} className="mt-1 mb-10">
                                        <div dangerouslySetInnerHTML={{ __html: companyDetail?.company_description }} />
                                    </Col>
                                </Row>
                                <Row className="pad-0 mt-20 border-radius-5 bg-white">
                                    <PhotosToken company={companyDetail} token={token} />
                                </Row>
                            </Col>
                            <Col md={5} sm={12} className="sm-pad-0">
                                <Row className="pad-0 mt-20 bg-white border-radius-5 ml-10  border-radius-5 sml-0 smr-0">
                                    <Row className='row-pad-0 mt-10 mb-10 pb-10'>
                                        <Col md={12} className='pad-0'>
                                            <h4 className="sub-heading">Highlights</h4>
                                        </Col>
                                        <span className='single-border d-block'></span>
                                    </Row>
                                    <Row className="d-flex justify-content-center flex-wrap row-pad-0 pb-10">
                                        <div className="d-flex highlights-box border-radius-5 border-highlights-box pb-10 mr-15">
                                            <Analytics
                                                className={clrName}
                                                icon={JobActive}
                                                link=""
                                                text="Job Active"
                                                activeNumber={companyDetail.company_highlight?.active_jobs || 0}
                                            />
                                        </div>
                                        <div className="d-flex highlights-box border-radius-5 border-highlights-box pb-10 mr-15">
                                            <Analytics
                                                className={clrName}
                                                icon={eye}
                                                link=""
                                                activeNumber={companyDetail.company_highlight?.invites || 0}
                                                text="Invites"
                                            />
                                        </div>
                                        <div className="d-flex highlights-box border-radius-5 border-highlights-box pb-10 mr-15">
                                            <Analytics
                                                className={clrName}
                                                icon={adduser}
                                                link=""
                                                activeNumber={companyDetail.company_highlight?.interviews || 0}
                                                text="Interviews"
                                            />
                                        </div>
                                    </Row>
                                </Row>
                                <Row className="pad-0 mt-20 bg-white ml-10  border-radius-5 sml-0 smr-0">
                                    <Row className="row-pad-0 mt-10 mb-10 pb-10">
                                        <Col md={12} className="pad-0">
                                            <h4 className="sub-heading">Connect with {companyDetail?.company_name}</h4>
                                        </Col>
                                        <span className="single-border d-block"></span>
                                        <Col md={12} className="d-flex justify-content-around pb-10 ml-20 mt-10">
                                            {companyDetail.website_url && <Analytics className="bgwesites" icon={website} link={companyDetail.website_url} />}
                                            {companyDetail.linkedin_url && <Analytics className="bglinkden" icon={linkden} link={companyDetail.linkedin_url} />}
                                            {companyDetail.facebook_url && <Analytics className="bgfacebook" icon={facebook} link={companyDetail.facebook_url} />}
                                            {companyDetail.twitter_url && <Analytics className="bgxtiwtter" icon={Xtiwtter} link={companyDetail.twitter_url} />}
                                        </Col>
                                    </Row>
                                </Row>
                                <Row className="pad-0 mt-20 bg-white ml-10 pad-10  border-radius-5 sml-0 smr-0">
                                    <Col md={12} className="pad-0 mb-10">
                                        <JobsLike JobActive={companyDetail.similar_jobs} />
                                    </Col>
                                </Row>
                                <Row md={12} className="pad-0 mt-20">
                                    <div className="d-flex justify-content-end md-transform-btnX-12 sm-transform-btnX-12" >
                                        <CustomButton
                                            size="lg"
                                            style={{
                                                background: buttonColor,
                                                color: buttonTextColor,
                                                borderColor: buttonColor
                                            }}
                                            onClick={ProceedInterview2}
                                            text="Proceed with the Interview"
                                        />
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                    </Col >
                </>}
        </>
    );
};

export default CompanyToken;
