import { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Image, Carousel } from "react-bootstrap";
import { CustomButton } from "../form/index";
import Photos from "./Photos";
import Connectsocialmedia from "./Connectsocialmedia";
import HeaderCompanName from "./HeaderCompanName";
import Spinner from "../common/Spinner";
import { companyPreviewtoken } from "../../store/actions/InterviewproceedAction";
const config = require('../../config/config.json');

const PreviewCompanyToken = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [companyDetail, setcompanyDetail] = useState(null);

  const { companyPreviewDetail, loading, error } = useSelector((state) => state.Interviewproceed);

  useEffect(() => {
    if (token) {
      dispatch(companyPreviewtoken(token));
    }
  }, [dispatch, token]);

  useLayoutEffect(() => {
    if (companyPreviewDetail) {
      setcompanyDetail(companyPreviewDetail);
    }
  }, [companyPreviewDetail]);

  const data = {
    Companylabel: "Company Name",
    Companyvalue: companyDetail?.company_name || "-",
    Industrylabel: "Industry",
    Industryvalue: companyDetail?.industry?.industry_name,
    Citylabel: "City, State",
    Cityvalue: companyDetail?.city || "-",
    ZipCodelabel: "Zip Code",
    ZipCodevalue: companyDetail?.zip_code || "-",
    Websitelabel: "Website",
    Websitevalue: companyDetail?.website_url || "-",
    Countrylabel: "Country",
    Countryvalue: companyDetail?.country_name || "-",
    CompanySizelabel: "Company Size",
    CompanySizevalue: companyDetail?.company_size || "-",
  };

  const hasData = companyDetail?.company_name || companyDetail?.city || companyDetail?.zip_code || companyDetail?.website_url || companyDetail?.company_size;
  const hasDataConnect = companyDetail?.website_url || companyDetail?.linkedin_url || companyDetail?.twitter_url || companyDetail?.facebook_url;
  const shouldlogo = companyDetail?.company_logo;
  const shouldbanner = companyDetail?.company_banner;
  const buttonColor = companyDetail?.button_colour_code;
  const buttonTextColor = companyDetail?.button_text_colour_code;

  console.log(companyPreviewDetail, "companyPreviewDetail")

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  return (
    <>
      {companyDetail && (<>

        <HeaderCompanName logo={<img src={`${companyDetail.company_logo}`} style={{ width: "100%", height: "100%", }} alt="company logo" />} bgclr={companyDetail?.header_footer_colour_code} name={companyDetail.company_name} />



        <Col className="pad-20 bg-ligte-gray y-scroll">
          {companyDetail?.landing_page_settings?.show_banner && ((shouldlogo || shouldbanner) && (


            <Col md={12} className="p-relative pad-0 ">
              <Row className="row-pad-0 virtelligenceTeam-card pb-10 ">
                <Col md={12} className="p-relative pad-0 ">
                  <div className="virtelligenceTeam-img">
                    <img alt="" src={`${shouldbanner}`} />
                  </div>

                  {companyDetail?.landing_page_settings?.show_logo && <div className="profile-image-view-company p-absolute ">
                    <img src={`${shouldlogo}`} alt="" />
                  </div>
                  }
                  {companyDetail?.landing_page_settings?.show_name && <div className="company-name">
                    <b>{companyDetail.company_name}</b>
                  </div>
                  }
                </Col>
              </Row>
            </Col>
          ))}
          <Row className="row-pad-0 mt-20">
            <Col md={7} className="">
              {hasData &&
                <Row className="pad-0 mt-20 bg-white">
                  <div>
                    <Row className='row-pad-0 mt-10 mb-10'>
                      <Col md={12} className='pad-0'>
                        <h4 className="heading-h">{data?.Companyvalue} Company Overview</h4>
                      </Col>
                      <span className='single-border d-block'></span>
                    </Row>

                    <Row className='row-pad-0 pad-0 mt-1 CompanyOverview-body'>
                      <Col md={6} className=''>
                        {data?.Companyvalue && (
                          <div className="content">
                            <label>{data?.Companylabel}:</label>
                            <small>{data?.Companyvalue}</small>
                          </div>
                        )}
                        {data?.Industryvalue && (
                          <div className="content">
                            <label>{data?.Industrylabel}:</label>
                            <small>{data?.Industryvalue}</small>
                          </div>
                        )}
                        {data?.Cityvalue && (
                          <div className="content">
                            <label>{data?.Citylabel}:</label>
                            <small>{data?.Cityvalue}</small>
                          </div>
                        )}
                        {data?.ZipCodevalue && (
                          <div className="content">
                            <label>{data?.ZipCodelabel}:</label>
                            <small>{data?.ZipCodevalue}</small>
                          </div>
                        )}
                      </Col>

                      <Col md={6} className=''>
                        {data?.Websitevalue && (
                          <div className="content">
                            <label>{data?.Websitelabel}:</label>
                            <small>{data?.Websitevalue}</small>
                          </div>
                        )}
                        {data?.Countryvalue && (
                          <div className="content">
                            <label>{data?.Countrylabel}:</label>
                            <small>{data?.Countryvalue}</small>
                          </div>
                        )}
                        {data?.CompanySizevalue && (
                          <div className="content">
                            <label>{data?.CompanySizelabel}:</label>
                            <small>{data?.CompanySizevalue}</small>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Row>
              }
              {companyDetail?.landing_page_settings?.show_description && (companyDetail?.description &&
                <Row className="pad-0 mt-20 bg-white">
                  <Row className='row-pad-0 mt-10 mb-1 '><Col md={12} className='pad-0'><h4 className="heading-h">About {companyDetail?.company_name}</h4> </Col>
                    <span className='single-border d-block'></span>
                  </Row>
                  <Col md={12} className="mt-1 mb-10">
                    <p dangerouslySetInnerHTML={{ __html: companyDetail?.description }} />
                  </Col>
                </Row>
              )}
              <Row className="pad-0 mt-20 bg-white">
                {companyDetail?.landing_page_settings?.show_socials &&
                  (companyDetail?.culture_images?.length > 0 && (
                    <>
                      <Row className="row-pad-0 mt-10 mb-10">
                        <Col md={12} className="pad-0">
                          <h4 className="heading-h">{companyDetail?.company_name || " "} Photos</h4>
                        </Col>
                        <span className="single-border d-block"></span>
                      </Row>

                      <Row className="row-pad-0 mt-10 mb-10 d-flex justify-content-center">
                        <Col md={12} className="photos-carousel">
                          <Carousel
                            style={{
                              width: '400px',
                              height: '150px',
                            }}
                          >
                            {companyDetail?.culture_images.map((photo, index) => (
                              <Carousel.Item key={index}>
                                <Image
                                  src={`${photo.image_file}`}
                                  fluid
                                  style={{
                                    width: '100%', // Set to full width of the container
                                    height: '150px', // Set fixed height
                                    objectFit: 'cover' // Ensure the image covers the area
                                  }}
                                />
                              </Carousel.Item>
                            ))}
                          </Carousel>
                        </Col>
                      </Row>
                    </>
                  ))}
              </Row>
            </Col>
            <Col md={5} className="">
              <Row className="pad-0 mt-10 bg-white ml-10">
                {hasDataConnect && <Connectsocialmedia company={companyDetail} />}
              </Row>
            </Col>
          </Row>


          <Row md={12} className="pad-0 mt-20 ">
            <Col className="text-end ">
              <CustomButton
                size="lg"
                style={{
                  background: buttonColor,
                  color: buttonTextColor,
                  borderColor: buttonColor
                }}

                text="Proceed"
              />
            </Col>
          </Row>
        </Col>

      </>
      )}

    </>);
};

export default PreviewCompanyToken;
