import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Table, Col } from "react-bootstrap";
import { CustomRating } from "../form/index";
import IndividualEvaluation from '../RecordedInterviewDetails/IndividualEvaluation';
import { getInterviewRating } from "../../store/actions/interviewActions";
import * as moment from 'moment';
import { eye } from "../../helper/Icons/Icon";

const RatingsTable = ({ id }) => {
  const [showIndividualEvaluation, setShowIndividualEvaluation] = useState(false);
  const { InterviewRating, loading, error } = useSelector((state) => state.interview);
  const [evaluatorId, setEvaluatorId] = useState(null);
  const dispatch = useDispatch();
  const [sortConfig, setSortConfig] = useState({ key: 'created_date', order: 'asc' });
  const [searchField, setSearchField] = useState({});

  useEffect(() => {
    if (id) {
      dispatch(getInterviewRating(id, sortConfig, searchField));
    }
  }, [dispatch, id, sortConfig, searchField]);

  const handleFinalRemarks = (id) => {
    setEvaluatorId(id);
    if (evaluatorId) {
      setShowIndividualEvaluation(true);
    }
  };

  const handleFieldSearch = (e) => {
    const { name, value } = e.target;
    setSearchField((prevSearchField) => ({ ...prevSearchField, [name]: value }));
  };

  const toggleSortOrder = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      order: prevConfig.key === key && prevConfig.order === 'asc' ? 'desc' : 'asc',
    }));
  };

  return (
    <>
      <Col md={12} className="pad-0 bg-white radius-7">
        <h5 className="min-sub-heading pad-10"><label>Ratings</label></h5>
        <Table className="table rating-tabel-wrap" style={{ width: "auto" }}>
          <thead>
            <tr>
              <th style={{ width: "120px" }}></th>
              <th style={{ minWidth: "100px", width: "200px" }} className="sorting">
                <span onClick={() => toggleSortOrder('name')}>
                  {sortConfig.key === 'name'
                    ? sortConfig.order === 'asc'
                      ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                      : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                    : <i className="fa fa-exchange" aria-hidden="true"></i>}
                </span>
                <span>
                  <Form.Control
                    style={{ minWidth: "100px", width: "136px" }}
                    type="text"
                    name="name"
                    placeholder="Reviewer’s Name"
                    value={searchField.name}
                    onChange={handleFieldSearch}
                  />
                </span>
              </th>
              <th style={{ minWidth: "100px", width: "200px" }} className="sorting">
                <span onClick={() => toggleSortOrder('final_rating')}>
                  {sortConfig.key === 'final_rating'
                    ? sortConfig.order === 'asc'
                      ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                      : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                    : <i className="fa fa-exchange" aria-hidden="true"></i>}
                </span>

                <span>
                  <Form.Control
                    style={{ minWidth: "100px", width: "136px" }}
                    type="text"
                    name="final_rating"
                    placeholder="Rating"
                    value={searchField.rating}
                    onChange={handleFieldSearch}
                  />
                </span>
              </th>
              <th style={{ minWidth: "100px", width: "200px" }} className="sorting">
                <span onClick={() => toggleSortOrder('final_remarks')}>
                  {sortConfig.key === 'final_remarks'
                    ? sortConfig.order === 'asc'
                      ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                      : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                    : <i className="fa fa-exchange" aria-hidden="true"></i>}
                </span>
                <span>
                  <Form.Control
                    style={{ minWidth: "100px", width: "136px" }}
                    type="text"
                    name="final_remarks"
                    placeholder="Final Remarks"
                    value={searchField.final_remarks}
                    onChange={handleFieldSearch}
                  />
                </span>
              </th>
              <th style={{ minWidth: "100px", width: "200px" }} className="sorting">
                <span onClick={() => toggleSortOrder('created_date')}>
                  {sortConfig.key === 'created_date'
                    ? sortConfig.order === 'asc'
                      ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                      : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                    : <i className="fa fa-exchange" aria-hidden="true"></i>}
                </span>
                <span>
                  <Form.Control
                    style={{ minWidth: "100px", width: "136px" }}
                    type="text"
                    name="created_date"
                    placeholder="Requested On"
                    value={searchField.created_date}
                    onChange={handleFieldSearch}
                  />
                </span>
              </th>
              <th style={{ minWidth: "100px", width: "200px" }}>
                <span>
                  <Form.Control
                    style={{ minWidth: "100px", width: "136px" }}
                    type="text"
                    name="requestedBy"
                    placeholder="Requested By"
                    value={searchField.requestedBy}
                    onChange={handleFieldSearch}
                  />
                </span>
              </th>
              <th style={{ minWidth: "100px", width: "200px" }}>
                <span>
                  <Form.Control
                    style={{ minWidth: "100px", width: "136px" }}
                    type="text"
                    name="evaluationDate"
                    placeholder="Evaluation Date"
                    value={searchField.evaluationDate}
                    onChange={handleFieldSearch}
                  />
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="5" className="text-center">Loading...</td>
              </tr>
            ) : InterviewRating?.data?.length === 0 ? (
              <tr>
                <td colSpan="6" className="text-center"><h6>Data not found</h6></td>
              </tr>
            ) : (
              InterviewRating?.data?.map((rating, index) => (
                <tr key={index}>
                  {/* <td><span className="cursor" onClick={() => handleFinalRemarks(rating.evaluator_id)}>{eye}</span></td> */}
                  <td><a href="#">{rating.name}</a></td>
                  <td><CustomRating value={rating.final_rating} edit={false} /></td>
                  <td><span className="ellipsis">{rating.final_remarks}</span></td>
                  <td>{moment(rating.requested_on).format('DD-MM-YYYY h:mm A')}</td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Col>
      {/* {showIndividualEvaluation && <IndividualEvaluation evaluatorId={evaluatorId} handleClose={() => setShowIndividualEvaluation(false)} />} */}
    </>
  );
};

export default RatingsTable;
