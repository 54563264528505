import { Offcanvas, Row, Container, Col } from "react-bootstrap";

import { useEffect, useState } from "react"
import { CustomButton, InputField, Switch, SelectField, Label } from "../form/index";
import ShareCandidate from "./ShareCandidate";
import { adduser, attachment, crossicon, website, faplusicon, faminusicon, btnplus, expirtime } from "../../helper/Icons/Icon"
import Alljobs from "./Alljobs";
import '../../css/actionlist.css';
import AllUniqueJobCandidate from "./AllUniqueJobCandidate";
import ScheduleInterviews from "../../components/common/ScheduleInterview";

const InviteLiveInterview = ({ handleClose, text, modelopnetext, candidateId, UniqueJobCandidate }) => {

  const [selectedID, setSelectID] = useState(null);
  const [showscheduledjob, setscheduledjob] = useState(false);
  const [ShowshareCandidate, setshareCandidate] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    select_job: ''
  });
  const jobId = formData.select_job;
  useEffect(() => {
    setSelectID(candidateId);

  }, [])
  const scheduledHandler = (action) => {
    if (action === "scheduleInterview") {
      const validationErrors = validateForm(formData);
      if (Object.keys(validationErrors).length === 0) {
        setscheduledjob(!showscheduledjob);
        if (showscheduledjob) {
          handleClose();
        }
        else {
          setErrors(validationErrors);
        }
      }

    } else if (action === "ShareCandidate") {
      const validationErrors = validateForm(formData);
      if (Object.keys(validationErrors).length === 0) {

        setshareCandidate(!ShowshareCandidate);

        if (ShowshareCandidate) {
          handleClose();
        }
      } else {
        setErrors(validationErrors);
      }
    }
  };

  const Changehandle = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: value.trim() ? '' : 'Field is required'
    }));
  };

  const validateForm = (formData) => {
    const errors = {};
    if (!formData.select_job || formData.select_job.trim() === '') {
      errors.select_job = 'Select job is required';
    }
    return errors;
  };




  return (
    <Offcanvas show={true} onHide={handleClose} placement="end" className="bg-ligte-gray custom-offcanvas" style={{ width: "620px" }}>

      <Offcanvas.Header className="model-header bg-white ">

        <Offcanvas.Title>
          <span className="model-header-svg">{adduser}{text}</span>


        </Offcanvas.Title>
        <div className="text-end"><CustomButton onClick={handleClose} className="text-end close-btn " text={crossicon} /></div>
      </Offcanvas.Header>
      <Col md={12} className=" mt-10 pad-10 ">
        <Row className="row-pad-0 pad-20 bg-white radius-7  " >
          {modelopnetext == "scheduleInterview" ? <Alljobs Changehandle={Changehandle} /> : <AllUniqueJobCandidate UniqueJobCandidateID={UniqueJobCandidate} Changehandle={Changehandle} />}

          {errors.select_job && <span className="text-danger">{errors.select_job}</span>}


        </Row>

      </Col>
      <Col md={12} className="text-end mt-20 mb-20 pad-0">
        <CustomButton className="mr-10 custom-btn-primary" text="Submit" onClick={() => scheduledHandler(modelopnetext)} />

      </Col>



      {showscheduledjob && <ScheduleInterviews jobId={jobId} handleClose={() => scheduledHandler("scheduleInterview")} />}
      {ShowshareCandidate && <ShareCandidate jobId={jobId} handleClose={() => scheduledHandler("ShareCandidate")} />}
    </Offcanvas>
  );
}



export default InviteLiveInterview;