import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import InviteCandidates from "./InviteCandidtes";
import ActionList from "../common/ActionList";
import ReorderColumns from "../common/ReorderColumns";
import ScheduleInterviews from "../common/ScheduleInterview";
import GenerateLink from "../common/GenerateLink";
import { actionlistfields, redordercolumnfields } from "./fields";
import { Table, Dropdown, Form, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { adduser, eye, pencil, deletclrred, JobActive, exporticon, deletegray } from "../../helper/Icons/Icon";
import Spinner from "../common/Spinner";
import { CustomButton } from "../form";
import JobDetail from "./JobDetail";
import Editjob from "./Editjob";
import { useLocation } from "react-router-dom";
import ChangeStatus from "./ChangeStatus";
import * as moment from 'moment'
import { getAll, deleteJob, deleteJobs, exportFile } from '../../store/actions/jobActions'
import { getByName } from "../../store/actions/userPrefrenceActions";

import Swal from 'sweetalert2';
import Paginate from "../common/Paginate";

import Stages from "../common/Stages";
import Tabelcolumn from "../common/Tabelcolumn";
import Postjob from "../job/Postjob";


const defaultCurrentPage = 1

const JobList = () => {
    const location = useLocation();
    const { active_jobs } = location.state || {};

    const dispatch = useDispatch();
    const [updateList, setUpdateList] = useState(true)
    const [checkedAll, setcheckedAll] = useState(false)
    const dropdownRef = useRef(null);
    const [jobId, setJobId] = useState(false);
    const [showScheduleInterviews, setScheduleInterviews] = useState(false);
    const [showGenerateLink, setGenerateLink] = useState(false);
    const [showInviteCandidates, setInviteCandidates] = useState(false);
    const [showDetailPage, setshowDetailPage] = useState(false);
    const [showEditPage, setShowEditPage] = useState(false);
    const [showactionlist, setshowactionlist] = useState(false);
    const [showreorderColumns, setshowreorderColumns] = useState(false);
    const [reload, setReload] = useState(false);
    const [showChangeStatus, setChangeStatus] = useState(false);
    const [showPostJob, setShowPostJob] = useState(false);
    const prefrenceName = "users";

    const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
    const [perPage, setPerPage] = useState(25);

    const [jobIds, setJobIds] = useState([]);
    const [jobStatus, setjobStatus] = useState();
    const [searchField, setSearchField] = useState({});

    const { jobs, loading, error } = useSelector((state) => state.job);
    const { userPrefrenceDetail } = useSelector((state) => state.userPrefrence);
    let jobList = jobs.data;
    let headers = jobs.headers;
    let pages = jobs.pages;
    let totalPages = jobs.total_page;
    let totalRecords = jobs.total_records;

    const [sortConfig, setSortConfig] = useState({});
    useEffect(() => {
        dispatch(getByName(prefrenceName));
    }, [dispatch, updateList])

    useEffect(() => {
        if (userPrefrenceDetail?.preference_value?.record_per_page) {
            setPerPage(userPrefrenceDetail.preference_value.record_per_page);
        }
    }, [userPrefrenceDetail]);

    useEffect(() => {
        if (perPage) {
            let payload = {
                'limit': perPage, 'page': currentPage, search: searchField, order_by: sortConfig?.key, sort_by: sortConfig?.order,
                ...(active_jobs ? { active_jobs } : {})
            }
            dispatch(getAll(payload));
        }
    }, [dispatch, reload, currentPage, perPage, searchField, updateList, jobStatus, searchField, sortConfig]);

    const reloadList = (bol) => {
        setReload(bol)
    }

    const toggleCandidates = (type, id, statusJob) => {
        setJobId(id)
        reloadList(true)
        if (type === "adduser") {
            setInviteCandidates(!showInviteCandidates);

        }
        else if (type === "userDetail") {
            setshowDetailPage(!showDetailPage)
        }
        else if (type === "actionList") {
            setshowactionlist(!showactionlist)
        }
        else if (type === "reorderColumns") {
            setshowreorderColumns(!showreorderColumns)
        }
        else if (type === "ChangeStatus") {
            setJobId(id)
            setjobStatus(statusJob)
            setChangeStatus(!showChangeStatus)

        } else if (type === "editJob") {
            setShowEditPage(!showEditPage);
        }


    };
    const handleClose = (type) => {
        setUpdateList(!updateList);
        setJobIds([])

        if (type === "InviteCandidates") {
            setInviteCandidates(false);

        }
        else if (type === "userDetail") {
            setshowDetailPage(false)
        } else if (type === "editJob") {
            setShowEditPage(false);
        }
        else if (type === "actionList") {
            setshowactionlist(false)
        }
        else if (type === "reorderColumns") {
            setshowreorderColumns(false)
        }
        else if (type === "GenerateLink") {
            setGenerateLink(false)
        } else if (type === "ScheduleInterview") {
            setScheduleInterviews(false);
        }
        else if (type === 'CopyJobOrder') {
            setShowPostJob(false);
        }

    };

    const handleModelActionList = (type, ID) => {
        setJobId(ID)
        if (type === "ViewJobDetails") {
            setshowDetailPage(true)

        }
        if (type === "BulkInviteCandidates") {
            setInviteCandidates(!showInviteCandidates);

        }
        if (type === "GenerateRecordedLink") {
            setGenerateLink(!showGenerateLink)
        }
        if (type === "ScheduleLiveInterview") {
            setScheduleInterviews(!showScheduleInterviews);
        }
        if (type === "editJob") {
            setShowEditPage(!showEditPage);
        }
        if (type === "Delete") {
            handleDelete(ID)
            handleClose("actionList")
        }
        if (type === 'CopyJobOrder') {
            setShowPostJob(!showPostJob);
        }
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerPage = (perPage) => {
        setPerPage(perPage);
    };

    const handleSelectAllChange = (e) => {
        if (e.target.checked) {
            setcheckedAll(e.target.checked)
            setJobIds(jobList.map(job => job.id));
        } else {
            setJobIds([]);
            setcheckedAll(false)
        }
    };

    const handleCheckboxChange = (id) => {
        setJobIds(prevSelectedItems => {
            if (prevSelectedItems.includes(id)) {
                return prevSelectedItems.filter(itemId => itemId !== id);
            } else {
                return [...prevSelectedItems, id];
            }
        });
    };

    const deleteMsg = () => Swal.fire({
        icon: 'success',
        title: 'Deleted',
        text: 'Deleted a job successfully',
        showConfirmButton: false,
        timer: 3000
    });

    const handleDelete = (jobId = false) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure to delete the selected item(s).',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                if (jobId) {
                    dispatch(deleteJob(jobId))
                        .then(() => {
                            reloadList(true)
                            deleteMsg();

                        });
                } else {
                    var FormData = require('form-data');
                    var data = new FormData();
                    jobIds.forEach((value, index) => {
                        data.append(`ids[${index}]`, value);
                    });

                    dispatch(deleteJobs(data))
                        .then(() => {
                            reloadList(true)
                            setJobIds([]);
                            deleteMsg();
                        });
                }
            }
        });

        reloadList(false)
    };

    const handleFieldSearch = (e) => {
        const { name, value } = e.target;
        searchField[name] = value;
        setSearchField({ ...searchField })

    };

    const inviteCandidate = () => {
        setInviteCandidates(!showInviteCandidates);
    }

    const refreshPage = (page) => {
        setSearchField({});
        setUpdateList(!updateList);
        setcheckedAll(false);
        setJobIds([]);
        setCurrentPage(defaultCurrentPage);
        setPerPage(perPage);
        setSortConfig({})

        let payload = {
            'limit': perPage, 'page': currentPage, search: searchField,
            ...(active_jobs && {})
        }
        dispatch(getAll(payload));
    };

    const exportJobs = () => {
        let payload = {
            'export_file': 1
        };
        dispatch(exportFile(payload))
            .then((res) => {


                let byteCharacters;
                try {
                    byteCharacters = atob(res.payload);
                } catch (e) {
                    console.error('Base64 decoding failed:', e);
                    Swal.fire({
                        icon: 'error',
                        title: 'Download Failed',
                        text: 'Failed to decode Jobs data.',
                    });
                    return;
                }

                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);

                const blob = new Blob([byteArray], { type: 'text/csv' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'Job.csv';
                link.click();

                URL.revokeObjectURL(link.href);

                Swal.fire({
                    icon: 'success',
                    title: 'Jobs',
                    text: 'Jobs downloaded successfully.',
                    showConfirmButton: false,
                    timer: 3000
                });
            })
            .catch((error) => {
                console.error('Download failed:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Download Failed',
                    text: `Failed to download invoice: ${error.message}`,
                });
            });
    };


    const toggleSortOrder = (key) => {
        setSortConfig(prevConfig => {
            if (prevConfig.key === key) {
                return { key, order: prevConfig.order === 'asc' ? 'desc' : 'asc' };
            } else {
                return { key, order: 'asc' };
            }
        });
    };

    return (
        <>
            {loading &&
                <Spinner />
            }

            <Row className="listing-section row-pad-0" >

                <Col md={12} className="text-end d-flex justify-content-end mt-10 mb-1" >
                    <Dropdown className="linsting-dropdown-arrowNone mr-10" drop="down-centered start" >
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip-quick-action">
                                    More Items
                                </Tooltip>
                            }
                        >
                            <Dropdown.Toggle id="dropdown-autoclose-true" className="header-btn y-center" variant="none">
                                <i class="fa fa-ellipsis-v" ></i>

                            </Dropdown.Toggle>
                        </OverlayTrigger>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => toggleCandidates("ChangeStatus", jobIds)} disabled={jobIds.length === 0}>
                                {JobActive} Change Job Status
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleDelete()} disabled={jobIds.length === 0}>
                                {deletegray} Delete
                            </Dropdown.Item>
                            <Dropdown.Item onClick={exportJobs}>
                                {exporticon} Export
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => inviteCandidate()} disabled={jobIds.length === 0}>
                                {adduser} Invite Candidates
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    <Stages reloadList={reloadList} module={'jobs'} userPrefrence={'job_listing_stage_filter'} />

                    <CustomButton module="jobs" className="header-btn mr-10" onClick={() => toggleCandidates("reorderColumns")} Tooltip="Select column" text={<i class="fa fa-columns fa-fw"></i>} />
                    <CustomButton onClick={refreshPage} className="header-btn mr-10" Tooltip="Refresh" text={<i class="fa fa-refresh"></i>} />
                </Col>

                <div className="oveflow-jobList-auto">
                    <Table className="table" style={{}}>
                        <thead>
                            <tr>
                                <th style={{ width: "15px" }} ><Form.Check type="checkbox" checked={checkedAll} onChange={handleSelectAllChange} /></th>
                                <th style={{ width: "15px" }} className="th-icon "><i className="fa fa-ellipsis-v" title="Quick Action"></i></th>
                                <th style={{ minWidth: "100px", width: "140px" }} className="th-text">Quick Action</th>
                                {headers &&
                                    headers.map((header, index) => {
                                        const fieldValue = searchField[header.key] || '';
                                        const isSortedColumn = sortConfig.key === header.key;

                                        return (
                                            <th key={index} className="sorting" style={{ minWidth: "100px", width: "200px" }}>
                                                <span onClick={() => toggleSortOrder(header.key)}>
                                                    {isSortedColumn ? (sortConfig.order === 'asc' ? <i class="fa fa-sort-amount-asc" aria-hidden="true"></i> : <i class="fa fa-sort-amount-desc" aria-hidden="true"></i>) : <i class="fa fa-exchange" aria-hidden="true"></i>}
                                                </span>
                                                <Form.Control name={header.key} type="text" value={fieldValue} placeholder={header.title} onChange={handleFieldSearch} />
                                            </th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>

                        <tbody>
                            {jobList && jobList.length > 0 ? (
                                jobList.map((job) => (
                                    <tr key={job.id}>
                                        <td>
                                            <Form.Check
                                                key={job.id}
                                                type="checkbox"
                                                checked={jobIds.includes(job.id)}
                                                onChange={() => handleCheckboxChange(job.id)}
                                            />
                                        </td>

                                        <td className="cursor quick-action td-icon"><Tabelcolumn icon={<i className="fa fa-ellipsis-v" onClick={() => toggleCandidates("actionList", job.id)} ></i>} Tooltip="Quick Action" /> </td>





                                        <td className="td-icon">
                                            <div className="quick-action d-flex">
                                                <Tabelcolumn span="span" onClick={() => toggleCandidates("adduser", job.id)} icon={adduser} Tooltip="Invite Candidate" />


                                                <Tabelcolumn span="span" onClick={() => toggleCandidates("userDetail", job.id)} icon={eye} Tooltip="Detail" />

                                                <Tabelcolumn span="span" className="eye" onClick={() => toggleCandidates("editJob", job.id)} icon={pencil} Tooltip="Edit" />



                                                <Tabelcolumn span="span" onClick={() => handleDelete(job.id)} Tooltip="Delete" icon={deletclrred} />
                                            </div>
                                        </td>
                                        {Object.keys(job).map(function (key) {
                                            if (key == "job_title")
                                                return <td className="td-text text-ellipsis" ><a href="#" onClick={() => toggleCandidates("userDetail", job.id)}>{job[key]}</a></td>;
                                            else if (key == "stage_name")

                                                return <td ><CustomButton className={`status-${job[key]} status`} onClick={() => toggleCandidates("ChangeStatus", job.id, job[key])} text={job[key]} /></td>;
                                            else if (key == "interview_analytics")
                                                return <td className="td-text text-ellipsis"> {key} </td>;
                                            else if (key == "job_expiry_date")
                                                return <td className="td-text text-ellipsis" >{moment(job[key]).format('MM-DD-YYYY')}</td>;
                                            else if (key.includes("_date"))
                                                return <td className="td-text text-ellipsis" >{moment(job[key]).format('MM-DD-YYYY h:mm A')}</td>;
                                            else if (key != "id")
                                                return <td className="td-text text-ellipsis">{job[key]}</td>

                                        })}

                                    </tr>
                                ))) : (<tr>
                                    <td colSpan={10} className="text-center">No records found.</td>
                                </tr>)

                            }

                        </tbody>
                    </Table>
                    <Paginate
                        pages={pages}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        perPage={perPage}
                        totalRecords={totalRecords}
                        handlePageChange={handlePageChange}
                        handlePerPage={handlePerPage}
                    />

                </div>
            </Row>
            {showInviteCandidates && <InviteCandidates jobId={jobId} handleClose={() => handleClose("InviteCandidates")} />}
            {showDetailPage && <JobDetail jobId={jobId} handleClose={() => handleClose("userDetail")} />}
            {showEditPage && <Editjob jobId={jobId} handleClose={() => handleClose("editJob")} />}
            {showPostJob && <Postjob copyJobId={jobId} handleClose={() => handleClose("CopyJobOrder")} />}
            {showactionlist && <ActionList ID={jobId} list={actionlistfields} headertext=" Action List " onclick={handleModelActionList} handleClose={() => handleClose("actionList")} />}
            {showreorderColumns && <ReorderColumns module="jobs" list={redordercolumnfields} headertext="Manage Columns" handleClose={() => handleClose("reorderColumns")} />}
            {showGenerateLink && <GenerateLink jobId={jobId} handleClose={() => handleClose("GenerateLink")} />}
            {showScheduleInterviews && <ScheduleInterviews jobId={jobId} handleClose={() => handleClose("ScheduleInterview")} />}
            {showChangeStatus && <ChangeStatus handleClose={() => toggleCandidates("ChangeStatus")} jobId={jobId} statusActive={jobStatus} reloadList={reloadList} />}

        </>
    );
};

export default JobList;
