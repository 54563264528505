import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row } from "react-bootstrap";
import { mic } from "../../helper/Icons/Icon";
import { CustomButton } from "../form";
import Swal from 'sweetalert2';
import Spinner from '../common/Spinner';

import { useParams } from 'react-router-dom';
import { addquestionAnswer, updateInterveiewTimeine } from "../../store/actions/InterviewproceedAction";
const config = require('../../config/config.json');

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 2000
    });
}

const AudioRecorder = ({ question, answer, activeStep, reLoad }) => {
    const { token } = useParams();
    const audioref = useRef(null);
    const mediaRecorder = useRef(null);
    const dispatch = useDispatch();
    const [isRecording, setIsRecording] = useState(false);
    const [recordedBlob, setRecordedBlob] = useState(null);
    const [aurl, setAurl] = useState(null);
    const [mLoading, setMLoading] = useState(false);

    const [retakeVideo, setRetakeVideo] = useState(false);
    const [retakeCount, setRetakeCount] = useState(answer?.answer_retake ?? 0);
    const [audioChunks, setAudioChunks] = useState([]);
    const [startStreem, setStartStreem] = useState(null);

    const [thinkTime, setThinkTime] = useState(0);
    const [responseTime, setResponseTime] = useState(answer?.response_time_insec ?? 0);
    const th = useRef();
    const rs = useRef();

    useEffect(() => {
        if (!isRecording && !answer?.interview_answer) {
            th.current = setInterval(() => setThinkTime(prevSeconds => prevSeconds + 1), 1000)
        }

        if (isRecording) {
            rs.current = setInterval(() => setResponseTime(prevSeconds => prevSeconds + 1), 1000)
        }

    }, [isRecording, answer, aurl]);

    const startRecording = async (retacke) => {
        setMLoading(true)
        if (!answer?.interview_answer) {
            clearInterval(th.current);
        }

        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

            audioref.current.srcObject = stream;
            mediaRecorder.current = new MediaRecorder(stream);
            mediaRecorder.current.start();

            setMLoading(false)
            setStartStreem(stream)
            setIsRecording(true);

            if (retacke) {
                setRetakeCount(retakeCount + 1)
            }


            const chunks = [];
            mediaRecorder.current.ondataavailable = (event) => {
                chunks.push(event.data);
            };

            setAudioChunks(chunks);

        } catch (error) {
            let errorMessage = "Error: Could not start audio source";
            if (error.name === "NotReadableError") {
                errorMessage += ". This could be due to permission issues, browser support, or device availability.";

            } else if (error.name === "NotAllowedError") {
                errorMessage += ". Please grant permission to access the microphone.";

            }

            Swal.fire({
                icon: "error",
                title: "Error!",
                text: errorMessage,
                showConfirmButton: false,
                timer: 2000
            });
        }

    };


    const stopRecording = (retacke) => {
        clearInterval(rs.current);

        if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
            mediaRecorder.current.stop();
        }

        mediaRecorder.current.onstop = () => {

            const blob = new Blob(audioChunks, { type: 'audio/webm' });
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
                var base64data = reader.result;
                setRecordedBlob(blob);


                const audioUrl = window.URL.createObjectURL(blob);
                setAurl(audioUrl)
                const payload = {
                    interview_template_question_id: question.interview_template_question_id,
                    response_time: Math.ceil(responseTime / 60),
                    response_time_insec: responseTime,
                    think_time: Math.ceil(thinkTime / 60),
                    interview_answer: audioUrl,
                    interview_answer_encoded: base64data,
                    retakes: retakeCount,
                    question_text: question.question_text,
                    answer_media: question.question_type_name,

                }
                dispatch(addquestionAnswer({ token, payload }))
                    .then((res) => {
                        reLoad(true)
                    })
                    .catch(() => {
                        sweetAlert(
                            "error",
                            "Error!",
                            "Something went wrong!"
                        );
                    });

                let qNumber = activeStep + 1;
                const log = {
                    key: `question_${qNumber}`,
                    log: `Submitted Response for ${question.question_type_name} Question ${qNumber}`,
                    date_time: new Date()

                };
                dispatch(updateInterveiewTimeine({ token, log }));

                audioref.current.srcObject = null;
                startStreem.getTracks().forEach(track => track.stop());

                setIsRecording(false);
                setRetakeVideo(false);
                setAudioChunks([]);
                setStartStreem(null)
                reLoad(false)

            };
        }
    };


    const handleDownload = (fileUri) => {
        const a = document.createElement('a');
        a.href = fileUri;
        a.download = fileUri.split('/').pop() || 'download';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    return (

        <Row className="row-pad-0 pad-10 border-radius-5 interview-media-wrap bg-ligte-gray mt-15">
            {mLoading && <Spinner />}
            <Col md={12} className="RecordAudio-wrap">
                <h4 className="sub-heading">
                    <span className="mr-5">{mic}Record Audio:</span>
                </h4>
                <div className="audio-section mt-20">
                    <span className="ready-audio">
                        <small></small>
                    </span>


                    <>
                        {(!answer?.interview_answer || retakeVideo) &&
                            <audio controls ref={audioref} autoPlay />
                        }

                        {(!retakeVideo && answer?.interview_answer) && (
                            <audio controls
                                src={aurl ?? `${config.API_BASE_URL_PUBLIC}/${answer?.interview_answer}`}
                            />
                        )}

                        <div className="audio-buttons">
                            {(!answer?.interview_answer || retakeVideo) && (
                                <>
                                    {retakeVideo ?
                                        <>
                                            <CustomButton text="Start Recording" className=" custom-btn-primary mt-20 mr-10 " disabled={isRecording} onClick={() => startRecording(true)} />
                                            <CustomButton text="Stop Recording" className="custom-btn-outline mr-10 mt-20 mr-10" disabled={!isRecording} onClick={() => stopRecording(true)} />
                                            <CustomButton text="Cancel Retake" className="custom-btn-outline mr-10 mt-20" disabled={isRecording} onClick={() => setRetakeVideo(false)} />

                                        </>
                                        :
                                        <>
                                            <CustomButton text="Start Recording" className=" custom-btn-primary mt-20 mr-10" disabled={isRecording} onClick={() => startRecording(false)} />
                                            <CustomButton text="Stop Recording" className="custom-btn-outline mr-10  mt-20" disabled={!isRecording} onClick={() => stopRecording(false)} />

                                        </>
                                    }

                                    <span className="mt-1" style={{ display: isRecording ? 'block' : 'none' }}>Responding time: {responseTime}</span>

                                    {(!isRecording && !answer?.interview_answer) &&

                                        <div className="mt-1"><span>thinking time: {thinkTime}</span></div>
                                    }
                                </>
                            )}

                            {(!retakeVideo && answer?.interview_answer) && (
                                <> <CustomButton text="Download Audio" className="custom-btn-outline mr-10 mt-20" onClick={() => handleDownload(`${config.API_BASE_URL_PUBLIC}/${answer?.interview_answer}`)} />

                                    {question.retakes !== answer?.answer_retake &&

                                        <CustomButton text="Retake " className="custom-btn-outline mt-20" onClick={() => setRetakeVideo(true)} />
                                    }
                                </>
                            )}
                        </div>
                    </>


                </div>

            </Col>
        </Row>

    );
};

export default AudioRecorder;
