import { Offcanvas, Container, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CriclrDiv from "../common/CriclrDiv";
import { CustomRating } from "../form/index";
import { crossicon, userclick, acceptcandidate, rejectecandidate, share, downloadarrowblow, Video, HiredICon } from "../../helper/Icons/Icon";
import { CustomButton } from "../form/index";
import aryaimg from "../../assets/images/users.png"
import { downloadinterview } from '../../store/actions/interviewActions';
import { getRecordedInterview } from '../../store/actions/interviewActions';
import { commentCreateAccept, commentCreatecandRejected, commentCreateHired } from '../../store/actions/recorderInterviewAction';
// import { interviewtemplatetokent, UpdateInterviewByToken } from "../../store/actions/InterviewproceedAction";
import TimeLineLive from "./TimeLineLive";
import RatingsTabelLiveInterview from '../RecordedInterviewDetails/RatingsTabelLiveInterview';
import LiveVideo from "./LiveVideo";
import CommentModel from '../common/CommentModel';
import CustomAlert from '../common/CustomAlert';
import ShareCandidateLiveInterview from "../common/ShareCandidateLiveInterview";
import Swal from 'sweetalert2';
import Spinner from "../common/Spinner";

const sweetAlert = (type, title, text) => {
  Swal.fire({
    icon: type,
    title: title,
    text: text,
    showConfirmButton: false,
    timer: 2500
  });
}
const Liveterview = ({ handleClose, id }) => {
  const [updateList, setUpdateList] = useState(true)
  const [showShareCandidate, setShowShareCandidate] = useState(false);


  const [showHiredCandidate, setHiredCandidate] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [commentData, setCommentData] = useState();
  const dispatch = useDispatch();
  const { recordedInterview, loading, error } = useSelector((state) => state.interview);
  const candidate = recordedInterview?.interview_candidate;
  const jobId = recordedInterview?.job_id;
  const interviewId = recordedInterview?.interview_id;
  const candidateId = candidate?.candidate_id;

  useEffect(() => {
    if (id) {
      dispatch(getRecordedInterview(id));
    }
  }, [dispatch]);



  const handelModel = (props) => {


    if (props === "Evaluation") {
      setShowShareCandidate(true);
    }
    if (props === "download") {
      InterviewsDownload(interviewId)
    }
  }

  const handelCloseModel = (props) => {


    if (props === "Evaluation") {
      setShowShareCandidate(false);
    }
    if (props === "alert") {
      setShowAlert(false);
    }

  }
  const InterviewsDownload = (interviewId) => {

    if (interviewId) {
      dispatch(downloadinterview(interviewId))
        .then((res) => {
          console.log(res, "res")

          if (res.error)
            Swal.fire({
              icon: 'error',
              title: 'Download Failed',
              text: `${res.error.message}`,
              showConfirmButton: false,
              timer: 2500
            });
          else {
            Swal.fire({
              icon: 'success',
              title: 'Video interview',
              text: `Video interview successfully downloaded !`,
              showConfirmButton: false,
              timer: 2500
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: 'error',
            title: 'Download Failed',
            text: `Failed to download invoice: ${error.message}`,
          });
        });

    }
    else {

      Swal.fire({
        icon: 'error',
        title: 'errro',

        showConfirmButton: false,
        timer: 1000
      });
    }

  }




  const HiredModel = () => {
    const payload = "ok";

    if (!candidateId || !interviewId) {
      console.error('Candidate ID or Interview ID is undefined.');
      return;
    }

    dispatch(commentCreateHired({ candidateId, interviewId, payload }))
      .then(() => {

        dispatch(getRecordedInterview(id));
        return sweetAlert('success', 'Success!', 'Candidate Hired successfully!');

      })
      .catch((error) => {
        console.error('Error:', error);
        return sweetAlert('error', 'Error!', 'Something went wrong!');
      });

  }


  // const parseStyle = (styleString) => {
  //   const styleObj = {};
  //   styleString.split(';').forEach(style => {
  //     const [key, value] = style.split(':');
  //     if (key && value) {
  //       styleObj[key.trim()] = value.trim();
  //     }
  //   });
  //   return styleObj;
  // }
  return (
    <>
      <Offcanvas show={true} onHide={handleClose} placement="end" className="bg-ligte-gray recordedinterview-wrap custom-offcanvas" style={{ width: "80%" }}>



        <Container fluid className="detailpage-wrap pad-0">
          <Offcanvas.Header className="model-header actionlist-header bg-white">
            <Offcanvas.Title><span className="svg mr-10">{Video}</span>Live Interview Details</Offcanvas.Title>
            <div className="text-end"><CustomButton onClick={handleClose} className="text-end close-btn " text={crossicon} /></div>
          </Offcanvas.Header>
          {loading && <Spinner />}
          <Col md={12} className="pad-0 recordedinterview-body">
            <Row className="row-pad-0">
              <Col md={8} className="">
                <Row className="row-pad-0 detail-interviewer bg-white">
                  <div className="flex-space-between">
                    <div className="d-flex">
                      <h5 className="name">
                        <label className="min-sub-heading">Candidate Details</label> <small>/  {recordedInterview?.job_title}</small>
                      </h5>
                      {recordedInterview?.interview_stage && (
                        <CustomButton

                          className={`status ${recordedInterview.interview_stage.stage_name.replace(/\s+/g, '-').toLowerCase()}`}
                          text={recordedInterview.interview_stage.stage_name}
                        />
                      )}
                    </div>
                    <div>
                      <CustomRating />
                    </div>
                  </div>
                  <Row className="row-pad-0 custom-row-pad-0">
                    <Col md={1} className="custom-md-1 pad-0 mt-10">
                      <div className="profile mr-15">
                        <img src={aryaimg} alt="user profile" />
                      </div>
                    </Col>
                    <Col md={11} className="content pad-0 custom-md-11 mt-10">
                      <div className="flex-space-between">
                        <div className="d-flex">
                          <h5 className="name">
                            <span className="title-label">{candidate?.first_name} {candidate?.last_name}</span>
                          </h5>
                        </div>
                      </div>
                      <div className="flex-Xcenter">
                        {candidate?.email_address && (
                          <div className="list-title">
                            <label className="title-label">Email:</label>
                            <span className="span-data">{candidate.email_address}</span>
                          </div>
                        )}



                        {candidate?.address && (
                          <div className="list-title">
                            <label>Address:</label>
                            <span className="span-data">{candidate.address}</span>
                          </div>
                        )}

                        {candidate?.phone && (
                          <div className="list-title">
                            <label className="title-label">Phone Number:</label>
                            <span className="span-data">{candidate.phone}</span>
                          </div>
                        )}

                      </div>



                    </Col>
                  </Row>
                </Row>
                <Row className="row-pad-0  pad-20  interviewer-responses bg-white mt-20 ">
                  <h6 className='pad-0 mt-1 bold'> Meeting Recording</h6>
                  <div className=' mt-20 Response-wrap  pad-10'>
                    {recordedInterview?.media_location ? (
                      <video width="100%" height="auto" controls>
                        <source src={recordedInterview?.media_location} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      !loading && <div>No video available.</div>
                    )}
                    {/* <LiveVideo interviewId={recordedInterview?.interview_id} /> */}
                  </div>
                </Row>
              </Col>
              <Col md={4}>
                <Row className="row-pad-0 timeline-wrap">
                  <div className="d-flex">
                    <CriclrDiv icon={share} onclick={() => handelModel("Evaluation")} tooltiptext="Share Candidate’s Live Interview for Evaluation" clrName="timeline-criclr border-header cursor" />
                    {/* <CriclrDiv icon={acceptcandidate} onclick={() => handelModel("apcceptCandidate")} tooltiptext="Accept Candidate" clrName="timeline-criclr accept-candidate-svg border-header cursor" />*/}
                    <CriclrDiv icon={downloadarrowblow} clrName="timeline-criclr border-header cursor" onclick={() => handelModel("download")} tooltiptext="Download" />
                    <CriclrDiv icon={HiredICon} onclick={HiredModel} tooltiptext="Hired" clrName="timeline-criclr border-header cursor" />
                  </div>

                  {
                    interviewId && <TimeLineLive id={interviewId} />
                  }
                </Row>
              </Col>
            </Row>

            <Row className="row-pad-0 rating-tabel-wrap ">
              <RatingsTabelLiveInterview />
            </Row>


          </Col>
        </Container>

        {/* {showHiredCandidate && <CommentModel icon={HiredICon} headertext="Hired" handelComment={handelComment} handelSubmit={handelHired} handleClose={() => handelCloseModel("Hired")} />} */}
        {showAlert && <CustomAlert handleClose={() => handelCloseModel("alert")} />}
        {showShareCandidate && <ShareCandidateLiveInterview jobId={jobId} handleClose={() => handelCloseModel("Evaluation")} />}
      </Offcanvas>




    </>



  )
}

export default Liveterview;