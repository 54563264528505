import { Button, Row, Col, Form, Offcanvas } from "react-bootstrap";
import users from "../../assets/images/users.png";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useRef } from 'react';
import { CustomButton, InputField, Label } from "../form";
import { attachment, crossicon, adduser } from "../../helper/Icons/Icon";
import Countries from "../common/Countries";
import States from "../common/States";
import UsersRoles from "../common/UsersRoles";
import { UplodedFiles } from '../../store/actions/companyActions';
import { update, getById } from "../../store/actions/userActions";
import Swal from "sweetalert2";
import TimeZone from "../common/TimeZone";
import Spinner from "../common/Spinner";
const config = require('../../config/config.json');
const sweetAlert = (type, title, text) => {
  Swal.fire({
    icon: type,
    title: title,
    text: text,
    showConfirmButton: false,
    timer: 2000,
  });
};

const EditUser = ({ handleClose, profileId }) => {
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const [image, setImage] = useState(false);
  const [formSate, setFormSate] = useState(null);
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const [errors, setErrors] = useState({});
  const { userDetail, loading, error } = useSelector((state) => state.user);

  useEffect(() => {
    if (profileId) {
      dispatch(getById(profileId));
    }
  }, [dispatch, profileId]);

  useEffect(() => {
    if (userDetail) {
      setFormData({
        user_image: userDetail.user_image,
        firstName: userDetail.fname,
        lastName: userDetail.lname,
        email: userDetail.email,
        address: userDetail.address,
        city: userDetail.city,
        state_name: userDetail.state_name,
        zipcode: userDetail.zip_code,
        country_name: userDetail.country ?? 'United States',
        phoneNumber: userDetail.phone,
        user_role: userDetail.role_id,
        timezone_id: userDetail.timezone_id,
      });
      setSelectedImage(userDetail.user_image);
      setFormSate(userDetail?.state_name,)
    }
  }, [userDetail]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: value.trim() ? '' : 'Field is required'
    }));
  };
  const handleSearChange = (name, value) => {

    setFormSate(null)
    setFormData({
      ...formData,
      [name]: value
    });



    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: value.toString().trim() ? '' : ' is required'
    }));




  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(true);
      setSelectedImage(file);
    }
  };

  const handleImageUpload = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (image) {
      const payload = {
        file: selectedImage,
        moduleName: "users"
      };
      dispatch(UplodedFiles(payload))
        .then((res) => {
          if (res.payload.error) {
            sweetAlert("error", "Error!", `${res.payload.error}!`);
          } else {
            setSelectedImage(res.payload.data[0].path);
            setImage(false);
          }
        })
        .catch(() => {
          sweetAlert("error", "Error!", "Something went wrong!");
        });
    }
  }, [image, selectedImage, dispatch]);

  const EditUserSubmit = () => {
    const newErrors = {};
    if (!formData.firstName) {
      newErrors.firstName = 'First name is required';
    }
    if (!formData.lastName) {
      newErrors.lastName = 'Last name is required';
    }
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/.test(formData.email)) {
      newErrors.email = 'Invalid email address';
    }
    if (!formData.user_role) {
      newErrors.user_role = 'User role is required';
    }
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      let formDataFields = {
        user_image: selectedImage,
        fname: formData.firstName,
        lname: formData.lastName,
        email: formData.email,
        address: formData.address,
        city: formData.city,
        state_name: formData.state_name,
        zip_code: formData.zipcode,
        country: formData.country_name,
        phone: formData.phoneNumber,
        role_id: formData.user_role,
        timezone_id: formData.timezone_id,
      };

      dispatch(update({ profileId, formDataFields }))
        .then((res) => {
          if (res.payload.error) {
            sweetAlert("error", "Error!", `${res.payload.error}!`);
          } else {
            handleClose();
            sweetAlert("success", "Success!", res.payload.message);
          }
        })
        .catch(() => {
          sweetAlert("error", "Error!", "Something went wrong!");
        });
    }
  };

  return (
    <>
      <Offcanvas
        show={true}
        onHide={handleClose}
        placement="end"
        className="bg-ligte-gray custom-offcanvas"
        style={{ width: "60%" }}
      >
        <Offcanvas.Header className="model-header bg-white ">
          <Offcanvas.Title>
            <span className="svg mr-10">{adduser}</span>Edit User
          </Offcanvas.Title>
          <div className="text-end">
            <CustomButton
              onClick={handleClose}
              className="text-end close-btn "
              text={crossicon}
            />
          </div>
        </Offcanvas.Header>
        {loading && <Spinner />}
        <Offcanvas.Body className="mt-10">
          <Col md={12} className="add-user-wrap">
            <Row className="row-pad-0 radius-5 bg-white pad-10">
              <Col md={12} className='mt-10 ml-10'>
                <div>
                  {selectedImage ? (
                    <img
                      style={{ width: '65px', height: '65px', borderRadius: '50%' }}
                      src={`${selectedImage}`}
                      alt="User Profile"
                      className="img-fluid"
                    />
                  ) : (
                    <img
                      style={{ width: '65px', height: '65px', borderRadius: '50%' }}
                      src={users}
                      alt="User Profile"
                      className="img-fluid"
                    />
                  )}
                </div>
                <div className='mt-10'>
                  {!selectedImage ? (
                    <Button className="upload-pic" onClick={handleImageUpload}>
                      {attachment} Upload picture
                      <input
                        ref={fileInputRef}
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                      />
                    </Button>
                  ) : (
                    <Button className="upload-pic" onClick={handleImageUpload}>
                      <i className="fa fa-undo fa-fw"></i>
                      <input
                        ref={fileInputRef}
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                      />
                    </Button>
                  )}
                </div>
              </Col>

              <Row className="row-pad-0 mt-10">
                <Col md={6} className="mb-10">
                  <Row>
                    <Col md={6}>
                      <Label text="First Name" className="label-input" required="required" />
                      <InputField
                        type="text"
                        id="firstName"
                        name="firstName"
                        onChange={handleChange}
                        placeholder="Enter first name"
                        value={formData.firstName || ''}
                      />
                      {errors.firstName && <span className="text-danger">{errors.firstName}</span>}
                    </Col>
                    <Col md={6}>
                      <Label text="Last Name" className="label-input" required="required" />
                      <InputField
                        type="text"
                        id="lastName"
                        name="lastName"
                        onChange={handleChange}
                        placeholder="Enter last name"
                        value={formData.lastName || ''}
                      />
                      {errors.lastName && <span className="text-danger">{errors.lastName}</span>}
                    </Col>
                  </Row>
                </Col>
                <Col md={6} className="mb-10">
                  <UsersRoles onChange={handleChange} userRoleId={formData.user_role} />
                  {errors.user_role && <span className="text-danger">{errors.user_role}</span>}
                </Col>
                <Col md={6} className="mb-10">
                  <Label text="Email" className="label-input" required="required" />
                  <InputField
                    type="text"
                    id="email"
                    name="email"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                    onChange={handleChange}
                    value={formData.email || ''}
                    placeholder="Enter your email address"
                  />
                  {errors.email && <span className="text-danger">{errors.email}</span>}
                </Col>
                <Col md={6} className="mb-10">
                  <Label text="Phone Number" className="label-input" />
                  <InputField
                    type="text"
                    id="phoneNumber"
                    name="phoneNumber"
                    onChange={handleChange}
                    value={formData.phoneNumber || ''}
                    placeholder="Enter your phone number"
                  />
                </Col>

                <Col md={6} className="mb-10">
                  <Label text="Address" className="label-input" />
                  <InputField
                    type="text"
                    id="address"
                    name="address"
                    onChange={handleChange}
                    value={formData.address || ''}
                    placeholder="Enter your address"
                  />
                </Col>
                <Col md={6} className="mb-10">
                  <Label text="City" className="label-input" />
                  <InputField
                    type="text"
                    id="city"
                    name="city"
                    onChange={handleChange}
                    value={formData.city || ''}
                    placeholder="Enter your city"
                  />
                </Col>
                <Col md={6} className="mb-10">
                  <States onChange={handleSearChange} state={formSate} stateName={formData.country_name} />
                </Col>
                <Col md={6} className="mb-10">
                  <Countries onChange={handleSearChange} countryName={formData.country_name} />
                </Col>
                <Col md={6} className="mb-10">
                  <Label text="Zip Code" className="label-input" />
                  <InputField
                    type="text"
                    id="zipcode"
                    name="zipcode"
                    onChange={handleChange}
                    value={formData.zipcode || ''}
                    placeholder="Zip Code"
                  />
                </Col>
                <Col md={6} className="mb-10">
                  <TimeZone onChange={handleSearChange} timezoneId={formData.timezone_id} />
                </Col>
                <Col md={12} className="text-end mt-20 mb-20">
                  <CustomButton
                    text="Cancel"
                    onClick={handleClose}
                    className="custom-btn-outline mr-10"
                  />
                  <CustomButton
                    text="Save"
                    onClick={EditUserSubmit}
                    className="custom-btn-primary"
                  />
                </Col>
              </Row>
            </Row>
          </Col>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default EditUser;
