import React, { useState, useEffect } from 'react';
import FileUploadView from './FileUploadView';
import { Row, Tab, Nav } from 'react-bootstrap';
import { ringcopy, videobox, mcqQuestion, textquestion, uploadfile, shareleftarrow, thingtime, retake, responsestime, word } from "../../helper/Icons/Icon";
import FileUploadViewInternal from './FileUploadViewInternal';
import { Checkbox } from "../form";
import InternalComment from './InternalComment';
const config = require('../../config/config.json');

const icons = { 'Video Question': videobox, 'Audio': ringcopy, 'Multiple Choice': mcqQuestion, 'Written': textquestion, 'File Upload': uploadfile };

const CandidateRespones = ({ responses, comment, updateList, setUpdateList }) => {
    const [answerId, setAnswerId] = useState(0);
    const checkbox = <span>✔️</span>;
    const crossbox = <span>❌</span>;
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    console.log(JSON.stringify(setUpdateList), JSON.stringify(updateList), "setUpdateList, updateList", setUpdateList, updateList);

    useEffect(() => {
        if (responses && responses.length > 0) {
            setSelectedAnswer(responses[answerId]);
        }
    }, [responses, answerId]);

    const handleTabSelect = (index) => {
        setAnswerId(index);
    };

    return (
        <Row className="row-pad-0 bg-white border-radius-5">

            <h6 className='pad-0 mt-1 min-sub-heading'><label>Candidate’s Responses</label> </h6>
            <Row className='row-pad-0 pad-0'>
                <Tab.Container id="left-tabs-example" className="" >
                    <div className='responses-wrap pad-0 mt-10'>
                        <Nav variant="pills" className="responses-wrap-nav">
                            {responses && responses.map((answer, index) => (
                                <Nav.Item key={index + 1}>
                                    <Nav.Link className={`box cursor ${index === answerId ? 'active' : ''}`} onClick={() => handleTabSelect(index)}>
                                        <span>{icons[answer.answer_media]}</span>
                                        <span className='question-nav-link'>Question {index + 1} - {answer.answer_media}</span>
                                    </Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                        <div className='Response-left-side'>
                            <Row className='row-pad-0'>
                                {selectedAnswer && (
                                    <>
                                        <div className='Response-div question row-pad-0 '>
                                            <p>{selectedAnswer.question_text}</p>
                                        </div>


                                        {selectedAnswer.answer_media === 'Video Question' && (<>
                                            <div className='Response-div thingtime-wrap d-flex mt-20 row-pad-0 pad-10'>
                                                <div className='thingtime'>
                                                    <small>{thingtime}Think Time:</small><span> {selectedAnswer.answer_think_time}mins</span>
                                                </div>
                                                <div className='responestime'>
                                                    <small>{responsestime}Response Time:</small><span>{selectedAnswer.answer_response_time}mins</span>
                                                </div>
                                                <div className='retakes'>
                                                    <small>{retake}Retakes:</small><span>{selectedAnswer.answer_retakes}</span>
                                                </div>
                                            </div>
                                            <div className='mt-20 Response-wrap pad-10'>
                                                <video width="100%" height="auto" controls>
                                                    <source src={`${config.API_BASE_URL_PUBLIC}/${selectedAnswer.answer}`} type="video/mp4" />

                                                    Your browser does not support the video tag.
                                                </video>
                                            </div>
                                            <InternalComment selectedAnswer={selectedAnswer} comment={comment} setupdate={setUpdateList} update={updateList} /></>
                                        )}

                                        {selectedAnswer.answer_media === 'Audio' && (<>
                                            <div className='Response-div thingtime-wrap d-flex mt-20 row-pad-0 pad-10'>
                                                <div className='thingtime'>
                                                    <small>{thingtime}Think Time:</small><span> {selectedAnswer.answer_think_time}mins</span> </div>
                                                <div className='responestime'>
                                                    <small>{responsestime}Response Time:</small><span>{selectedAnswer.answer_response_time}mins</span>
                                                </div>
                                                <div className='retakes'>
                                                    <small>{retake}Retakes:</small><span>{selectedAnswer.answer_retake}/{selectedAnswer.question_retakes}</span>
                                                </div>
                                            </div>
                                            <div className='mt-20 Response-wrap pad-10'>
                                                <audio width="100%" height="auto" controls>
                                                    <source src={`${config.API_BASE_URL_PUBLIC}/${selectedAnswer.answer}`} type="audio/mp3" />
                                                    Your browser does not support the audio tag.
                                                </audio>
                                            </div>
                                            <InternalComment selectedAnswer={selectedAnswer} comment={comment} setupdate={setUpdateList} update={updateList} /></>
                                        )}

                                        {selectedAnswer.answer_media === 'Multiple Choice' && (<>
                                            <div className='Response-div thingtime-wrap d-flex mt-20 row-pad-0 pad-10'>
                                                <div className='thingtime'>
                                                    <small>{thingtime}Think Time:</small><span> {selectedAnswer.answer_think_time}mins</span>
                                                </div>

                                            </div>
                                            <div className='Response-wrap pad-10 mt-20'>
                                                {selectedAnswer.question_options.map((option, index) => (
                                                    <>
                                                        <div className='Response-MCQResponse' key={index} style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between'
                                                        }}>
                                                            <Checkbox
                                                                label={option.value}
                                                                type="radio"
                                                                id={`option${index + 1}`}
                                                                name="options"
                                                                checked={option.value === selectedAnswer.answer}
                                                            />

                                                            {option.is_correct === 1 ? (
                                                                option.value === selectedAnswer.answer ? checkbox : checkbox
                                                            ) : (
                                                                option.value === selectedAnswer.answer ? crossbox : null
                                                            )}
                                                        </div>
                                                    </>
                                                ))}
                                            </div>
                                            <InternalComment selectedAnswer={selectedAnswer} comment={comment} setupdate={setUpdateList} update={updateList} /></>
                                        )}

                                        {selectedAnswer.answer_media === 'Written' && (<>
                                            <div className='Response-div thingtime-wrap d-flex mt-20 row-pad-0 pad-10'>
                                                <div className='thingtime'>
                                                    <small>{thingtime}Think Time:</small><span> {selectedAnswer.answer_think_time}mins</span>
                                                </div></div>
                                            <div className='Response-wrap mt-20 pad-10'>
                                                <p className='text-p'>{selectedAnswer.answer}</p>
                                            </div>
                                            <InternalComment selectedAnswer={selectedAnswer} comment={comment} setupdate={setUpdateList} update={updateList} /></>
                                        )}

                                        {selectedAnswer.answer_media === 'File Upload' && (
                                            <>
                                                <div className='Response-div thingtime-wrap d-flex mt-20 row-pad-0 pad-10'>
                                                    <div className='thingtime'>
                                                        <small>{thingtime}Think Time:</small><span> {selectedAnswer.answer_think_time}mins</span>
                                                    </div>

                                                </div>
                                                <div className='Response-wrap pad-10 mt-20'>
                                                    <FileUploadViewInternal document={`${selectedAnswer.answer}`} />
                                                </div>
                                                <InternalComment selectedAnswer={selectedAnswer} comment={comment} setupdate={setUpdateList} update={updateList} /></>
                                        )}

                                    </>
                                )}
                            </Row>
                        </div>
                    </div>
                </Tab.Container>
            </Row>
        </Row>
    )
}

export default CandidateRespones;
