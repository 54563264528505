import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Offcanvas, Container, Col, Row } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { CustomButton } from "../form";


import { crossicon, actionlist, adduser, attachment, Jobs } from "../../helper/Icons/Icon";
import ActionList from "../common/ActionList";
import { actionlistfields, redordercolumnfields, headertimeLine } from "./fields"
import FeedTimeLine from "../common/FeedTimeLine";
import Interview from "../RecordedInterviewDetails/Interview";
import ShareCandidate from "../common/ShareCandidate";
import Editjob from "./Editjob";
import Spinner from "../common/Spinner";
import * as moment from 'moment'
import { getById, deleteJob, deleteJobs, exportFile } from '../../store/actions/jobActions'
import Swal from 'sweetalert2';
import Detail from './Detail';
import InviteCandidates from "./InviteCandidtes";
import GenerateLink from "./GenerateLink";
import ScheduleInterviews from "../common/ScheduleInterview";
import ChangeStatus from "./ChangeStatus";
const renderTooltip = (text) => (
    <Tooltip id="tooltip">
        {text}
    </Tooltip>
);
const JobDetail = ({ handleClose, jobId }) => {
    const dispatch = useDispatch();
    const [updateList, setUpdateList] = useState(true)
    const [jobPid, setJobId] = useState(jobId);
    const [showScheduleInterviews, setScheduleInterviews] = useState(false);
    const [showGenerateLink, setGenerateLink] = useState(false);
    const [showInviteCandidates, setInviteCandidates] = useState(false);

    const [showEditPage, setShowEditPage] = useState(false);
    const [showactionlist, setshowactionlist] = useState(false);
    const [showChangeStatus, setChangeStatus] = useState(false);
    const [showInterview, setInterview] = useState(false);
    const [jobStatus, setjobStatus] = useState();
    const [showTooltip, setShowTooltip] = useState({});
    const { jobDetail, loading, error } = useSelector((state) => state.job);


    useEffect(() => {
        if (jobPid) {
            dispatch(getById(jobId));
        }

    }, [dispatch, updateList]);

    const refreshPage = () => {
        setUpdateList(!updateList)

    }

    const JobDetailModel = (props, id) => {
        setJobId(id)


        if (props === "Interview") {
            setInterview(true)
        }


    };
    const JobDetailModelClose = (props) => {
        setJobId(null)

        if (props === "Interview") {
            setInterview(false)
        }

    }

    const handleMouseEnter = (key) => {
        setShowTooltip(prev => ({ ...prev, [key]: true }));
    };

    const handleMouseLeave = (key) => {
        setShowTooltip(prev => ({ ...prev, [key]: false }));
    };
    const deleteMsg = () => {
        Swal.fire({
            icon: 'success',
            title: 'Deleted',
            text: 'Deleted a job successfully',
            showConfirmButton: false,
            timer: 3000
        });

    }

    const handleDelete = (jobId = false) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure to delete the selected item(s).',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                if (jobId) {
                    dispatch(deleteJob(jobId))
                        .then(() => {

                            deleteMsg();
                            handleClose()
                        });
                }
            }
        });


    };
    const toggleCandidates = (type, id, statusJob) => {
        setJobId(id)



        if (type === "ChangeStatus") {
            setJobId(id)
            setjobStatus(statusJob)
            setChangeStatus(!showChangeStatus)

        }


    };

    const handleModel = (type, ID) => {
        setJobId(ID)
        if (type === "adduser") {
            setInviteCandidates(true);

        }

        if (type === "actionList") {
            setshowactionlist(true)
        }
        if (type === "BulkInviteCandidates") {
            setInviteCandidates(true);

        }
        if (type === "GenerateRecordedLink") {
            setGenerateLink(true)
        }
        if (type === "ScheduleLiveInterview") {
            setScheduleInterviews(true);
        }
        if (type === "editJob") {
            setShowEditPage(true);

        }
        if (type === "Delete") {
            handleDelete(ID)

        }

    }

    const handelCloseModel = (type, id) => {
        if (type === "actionList") {
            setshowactionlist(false)
        }
        if (type === "BulkInviteCandidates") {
            setInviteCandidates(false);

        }
        if (type === "GenerateRecordedLink") {
            setGenerateLink(false)
        }
        if (type === "ScheduleLiveInterview") {
            setScheduleInterviews(false);

        }
        if (type === "editJob") {
            setShowEditPage(false);
            setUpdateList(!updateList)


        }


    }
    return (
        <>


            {jobDetail &&
                <Offcanvas show={true} onHide={handleClose} placement="end" className="bg-ligte-gray custom-offcanvas" style={{ width: "82%" }}>
                    <Container fluid className="detailpage-wrap pad-0">
                        {loading &&
                            <Spinner />
                        }
                        <Row className="row-pad-0">
                            <div className="header">
                                <div className="header-left">
                                    {/* <span>{Jobs}</span> */}
                                    <h4>Job Orders Detail</h4>
                                    <div className="date">
                                        <p className="created">
                                            <span>CREATED BY: {jobDetail.created_by}</span>
                                            <small> {moment(jobDetail.created_date).format('MM-DD-YYYY h:mm A')}</small>
                                        </p>
                                        <p className="updated">
                                            <span>UPDATED BY: {jobDetail.updated_by}</span>
                                            <small>{moment(jobDetail.updated_date).format('MM-DD-YYYY h:mm A')}</small>
                                        </p>
                                    </div>

                                    <CustomButton className="actionlist-btn" onClick={() => handleModel("actionList", jobDetail.id)} icon={<i class="fa fa-ellipsis-v" ></i>} />
                                </div>
                                <div className="header-right">
                                    <ul className="right-ul">
                                        <li>
                                            <OverlayTrigger
                                                placement="bottom"
                                                show={showTooltip['InviteCandidates']}
                                                overlay={renderTooltip("Invite Candidates")}
                                            >
                                                <span
                                                    onMouseEnter={() => handleMouseEnter('InviteCandidates')}
                                                    onMouseLeave={() => handleMouseLeave('InviteCandidates')}
                                                >

                                                    <CustomButton
                                                        className="circle-btn ml-2"
                                                        onClick={() => handleModel("BulkInviteCandidates", jobDetail.id)}
                                                        icon={adduser}
                                                    />
                                                </span>
                                            </OverlayTrigger>
                                        </li>
                                        <li>
                                            <OverlayTrigger
                                                placement="bottom"
                                                show={showTooltip['generateLink']}
                                                overlay={renderTooltip("Generate Link")}
                                            >
                                                <span
                                                    onMouseEnter={() => handleMouseEnter('generateLink')}
                                                    onMouseLeave={() => handleMouseLeave('generateLink')}

                                                >
                                                    <CustomButton
                                                        className="circle-btn ml-10"
                                                        onClick={() => handleModel("GenerateRecordedLink", jobDetail.id)}
                                                        icon={attachment}
                                                    />
                                                </span>
                                            </OverlayTrigger>
                                        </li>
                                    </ul>


                                    <ul className="right-ul">
                                        <li>
                                            <OverlayTrigger
                                                placement="bottom"
                                                overlay={renderTooltip("Refresh")}
                                            >
                                                <span>
                                                    <CustomButton className="btn-default" onClick={refreshPage} icon={<i class="fa fa-refresh"></i>} />

                                                </span>
                                            </OverlayTrigger>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Row>
                        <Col md={12} className="body-wrap pad-0">
                            <CustomButton className="closeIconouter" onClick={handleClose} icon={crossicon} />
                            <div className="body-left-side ">
                                <Detail jobDetail={jobDetail} update={updateList} onclick={handleModel} />
                            </div>
                            <div className="body-right-side">
                                <FeedTimeLine module={"jobs"} module_pk_id={jobPid} update={updateList} />
                            </div>
                        </Col>
                    </Container>
                </Offcanvas>
            }

            {showInterview && <Interview headertimeLine={headertimeLine} handleClose={() => JobDetailModelClose("Interview")} />}
            {showactionlist && <ActionList ID={jobId} list={actionlistfields} headertext=" Action List " onclick={handleModel} handleClose={() => handelCloseModel("actionList")} />}
            {showInviteCandidates && <InviteCandidates jobId={jobId} handleClose={() => handelCloseModel("BulkInviteCandidates")} />}
            {showEditPage && <Editjob jobId={jobId} handleClose={() => handelCloseModel("editJob")} />}
            {showGenerateLink && <GenerateLink jobId={jobId} handleClose={() => handelCloseModel("GenerateRecordedLink")} />}
            {showScheduleInterviews && <ScheduleInterviews jobId={jobId} handleClose={() => handelCloseModel("ScheduleLiveInterview")} />}
            {showChangeStatus && <ChangeStatus handleClose={() => toggleCandidates("ChangeStatus")} jobId={jobId} statusActive={jobStatus} />}
        </>


    )
}

export default JobDetail;