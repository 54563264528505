import { Col, Row } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { CustomButton } from "../form/index";
import { useState } from "react";
import CandidatesList from "./CandidatesList";
import { Jobs } from "../../helper/Icons/Icon";
import * as moment from 'moment'
const renderTooltip = (text) => (
    <Tooltip id="tooltip">
        {text}
    </Tooltip>
);
const Detail = ({ jobDetail, onclick, update }) => {
    const [showTooltip, setShowTooltip] = useState({});
    const handleMouseEnter = (key) => {
        setShowTooltip(prev => ({ ...prev, [key]: true }));
    };

    const handleMouseLeave = (key) => {
        setShowTooltip(prev => ({ ...prev, [key]: false }));
    };
    return (
        <>
            <Row className="row-pad-0 custom-row-pad-0">
                <Col md={1} className="custom-md-1 mt-15">
                    <div className="profile">
                        <span>{Jobs}</span>
                    </div>
                </Col>
                <Col md={11} className="content pad-0 custom-md-11 mt-10">
                    <div className="flex-space-between">
                        <div className="d-flex base-line">
                            <h5 className="min-sub-heading">
                                <label >{jobDetail.job_title} <small className="small-text"> / {jobDetail.company_name}</small> </label>
                            </h5>
                            <CustomButton className={`btn-status ml-10 status-${jobDetail.stage_name}`} text={jobDetail.stage_name} />
                        </div>
                        <div>
                            <OverlayTrigger
                                placement="bottom"
                                show={showTooltip['editJob']}
                                overlay={renderTooltip("Edit  Job")}
                            >
                                <span
                                    onMouseEnter={() => handleMouseEnter('editJob')}
                                    onMouseLeave={() => handleMouseLeave('editJob')}

                                >
                                    <CustomButton className="actionlist-btn" onClick={() => onclick("editJob", jobDetail.id)} icon={<i class="fa fa-pencil" style={{ fontSize: "14px", color: "black" }}></i>} />    </span>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div className="flex-Xcenter">
                        <div className="list-title">
                            <label className="title-label">Job Type :</label><span className="view-text">{jobDetail.job_type_name}</span>
                        </div>
                        <div className="list-title">
                            <label className="title-label">Pay/Salary :</label><span className="view-text">{jobDetail.pay_salary}</span>
                        </div>
                        <div className="list-title">
                            <label className="title-label">Work Authorization :</label><span className="view-text">{jobDetail.work_authorization_name}</span>
                        </div>
                        <div className="list-title">
                            <label className="title-label">Workspace :</label><span className="view-text">{jobDetail.workspace_type_name}</span>
                        </div>
                        <div className="list-title">
                            <label className="title-label">Job Expires on :</label><span className="view-text">{moment(jobDetail.job_expiry_date).format('MM-DD-YYYY')}</span>
                        </div>
                        <div className="list-title">
                            <label className="title-label">Pay Type :</label><span className="view-text">{jobDetail.pay_type_name}</span>
                        </div>
                        <div className="list-title">
                            <label className="title-label">Interview Template :</label><span className="view-text">{jobDetail.interview_template_name}</span>
                        </div>
                        <div className="list-title">
                            <label className="title-label">Followup User:</label><span className="view-text">{jobDetail.followup_user_name}</span>
                        </div>
                        <div className="list-title">
                            <label className="title-label"> Industry :</label><span className="view-text">{jobDetail.industry_name}</span>
                        </div>
                    </div>
                    <div className="list-location">
                        <label className="title-label">Location :</label>
                        <span className="view-text">
                            <a
                                href={`https://www.google.com/maps/place/${encodeURIComponent(jobDetail.address + ', ' + jobDetail.city + ', ' + jobDetail.state_name + ', ' + jobDetail.zip_code + ', ' + jobDetail.country_name)}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {jobDetail.address + ', ' + jobDetail.city + ', ' + jobDetail.state_name + ', ' + jobDetail.zip_code + ', ' + jobDetail.country_name}
                            </a>
                        </span>
                    </div>

                    <div className="list-description">
                        <label className="title-label">job description :</label>

                        <p className="view-text bg-white pad-10 radius-5" dangerouslySetInnerHTML={{ __html: jobDetail.job_description }}></p>


                    </div>

                </Col>
            </Row>

            <CandidatesList jobId={jobDetail.id} update={update} />
        </>
    )
}

export default Detail;