
import { useState, useRef } from "react"
import { useDispatch, useSelector } from 'react-redux';
import { generaterecordedinvitationlink } from "../../store/actions/interviewEvaluatorAction";


import { CustomButton, InputField, Switch } from "../form/index";
import { crossicon, website, faplusicon, faminusicon, btnplus, expirtime, generatelink } from "../../helper/Icons/Icon";
import { Offcanvas, Row, Container, Col, Button } from "react-bootstrap";
import Spinner from "../common/Spinner";

import Swal from 'sweetalert2';

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 2000
    });
}
const GenerateLink = ({ handleClose, jobId }) => {
    const dispatch = useDispatch();
    const [showlink, setLink] = useState()
    const [showLoading, setLoading] = useState(false)
    const [showLink, setShowLink] = useState("");
    const [isChecked1, setIsChecked1] = useState(true);
    const [isChecked2, setIsChecked2] = useState(true);

    const [expireDays, setExpireDays] = useState(5);
    const [isCopyLink, setCopyLink] = useState(false);
    const [isLinkGenerate, setLinkGenerate] = useState(false);
    const inputRef = useRef(null);


    const handleIncrement = () => {
        setExpireDays(expireDays + 1);
    };

    const handleDecrement = () => {
        if (expireDays > 1) {
            setExpireDays(expireDays - 1);
        }
    };

    const handleChange1 = () => {
        setIsChecked1(!isChecked1);
    };

    const handleChange2 = () => {
        setIsChecked2(!isChecked2);
    };



    const LinkGenerate = () => {
        setLoading(true)

        if (jobId) {


            const payload = {
                'job_id': jobId,

                'expire_after_days': expireDays,
                'show_company_profile': isChecked2 ? 1 : 0
            }


            dispatch(generaterecordedinvitationlink(payload))
                .then((res) => {

                    if (res.payload.link) {
                        setLoading(false)
                        setShowLink(res.payload.link);
                        setLinkGenerate(true);
                        setCopyLink(false);

                    }
                    if (res.payload.error) {
                        setLoading(false)
                        return sweetAlert(
                            "error",
                            "Error!",
                            `${res.payload.error}!`
                        )
                    }


                })
                .catch(error => {
                    setLoading(false)
                    return sweetAlert(
                        "error",
                        "Error!",
                        "Something went wrong!"
                    )
                });

        }
        else {
            return sweetAlert(
                "error",
                "Error!",
                "Please Try agin process!"
            )
            setLoading(false)
        }

    };
    const copyLink = () => {
        if (showLink) {
            const textarea = document.createElement('textarea');
            textarea.value = showLink;
            document.body.appendChild(textarea);
            textarea.select();
            try {
                const successful = document.execCommand('copy');
                if (successful) {
                    setCopyLink(true);
                    sweetAlert("success", "Copied!", "The link has been copied to your clipboard.");
                } else {
                    alert("Unable to copy the text. Please use Ctrl+C (Cmd+C on Mac) to copy.");
                }
            } catch (err) {
                console.error('Unable to copy text:', err);
                alert("Unable to copy the text. Please use Ctrl+C (Cmd+C on Mac) to copy.");
            } finally {
                document.body.removeChild(textarea);
            }
        }
    };
    return (
        <>
            <Offcanvas
                show={true}
                onHide={handleClose}
                placement="end"
                className="bg-ligte-gray custom-offcanvas"
                style={{ width: "50%" }}
            >
                {showLoading && <Spinner />}
                <Container fluid className="row-pad-0 pad-0 bg-ligte-gray pad-20">
                    <Offcanvas.Header className="model-header bg-white  ">
                        <Offcanvas.Title>
                            <span className="model-header-svg ">{generatelink}Generate Link</span></Offcanvas.Title>
                        <div className="text-end"><CustomButton onClick={handleClose} className="text-end close-btn " text={crossicon} /></div>
                    </Offcanvas.Header>
                    <Offcanvas.Body className="mt-10 wrap InviteCandidate-wrap">
                        <Row className="row-pad-0 bg-white mt-20 share-setting-wrap">

                            <Col md={12} className="pad-0">
                                {/* <h4 className="mb-10">Share Settings</h4>
                                <h5>Main Settings</h5> */}
                                <Row className="row-pad-0 mt-20">
                                    <Col md={12} className="main-setting-card ">
                                        <div className="link mt-10">
                                            <div className="puls-minus">
                                                <span className="mr-10">{expirtime}</span>
                                                <span>Expire Link After</span>
                                                <CustomButton icon={faminusicon} className="btn-default btn-mius" onClick={handleDecrement} />
                                                <InputField type="text" value={expireDays} readOnly />
                                                <CustomButton className="btn-default btn-puls" icon={faplusicon} onClick={handleIncrement} />
                                                <span className="mr-10">Days</span>
                                            </div>
                                            <Switch checked={isChecked1} onChange={handleChange1} />
                                        </div>
                                        <div className="link mt-20 mb-10">
                                            <div>
                                                <span className="mr-10">{btnplus}</span>
                                                <span>Include company landing page</span>
                                            </div>
                                            <Switch checked={isChecked2} onChange={handleChange2} />
                                        </div>
                                        {isLinkGenerate && (
                                            <Col md={12} className="pad-0 mb-20 mt-20">
                                                <div className="Copy-Link">
                                                    <Col className="d-flex pt-10">
                                                        {showLink && (
                                                            <p ref={inputRef} className="text-ellipsis-link">
                                                                <span className="ml-10 mr-10">{website}</span>{showLink}
                                                            </p>
                                                        )}
                                                    </Col>
                                                    <Col className="text-end">
                                                        <Button
                                                            onClick={copyLink}
                                                            className={isCopyLink ? "green btn" : "custom-btn-outline"}
                                                        >
                                                            {isCopyLink ? "Link Copied!" : "Copy Link"}
                                                        </Button>
                                                    </Col>
                                                </div>
                                            </Col>
                                        )}

                                    </Col>
                                </Row>


                            </Col>


                        </Row>
                        {!isLinkGenerate && (
                            <Col md={12} className="text-end mt-20 mb-20 pad-0">
                                <CustomButton onClick={handleClose} className="mr-10 custom-btn-outline" text="Cancel" />
                                <CustomButton className="custom-btn-primary" text="Generate Link " onClick={LinkGenerate} />
                            </Col>
                        )}
                    </Offcanvas.Body>
                </Container>
            </Offcanvas>
        </>
    )
}

export default GenerateLink;
